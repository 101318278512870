import React, { useEffect, useRef, useState } from 'react'
import ReactDOMServer from 'react-dom/server';
import ReactDOM from 'react-dom';

const BoxInput = (props) => {
    const [BoxValue, SetBoxValue] = useState()

    useEffect(() => {
        RendorContent()
    }, [])

    const RendorContent = () => {
        var indents = [];
        for (let i = 0; i < props.noOfBoxes; i++) indents.push(BoxHTML(props.value[i].replace(/_/g, ''), i));
        ReactDOM.render(indents, document.getElementById("divBox_" + props.QId))
    }

    const AssignBoxValue = (v, i) => {
        console.log(v);
        SetBoxValue(v.target.value)
        var dOTP = {
            OTP: GetValue(),
            QId: props.QId
        }
        props.GetAnswer(dOTP)
    }

    const ManageElementFocus = (i, IsIncreament = true) => {
        var cnt = i
        if (IsIncreament) {
            cnt += 1;
            if (document.getElementById("txtWordleBox_" + props.QId + "_" + (cnt)) != null) {
                if (document.getElementById("txtWordleBox_" + props.QId + "_" + (cnt)).readOnly) ManageElementFocus(cnt)
                else document.getElementById("txtWordleBox_" + props.QId + "_" + (cnt)).focus()
            }
        }
        else if (!IsIncreament) {
            cnt -= 1;
            if (document.getElementById("txtWordleBox_" + props.QId + "_" + (cnt)) != null) {
                if (document.getElementById("txtWordleBox_" + props.QId + "_" + (cnt)).readOnly) ManageElementFocus(cnt, false)
                else document.getElementById("txtWordleBox_" + props.QId + "_" + (cnt)).focus()
            }
        }
    }

    const HandelKeyPressEvent = (e, i) => {
        if(e.key == 'Backspace') ManageElementFocus(i, false)
        else if(e.key == 'ArrowLeft') ManageElementFocus(i, false)
        else if(e.key == 'ArrowRight') ManageElementFocus(i)
        else ManageElementFocus(i)
    }

    const GetValue = () => {
        var V = "";
        var cusid_ele = document.getElementsByClassName('WordleBox_' + props.QId);
        for (var i = 0; i < cusid_ele.length; ++i) {
            var item = cusid_ele[i];
            V += item.value
        }
        return V
    }

    // useEffect(() => {
    //     console.log(inputRef.currect)
    //     //inputRef.currect?.autofocus()
    // }, [ActiveIndex])

    const BoxHTML = (Value, i) => {
        return (    
            <div style={{ display: "flex", alignItems: "center" }}>
                <input
                    id={("txtWordleBox_" + props.QId + "_" + i)}
                    className={"form-control w2em" + (Value != '' ? " border-primary" : "") + " WordleBox_" + props.QId}
                    onChange={e => {
                        AssignBoxValue(e, i)
                    }}
                    onKeyUp={(e) => HandelKeyPressEvent(e, i)}
                    type="text"
                    maxLength={1}
                    //autoFocus={i == 0}
                    value={Value != "" ? Value : BoxValue}
                    style={{ width: "1em", textAlign: "center" }}
                    readOnly={Value != '' ? true : false}
                    >
                </input>
                <span className="ml-2"></span>
            </div>
        )
    }

    return (
        <>
            <div id={"divBox_" + props.QId} style={{ display: "flex" }}></div>
        </>
    )
}

export default BoxInput