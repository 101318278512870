import React from 'react'
import TextField from '@material-ui/core/TextField';

const NoOfQuestionSelection = (props) => {
    const [TotalQuestion, setTotalQuestion] = React.useState(props.QDetail.total_question);
    const [NQValue, setNQValue] = React.useState(props.QDetail.total_question >= 100 ? 100 : props.QDetail.total_question);

    React.useEffect(() => {
        props.ReceiveNoOfTotalSelectedQuestion(props.QDetail.total_question >= 100 ? 100 : props.QDetail.total_question);
      }, []);

    const SendNoOfQuestionSeleted = (data) => {
        setNQValue(data);
        props.ReceiveNoOfTotalSelectedQuestion(data);
    }

    return (
        <>
            <div className="row">
                <div className="col-md-4 mt-3">
                    <span>Total no of Questions <b>{TotalQuestion}</b></span>
                </div>
                <div className="col-md-8 mt-2">
                    <TextField type="number" className="form-control" size="small" value={NQValue}
                        onChange={(t) => {
                            SendNoOfQuestionSeleted(parseInt(t.target.value) <= TotalQuestion || t.target.value == '' ? t.target.value : (TotalQuestion >= 100 ? 100 : TotalQuestion));
                        }}
                        onBlur={(e) => SendNoOfQuestionSeleted(e.target.value == '' ? (TotalQuestion >= 100 ? 100 : TotalQuestion) : e.target.value)}
                        label="Select no of Question" variant="outlined" />
                </div>
            </div>
        </>
    )
}

export default NoOfQuestionSelection
