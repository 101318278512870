import React, { Component } from 'react'
import { Input, Button, Label, Icon } from "semantic-ui-react";
import ReactDOM from 'react-dom';
import ChangePassword from '../LoginComponant/ChangePassword'
import ForgotPassword from './ForgotPassword';
import { TextField } from '@material-ui/core';

const VerifyOtp = props => {
    const [loading, set_loading] = React.useState(false)
    const [OTPEntered, setOTP] = React.useState('')

    function Verify() {
        set_loading(true);
        if (OTPEntered == props.OTPValue) {
            ReactDOM.render(<ChangePassword Email={props.Email}></ChangePassword>, document.getElementById('ForgotPasswordContent'));
        }
        else { alert('Invalid OTP'); set_loading(false) }
    }

    const RendorForgotPasswordSection = () => {
        ReactDOM.render(<ForgotPassword></ForgotPassword>, document.getElementById('MainContent'))
    }

    return (
        <>
            <span>
                OTP successfully Sent to:
            </span>
            <br></br>
            <Label className="mb-4">{props.Email}
                {/* <a className="ml-2" onClick={(e) => RendorForgotPasswordSection()}><Icon className="edit"></Icon></a> */}
            </Label>
            <br></br>
            <TextField
                style={{ width: 250 }}
                onChange={(e) => {
                    setOTP(e.target.value)
                }}
                id="outlined-basic"
                label="Enter OTP"
                variant="outlined" />
            <br></br>
            <div className="mt-4" style={{ alignSelf: 'center', display: loading ? 'none' : 'flex' }}>
                <Button style={{
                    backgroundColor: "#21b6ae", fontSize: "14px",
                    color: '#fff', width: 250,
                    flexDirection: 'row'
                }} onClick={() => {
                    Verify()
                }} variant="contained" >
                    Verify OTP
                </Button>
            </div>
        </>
    )
}

export default VerifyOtp
