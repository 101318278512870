import { TextField } from '@material-ui/core';
import React, { Component } from 'react'
import ReactDOM from 'react-dom';
import { Input, Button, Label } from "semantic-ui-react";
import LoginScreen from './LoginScreen';
const axios = require('axios');

const ChangePassword = props => {
    const [loading, set_loading] = React.useState(false)
    const [NewPassword, setNewPassword] = React.useState('')
    const [ConfirmNewPassword, setConfirmNewPassword] = React.useState('')

    function ChangePWd() {
        if (verifyPWD()) {
            set_loading(true);
            axios.get('Nishant/FGTAPI/ChangePAssword?Email=' + props.Email + '&PWD=' + NewPassword).then(function (response) {
                set_loading(false)
                if (!response.data.Ok) alert('Invalid Email')
                else {
                    alert('Password SuccessFully Changed. Please Login again');
                    setTimeout(() => {
                        ReactDOM.render(<LoginScreen></LoginScreen>, document.getElementById('MainContent'))
                    }, 2000);
                }
            }).catch(function (error) {
                set_loading(false)
                alert(error);
            })
        }
    }

    function verifyPWD() {
        if (NewPassword != '' && NewPassword != null) {
            if (NewPassword == ConfirmNewPassword) return true
            else { alert('Password did not matched'); return false; }
        }
        else { alert('Please Enter Password'); return false; }
    }

    return (
        <>
            <div>
                <TextField
                    style={{ width: 250 }}
                    onChange={(e) => {
                        setNewPassword(e.target.value)
                    }}
                    id="outlined-basic"
                    type='password'
                    label="Enter New Password"
                    variant="outlined" />
            </div>
            <div className="mt-4">
                <TextField
                    style={{ width: 250 }}
                    onChange={(e) => {
                        setConfirmNewPassword(e.target.value)
                    }}
                    id="outlined-basic"
                    type='password'
                    label="Confirm New Password"
                    variant="outlined" />
            </div>
            <div className="mt-4" style={{ alignSelf: 'center', display: loading ? 'none' : 'flex' }}>
                <Button style={{
                    backgroundColor: "#21b6ae", fontSize: "14px",
                    color: '#fff', maxWidth: 250, width: '100%',
                    flexDirection: 'row'
                }} onClick={() => {
                    ChangePWd()
                }} variant="contained" >
                    Change Password
                </Button>
            </div>
        </>
    )
}

export default ChangePassword
