import React, { useState, useEffect } from 'react';
import ReactDOM from 'react-dom';
import './My_test.css';

const axios = require('axios');
const LetDirect = () => {

    
    const [StdLoginId,setStdLoginId] = React.useState('')
    const [StdLoginPwd,setStdLoginPwd] = React.useState('')
    const [isLoading, setIsLoading] = useState(true);
    
    React.useEffect(() => {
        GetStudentDetail()
    }, [])

    const GetStudentDetail = () => {
        //set_loading(true);
        axios.get("/Nishant/Qubits/GetStudentLoginCredential?StdId=" + localStorage.getItem("letClientEmail")).then(function (response) {
            console.log(response.data[0].StdLoginId+' '+response.data[0].StdPwd)
            // setStdLoginId(response.data[0].StdLoginId)
            // setStdLoginPwd(response.data[0].StdPwd)
            // console.log(StdLoginId+' $$ '+StdLoginPwd)
            GoToLET(response.data[0].StdLoginId,response.data[0].StdPwd)
        }).catch(function (error) {
            alert(error);
        });
    }

    const GoToLET = async (SID, SPWD) => {
        if (SID != '' || SPWD != '') {
            let SIDEncryptedValue = await GetEncryptedValue(SID);
            let SPWDEncryptedValue = await GetEncryptedValue(SPWD);
            if (SIDEncryptedValue != '' && SPWDEncryptedValue != '') {
                let link = "https://www.mykoenig.com/account/index?id=" + SIDEncryptedValue + "&pwd=" + SPWDEncryptedValue + "&t=1";
                console.log(link)
                window.open(link, "_self");
            }
        }
        else alert("Student credentials are not availabe");
    }

    const GetEncryptedValue = async (Value) => {
        let V = "";
        await axios.get("/Nishant/Qubits/GETStudedentEncryptedValue?type=0&TextValue=" + Value).then(function (response) {
            V = response.data[0].EValue;
        }).catch(function (error) {
            alert(error);
        });
        return V;
    }


    // const CallTestComponant = (PS) => {
    //     ReactDOM.render(<Random_test PaperSet={PS} CId={CommonHelper.EnCodeURL(CId)}></Random_test>, document.getElementById('divMain'))
    // }

    return (
        <div>
      {isLoading ? (
        <div className="loading-container">
          <div className="loading-spinner"></div>
          <div>Please wait...</div>
        </div>
      ) : (
        <div id="divMain"></div>
      )}
    </div>
    )
}

export default LetDirect