import React, { useState, useEffect } from 'react';
import ReactDOM from 'react-dom';
import CSDataTable from '../Reusable/CSDataTable';
import { Interweave } from 'interweave';

const axios = require("axios");

const DiscussionRequired = (props) => {
    const Coulumn = [
        // { text: 'Student Name', key: 'StudentName', sortable: true },
        {
            text: 'Student Name',
            key: '',
            sortable: true,
            cell: d => {
                return (<Interweave content={d.ClientEmail} />);
            }
        },
        { text: 'Questions', key: 'QuestionsWantsToDiscuss', sortable: true },
        // {
        //     text: 'Action',
        //     key: '',
        //     sortable: false,
        //     cell: d => {
        //         return (
        //             <>
        //                 {
        //                     <a onClick={() => MarkAsDiscussed(d.QuestionId)} className="badge btn btn-outline-primary text-primary">Discussed</a>
        //                 }
        //             </>
        //         );
        //     }
        // },
    ]
    const [Rows] = useState(props.Data);

    const GetDataTable = () => {
        if (Rows.length > 0) return <CSDataTable Rows={Rows} Columns={Coulumn} PageSize={10} Length_menu={[10, 20, 50, 100]}></CSDataTable>
        else return <div className="text-center"><label className="alert-danger alert">No Discussion Required</label></div>
    }

    const MarkAsDiscussed = async (QId) => {
        if (props.TID != 0) {
            var obj = {
                Type: 16,
                TestId: props.TID,
                QId: QId
            };
            axios.post('/Nishant/Qubits/AddQuestionFromList', obj).then(function (response) {
                if (response.data != null) {
                    ReactDOM.render(<label>Added</label>, document.getElementById('ListQId_' + response.data[0].QId))
                }
            })
        }
        else alert('Please Create test first');
    }

    const AddMCQQuestionToTest = (QId) => {
        if (props.TID != 0) {
            var obj = {
                Type: 21,
                QId: QId
            };
            axios.post("/Nishant/Qubits/GetMCQQuestionDetail", obj).then(function (response) {
                console.log(response.data)
                if (response.data != null) {
                    AddMCQ(response.data[0].Question, response.data[0].QuesId)
                }
            })

        }
        else alert('Please Create test first');
    }

    const AddMCQ = (Question, QId) => {
        if (Question != "") {
            var obj = {
                Question: Question,
                trainerID: props.UserId,
                TestId: props.TID
            };
            axios.post("/Nishant/Qubits/AddQuestion", obj).then(function (response) {
                if (response.data != null) {
                    ReactDOM.render(<label>Added</label>, document.getElementById('ListQId_' + QId))
                }
            })
        }
    }

    return (
        <div className="pl-2 pr-2">
            {GetDataTable()}
        </div>
    )
}

export default DiscussionRequired