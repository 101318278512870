import React from 'react';
import ReactDOM from 'react-dom';
import Checkbox from '@material-ui/core/Checkbox';
import CircularProgress from '@material-ui/core/CircularProgress';
import ReactDOMServer from 'react-dom/server';
import CheckSound from '../../../src/Check.mp3'
import CorrectSound from '../../../src/correct-ans2.mp3'
import InCorrectSound from '../../../src/wrong-ans.mp3'
import '../../random_test.css'
import { FcFeedback } from 'react-icons/fc';
import { ImStatsBars } from "react-icons/im";
import SubmitQuestionFeedback from '../Componant/SubmitQuestionFeedback';
import GatStatsOfQuestion from '../Componant/GatStatsOfQuestion';
import clapImg from '../../image/clap.gif';
import WordleComponant from '../Componant/PartialComponant/WordleComponant';
import { Interweave } from 'interweave';

const axios = require('axios');
var moment = require('moment'); // require

const Question = props => {
    let c_id = props.c_id
    let paperset = props.paperset

    const [QUestionList, setQUestionList] = React.useState([]);
    const [DistinctScenarioId, setDistinctScenarioId] = React.useState([]);
    const [MainLoader, SetMainLoader] = React.useState(true);
    const [TimeTaken, SetTimeTaken] = React.useState(moment());
    React.useEffect(() => {
        GetAllQuestion()
    }, [])

    const GetAllQuestion = async () => {
        var obj = {
            c_id: c_id,
            clientid: props.Cl_Id,
            paperset: paperset,
            paper_type: ''
        };
        console.log(obj)
        await axios.post('/Nishant/Qubits/QubitsNonAttamptedQuestion', obj).then(function (response) {
            console.log(response.data)
            if (response.data != null) {
                setDistinctScenarioId(GetDistinctSCId(response.data))
                setQUestionList(response.data);
                props.getDetailFormQuestion(response.data)
                SetMainLoader(false)
            }
        })
    }

    const GetDistinctSCId = (obj) => {
        var lookup = {};
        var items = obj;
        var result = [];
        for (var item, i = 0; item = items[i++];) {
            var name = item.ScenarioId;
            if (!(name in lookup)) {
                lookup[name] = 1;
                var d = {
                    ScenarioId: name,
                    Heading: item.SCQuestion,
                    ImagePath: item.SCImage
                }
                result.push(d);
            }
        }
        return result
    }

    const SubmitAnswerMultiple = (qid) => {
        let a_1 = document.getElementById("Chk1_" + qid).checked;
        let a_2 = document.getElementById("Chk2_" + qid).checked;
        let a_3 = document.getElementById("Chk3_" + qid).checked;
        let a_4 = document.getElementById("Chk4_" + qid).checked;
        SubmitAnswer(qid, a_1, a_2, a_3, a_4)
    }

    const SubmitAnswer = (qid, a_1, a_2, a_3, a_4, a_5 = false) => {
        //sound();
        let StartTime = TimeTaken.format("DD-MMM-YYYY HH:mm:ss")
        let EndTime = moment().format("DD-MMM-YYYY HH:mm:ss")
        let time_taken = moment(EndTime).diff(moment(StartTime), 'seconds')
        let cl_id = props.Cl_Id
        var obj = {
            stdid: cl_id,
            quesid: qid,
            cid: c_id,
            a1: (a_1 ? 1 : 0),
            a2: (a_2 ? 1 : 0),
            a3: (a_3 ? 1 : 0),
            a4: (a_4 ? 1 : 0),
            a5: 0,
            StartTime: StartTime,
            EndTime: EndTime,
            time_taken: time_taken,
            paper_set: paperset
        };
        axios.post("/Nishant/Qubits/insert_qbit_answer2", obj).then(function (response) {
            SetTimeTaken(moment())
            document.getElementById('divHeader_' + qid).classList.add("print-heading-attempted"); //Please don't remove Class Name. Title is using for Summary at the time of Submit
            ReactDOM.render(parseInt(document.getElementById('hiddenCount').value) + parseInt(document.getElementsByClassName('print-heading-attempted').length), document.getElementById("AttemptedCount"))
            // console.log(parseInt(document.getElementById('AttemptedCount').innerText) + 1)
        })
    }

    const EventAfterAnsweredAnyQuestion = (r) => {
        if (r.IsMultiAnswer === 0) ShowCorrectAnswer(r.QuestionId)
    }

    const ShowCorrectAnswer = (QId) => {
        let clientid = props.Cl_Id// localStorage.getItem("cl_id");
        if (clientid != null) {
            SetTimeTaken(moment())
            axios.get("/Nishant/Qubits/GetAnswerStatus?ClientId=" + clientid + "&QuesId=" + QId + "&Paperset=" + paperset).then(function (r) {
                if (r.data.length > 0) ReactDOM.render(GetAnswerStatement(r.data), document.getElementById('AnswerStatus_' + QId));
                else alert('Please choose your answer first');
            })
        }
    }
    
    const GetAnswerStatement = (row) => {
        return (
            <>
                {
                    row[0].IsWordleQuestion == 1 ? <></> : row[0].AllAnswer.split('*$').map((data, i) => 
                        data != "" ?
                            <span className="option px-3 pt-3">
                                <div className="pb-2" style={{
                                    justifySelf: 'center',
                                    alignSelf: 'center'
                                }}>
                                    <span className="mr-3">{data.split(')')[0] + (data.split(')')[0] != '' ? ')' : '')}</span> <Interweave content={data.split(')')[1]} />
                                </div>
                            </span>
                            : 
                        <></>
                    )
                }
                {row[0].IsWordleQuestion == 1 ? <></> :
                    <span className={row[0].AnswerStatus == "Correct" ? "text-success pb-3 pt-3 px-3" : "text-danger pb-3 pt-3 px-3"}>
                        <span className="">
                            {row[0].AnswerStatus} Answer
                        </span>
                    </span>
                }
            </>
        )
    }

    
    const GetQuestionRating = (Id) => {
        switch (Id) {
            case 1: return "Basic Level Question";
            case 2: return "Intermediate Level Question";
            case 3: return "Advance Level Question";
            default: return "";
        }
    }

    const GetRatingStar = (NoOfRating) => {
        var Content = []
        for (let i = 0; i < NoOfRating; i++) {
            Content.push(<i className="fa fa-star" aria-hidden="true" style={{ color: ' yellow' }}></i>)
        }
        return Content;
    }

    const CreatePopUpForDetail = (FType, QId) => {
        if (FType == 1) QuestionFeedback(QId)
    }

    async function SendMail1(uname, to, cc, bcc, subject, html) {
        try {
            var obj = {
                _uname: uname,
                _to: to,
                _cc: cc,
                _bcc: bcc,
                _subject: subject,
                _html: html
            };
            await axios.post("/Nishant/sendemail", obj)
        } catch (er) {
            //alert(JSON.stringify(er));
        }
    }

    const ReturnHtml = (data) => {
        return (
            <>
                <span>Question: <b> {data[0].Question}</b></span><br></br><br></br>
                <span>Options: </span><br></br><br></br>
                {data[0].Ans1 != "" ? <><span style={{ margin: '5px 20px' }}>A): {data[0].Ans1}</span><br /></> : ""}
                {data[0].Ans2 != "" ? <><span style={{ margin: '5px 20px' }}>B): {data[0].Ans2}</span><br /></> : ""}
                {data[0].Ans3 != "" ? <><span style={{ margin: '5px 20px' }}>C): {data[0].Ans3}</span><br /></> : ""}
                {data[0].Ans4 != "" ? <><span style={{ margin: '5px 20px' }}>D): {data[0].Ans4}</span><br /></> : ""}
                {data[0].Ans5 != "" ? <><span style={{ margin: '5px 20px' }}>E): {data[0].Ans5}</span><br /></> : ""}
            </>
        )
    }

    const CloseModel = () => {
        ReactDOM.render('', document.getElementById("divModel"));
    }

    const QuestionFeedback = (QId) => {
        ReactDOM.render(<SubmitQuestionFeedback QId={QId} SendDataToSuperParent={SendQuetionFeedback}></SubmitQuestionFeedback>, document.getElementById("divModel"));
    }

    const SendQuetionFeedback = (d) => {
        axios.get("/Nishant/Qubits/GetQuestionDetail?QId=" + d.QId + "&Paperset=" + paperset).then(function (response) {
            if (response.data != null) {
                saveEmailData(response.data,d.FB)
                let SendTo = GetFeedbackSendTo();
                var CC = 'editor@qubits42.com';
                var HTML = ReactDOMServer.renderToStaticMarkup(HTMLDataForFeedback(response.data, d.FB));
                console.log(HTML);
                SendMail1('Qubits@koenig-solutions.com', SendTo, CC, 'Qubits@koenig-solutions.com; vaibhav.aggarwal@koenig-solutions.com;', 'Feedback for Qubits', HTML);
                alert("Thank you for you feedback");
                CloseModel();
            }
        }).catch(function (error) {
            alert(error);
        });
    }

    async function saveEmailData (d,Feedback){
        await axios.post("/Nishant/Qubits/SaveFeedBackDetail?AssignmentId=" + d[0].AssignmentId + "&Paperset=" + d[0].PaperSet + "&Cid=" + d[0].CId + "&ClientId=" + props.Cl_Id+ "&QuesId=" + d[0].QuesId+ "&FeedBack=" + Feedback+ "&FeedBackType=1").then(function (response) {
            
        }).catch(function (error) {
            alert(error);
        });
    }

    const GetFeedbackSendTo = () => {
        var SendTo = '';
        if (localStorage.getItem("cordinator_email").toString().includes("qubitusernotreg@qubit.com")) {
            if (localStorage.getItem("TempEmail").toString() != '') SendTo = localStorage.getItem("TempEmail")
            else SendTo = 'editor@qubits.com';
        }
        else SendTo = localStorage.getItem("cordinator_email");
        return SendTo
    }

    const GetClientName = () => {
        var Sender = '';
        if (localStorage.getItem("cordinator_email").toString().includes("qubitusernotreg@qubit.com")) {
            if (localStorage.getItem("TempName").toString() != '') Sender = localStorage.getItem("TempName")
            else Sender = '';
        }
        else Sender = localStorage.getItem("user_name");
        return Sender
    }

    const HTMLDataForFeedback = (d, FB, IsForFeedback = true) => {
        return (
            <>
                <span>Thank you for your {IsForFeedback ? "feedback" : "comment"}.</span> <br /><br />
                <span>URL: </span><span>{window.location.href}</span> <br />
                {GetClientName() != '' ? <><span>Name: </span><span>{GetClientName()}</span> <br /></> : ''}
                <span>Email: </span><span>{GetFeedbackSendTo()}</span> <br />
                <span>Paperset: </span><span>{d[0].PaperSet}</span> <br />
                <span>Course: </span><span>{d[0].CName}</span> <br/>
                {d[0].AssignmentId != '' ? <><span>AssignmentId: </span><span>{d[0].AssignmentId}</span> <br /></> : ''}
                {d[0].TrainerNameWithEmail != '' ? <><span>Trainer Name: </span><span>{d[0].TrainerNameWithEmail}</span> <br /></> : ''}
                {d[0].Manager_NameWithEmail != '' ? <><span>Delivery Manager: </span><span>{d[0].Manager_NameWithEmail}</span> <br /></> : ''}
                {d[0].AssignmentDeliveryDate != '' ? <><span>Assignment Delivery Date: </span><span>{d[0].AssignmentDeliveryDate}</span> <br /></> : ''}
                {d[0].FeedbackDate != '' ? <><span>Feedback Date: </span><span>{d[0].FeedbackDate}</span> <br /></> : ''}
                <span>Question Id: </span><span>{d[0].QuesWithSource}</span> <br />
                <span>Question Detail: <br /> <div style={{ margin: '5px' }}>{ReturnHtml(d)}</div></span> <br />
                {
                    IsForFeedback ? <><span>Your Feedback: </span><span>{FB}</span> <br /> <br /><br />
                        <span>
                            <div style={{ margin: '5px' }}>We will review and make corrections as required</div>
                            <br /> <br />
                            <div style={{ margin: '5px' }}>Regards, <br /> Qubits-Editors</div>
                        </span></> :  <><span>Your Comment: </span><span>{FB}</span> <br /> <br /><br />
                        <span>
                            <div style={{ margin: '5px' }}>Regards, <br /> Qubits-Editors</div>
                        </span></>
                }

            </>
        )
    }

    const StatsOfQuestion = (QId) => {
        ReactDOM.render(<GatStatsOfQuestion QId={QId} Paperset={paperset}></GatStatsOfQuestion>, document.getElementById("divModel"));
    }

    const GetQuestionControl = (obj, i) => {
        return (
            <div className="print-container" key={i}>
                <div className="print-heading" id={'divHeader_' + obj.QuesId} title={obj.q_no}> {/* //Please don't remove Class Name. Title is using for Summary at the time of Submit */}
                    <span className="heading-text">Question {obj.q_no}/{obj.total}</span>
                    <div className="actions-btn">
                        <a title="Stats" className='mr-3' onClick={() => StatsOfQuestion(obj.QuesId)}><ImStatsBars /></a>
                        <a title={GetQuestionRating(obj.rating)}>{GetRatingStar(obj.rating)}</a>&nbsp;  &nbsp;
                        <a title="Feedback" onClick={() => CreatePopUpForDetail(1, obj.QuesId)}><FcFeedback /></a>
                    </div>
                </div>
                {obj.IsWordly == 0 ?
                    <>
                        <div className="print-questions">
                            <span><Interweave content={obj.Question} /></span>
                            <span>{obj.QuestionImage != '' ? <><img className="mt-3" src={"https://rms.koenig-solutions.com" + obj.QuestionImage}></img></> : ""}</span>
                        </div>
                        <div className="ans-options" id={"AnswerStatus_" + obj.QuesId}>
                            <span className="option" style={{ display: (obj.Ans1 == '') ? 'none' : 'flex' }}>
                                <div className="ans-radio">
                                    <Checkbox id={'Chk1_' + obj.QuesId} style={{ display: (obj.multi == '0') ? 'none' : 'flex' }}
                                        onChange={() => {
                                            SubmitAnswerMultiple(obj.QuesId)
                                        }}
                                    />
                                    <input type="radio" style={{
                                        display: (obj.multi == '1') ? 'none' : 'flex'
                                        , height: 30
                                        , marginRight: 20, marginTop: 5, marginLeft: 12
                                    }} name={obj.QuesId}
                                        onChange={() => {
                                            SubmitAnswer(obj.QuesId, true, false, false, false)
                                        }}
                                    />
                                </div>
                                <div className="ans-content">
                                    <div><Interweave content={obj.Ans1} /></div>
                                    {obj.OptionOneFilepath != '' ? <><img src={"https://rms.koenig-solutions.com" + obj.OptionOneFilepath}></img></> : ""}
                                </div>
                            </span>
                            <span className="option" style={{ display: (obj.Ans2 == '') ? 'none' : 'flex' }}>
                                <div className="ans-radio">
                                    <Checkbox id={'Chk2_' + obj.QuesId} style={{ display: (obj.multi == '0') ? 'none' : 'flex' }}
                                        onChange={() => {
                                            SubmitAnswerMultiple(obj.QuesId)
                                        }}
                                    />
                                    <input type="radio" style={{
                                        display: (obj.multi == '1') ? 'none' : 'flex'
                                        , height: 30
                                        , marginRight: 20, marginTop: 5, marginLeft: 12
                                    }} name={obj.QuesId}
                                        onChange={() => {
                                            SubmitAnswer(obj.QuesId, false, true, false, false)
                                        }}
                                    />
                                </div>
                                <div className="ans-content">
                                    <div><Interweave content={obj.Ans2} /></div>
                                    {obj.OptionTwoFilepath != '' ? <><img src={"https://rms.koenig-solutions.com" + obj.OptionTwoFilepath}></img></> : ""}
                                </div>
                            </span>
                            <span className="option" style={{ display: (obj.Ans3 == '') ? 'none' : 'flex' }}>
                                <div className="ans-radio">
                                    <Checkbox id={'Chk3_' + obj.QuesId} style={{ display: (obj.multi == '0') ? 'none' : 'flex' }}
                                        onChange={() => {
                                            SubmitAnswerMultiple(obj.QuesId)
                                        }}
                                    />
                                    <input type="radio" style={{
                                        display: (obj.multi == '1') ? 'none' : 'flex'
                                        , height: 30
                                        , marginRight: 20, marginTop: 5, marginLeft: 12
                                    }} name={obj.QuesId}
                                        onChange={() => {
                                            SubmitAnswer(obj.QuesId, false, false, true, false)
                                        }}
                                    />
                                </div>
                                <div className="ans-content">
                                    <div><Interweave content={obj.Ans3} /></div>
                                    {obj.OptionThreeFilepath != '' ? <><img src={"https://rms.koenig-solutions.com" + obj.OptionThreeFilepath}></img></> : ""}
                                </div>
                            </span>
                            <span className="option" style={{ display: (obj.Ans4 == '') ? 'none' : 'flex' }}>
                                <div className="ans-radio">
                                    <Checkbox id={'Chk4_' + obj.QuesId} style={{ display: (obj.multi == '0') ? 'none' : 'flex' }}
                                        onChange={() => {
                                            SubmitAnswerMultiple(obj.QuesId)
                                        }}
                                    />
                                    <input type="radio" style={{
                                        display: (obj.multi == '1') ? 'none' : 'flex'
                                        , height: 30
                                        , marginRight: 20, marginTop: 5, marginLeft: 12
                                    }} name={obj.QuesId}
                                        onChange={() => {
                                            SubmitAnswer(obj.QuesId, false, false, false, true)
                                        }}
                                    />
                                </div>
                                <div className="ans-content">
                                    <div><Interweave content={obj.Ans4} /></div>
                                    {obj.OptionFourFilepath != '' ? <><img src={"https://rms.koenig-solutions.com" + obj.OptionFourFilepath}></img></> : ""}
                                </div>
                            </span>
                            {/* <div className="text-center p-3"><a className="btn-outline-primary btn text-primary p-2" onClick={(e) => ShowCorrectAnswer(obj.QuesId)}>Explanation</a></div> */}
                            {/* {
                                (obj.multi == '1') ? <div className="text-center p-3"><a className="badge btn-outline-primary btn text-primary p-2" onClick={(e) => ShowCorrectAnswer(obj.QuesId)}>Done</a></div> : ''
                            } */}
                        </div>
                    </>
                    : <WordleComponant obj={obj} Cl_Id={props.Cl_Id} c_id={props.c_id} paperset={props.paperset} TimeTaken={TimeTaken}></WordleComponant>
                }
            </div>
        )
    }

    const GetNormalQuestion = (d) => {
        return (
            QUestionList.filter(f => f.ScenarioId == d.ScenarioId).map((obj, i) => GetQuestionControl(obj, i))
        )
    }

    const GetScenarioQuestion = (d) => {
        return(
            <div className='card mb-2'>
                <div className='card-header bg-info Scenario-heading'>
                    <div style={{fontSize:'18px'}}><strong>Scenario Question</strong></div>
                    <hr></hr>
                    {d.Heading}
                    <span>{d.ImagePath != '' ? <><img className="mt-3" src={"https://rms.koenig-solutions.com" + d.ImagePath}></img></> : ""}</span>
                </div>
                <div className='card-body'>
                    {
                        QUestionList.filter(f => f.ScenarioId == d.ScenarioId).map((obj, i) => GetQuestionControl(obj, i))
                    }
                </div>
            </div>
        )
    }

    return (
        <>
            <div id="divModel"></div>
            <div id="divClapping">
                <img src={clapImg} alt="" title="" />
            </div>
            <span style={{ display: MainLoader ? 'block' : 'none', textAlign: 'center' }} className="main-laoder">
                <CircularProgress size={80} color="secondary" />
            </span>
            <audio className="audio-element">
                <source src={CheckSound}></source>
            </audio>
            <audio className="audio-elementCorrect">
                <source src={CorrectSound}></source>
            </audio>
            <audio className="audio-elementIncorrect">
                <source src={InCorrectSound}></source>
            </audio>
            {
                DistinctScenarioId.map((d) => {
                    return <> {d.ScenarioId == 0 ? <>{GetNormalQuestion(d)}</>
                        : <>{GetScenarioQuestion(d)}</>
                    }
                    </>
                })
            }
        </>
    )
}

export default Question
