// import 'react-app-polyfill/ie11';
// import 'react-app-polyfill/stable';
import React from 'react';
import ReactDOM from 'react-dom';
import CssBaseline from '@material-ui/core/CssBaseline';
import { ThemeProvider } from '@material-ui/core/styles';
import App from './App';
import theme from './theme';
import Routes from './router'
import 'semantic-ui-css/semantic.min.css'
ReactDOM.render(
  // <ThemeProvider theme={theme}>
    <Routes />,
   
  // </ThemeProvider>,
  document.querySelector('#root'),
);
