import React from "react";
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import ListSubheader from "@material-ui/core/ListSubheader";
import DashboardIcon from "@material-ui/icons/Dashboard";
import ShoppingCartIcon from "@material-ui/icons/ShoppingCart";
import PeopleIcon from "@material-ui/icons/People";
import BarChartIcon from "@material-ui/icons/BarChart";
import LayersIcon from "@material-ui/icons/Layers";
import AssignmentIcon from "@material-ui/icons/Assignment";
import { BrowserRouter, Switch, Route } from "react-router-dom";
import PowerSettingsNewIcon from '@material-ui/icons/PowerSettingsNew';
export const mainListItems = (
  <div>
    {/* <ListItem button>
      <ListItemIcon>
        <DashboardIcon onClick={() => {
          window.location.href =  window.location.origin +'/student' 
          //window.location.href = 'http://localhost:3000/' 
        }} />
      </ListItemIcon>
      <ListItemText 
        onClick={() => {
          window.location.href =  window.location.origin +'/student' 
          //window.location.href = 'http://localhost:3000/' 
        }}
       primary="Qubits Test" />
    </ListItem> */}
    {
     (window.location.href == window.location.origin +'/student')?
     '':
    <ListItem style={{display:(localStorage.getItem('co_id')>0)?'flex':'none'}} button>
      <ListItemIcon>
        <PeopleIcon onClick={() => {
         //alert(window.location.origin)
          window.location.href = window.location.origin +'/class' //'http://localhost:3000/class'
        }} />
      </ListItemIcon>

      <ListItemText
        onClick={() => {
         //alert(window.location.origin)
          window.location.href = window.location.origin +'/class' //'http://localhost:3000/class'
        }}
        primary="Create Class"
      />
    </ListItem>
    
    }
   
    {/* <ListItem button>
      <ListItemIcon>
        <AssignmentIcon />
      </ListItemIcon>
      <ListItemText
        onClick={() => {
          window.location.href =  window.location.origin +'/test' //'http://localhost:3000/class'

          //window.location.href =  'http://localhost:3000/test'
        }}
        primary="Create Test"
      />
    </ListItem> */}
    {/* <ListItem button>
      <ListItemIcon>
        <AssignmentIcon />
      </ListItemIcon>
      <ListItemText onClick={() => {
          window.location.href = 'http://localhost:3000/Practise'
        }} primary="Practise" />
    </ListItem> */}
    <ListItem button>
      <ListItemIcon>
        <PowerSettingsNewIcon onClick={() => {
          //window.location.href = 'http://localhost:3000/Practise'
          localStorage.clear()
          
          window.location.href = window.location.origin 
          //localStorage.setItem('cordinator_id',null)
        }} />
      </ListItemIcon>
      <ListItemText onClick={() => {
          //window.location.href = 'http://localhost:3000/Practise'
          localStorage.clear()
          
          window.location.href = window.location.origin 
          //localStorage.setItem('cordinator_id',null)
        }} primary="Logout" />
    </ListItem>

  </div>
);
