import React from 'react'
import { Container, Input, Image } from 'semantic-ui-react'
import './HomepageStyle.css';
import { Icon, Button, Checkbox, Form } from 'semantic-ui-react'
import { useMediaQuery } from 'react-responsive'
import './Home1.css'
import Navbar from './Navbar';
import 'bootstrap/dist/css/bootstrap.min.css';
import $ from 'jquery'
const axios = require('axios');

const Login = (props) => {
  return (

    <div id="divIdToPrint" className="divIdToPrintDesktop">
      <div className="rightAreaDesktop">
      <Navbar/>
        <div>
          <div class="container">
            <div class="row">
              <img src="/aboutus-quibtis.jpg" />
            </div>
            <div class="row">
              <div class="col-md-12 mt-5 mb-5 p-0">
                <div class="text-bg">
                  <div class=" mt-4">
                    <h4 class="text-Center mt-4 mb-3" style={{fontWeight: 700, textDecoration: 'underline'}}>Launch of Qubits Wordle</h4>

                    <h6 class="mb-3 text">
                    Qubits42.com is the single largest source of IT questions in the world <br />
                      Learning is best achieved when integrated with testing.  This was the thought behind launch of Qubits42.com in 2018.<br />
                    </h6>
                   
                    <h6 class="mb-0 text" style={{ fontWeight: 600, textDecoration: 'underline' }}>Trivia</h6>
                    <p class="mb-4 text">
                      The #42 in Qubits 42 is inspired by  Douglas Adams,"The Hitchhiker's Guide to the Galaxy". 
                      <br></br>
                      42 is the number from which all meaning ("the meaning of life, the universe, and everything") could be derived.
                    </p>

        
                    
                  </div>
                </div>



              </div>
            </div>
          </div>
        </div>
      </div>
      {/* <div class="footer">
        <a href="/DMCA-Copyright">DMCA & LEGAL</a>
      </div> */}
    </div>

  )
}
export default Login
