import React, { useEffect } from 'react';
import ReactDOM from "react-dom";
import CircularProgress from '@material-ui/core/CircularProgress';
import Chip from '@material-ui/core/Chip';
import { Label, Table, Button } from 'semantic-ui-react';
import AppBarMenu from './Nishant/Reusable/QLAppbar'
import LoginForm from './Nishant/Reusable/Login';
import CommonHelper from './Nishant/Reusable/Utility/CommonHelper';
import ModuleWise from './ModuleWise';

const axios = require('axios');

export default function Student_Dashboard() {
    const [data, setdata] = React.useState([]);
    const [Coursedata, setCoursedata] = React.useState([]);
    const [main_loader, set_main_loader] = React.useState(false);
    const [IsValidLogin, setIsValidLogin] = React.useState(false);
    const [correctAns, set_correctAnd_count] = React.useState(0);
    
    useEffect(() => {
        if (localStorage.getItem("cl_id") > 0) {
            setIsValidLogin(true)
            set_main_loader(true)
            get_correctAns_count()
            get_test()
        }
        else Login()
    }, []);

    const Login = () => {
        if (localStorage.getItem("cl_id") > 0) {
            window.location.href = window.location.origin + "/student";
        } else {
            ReactDOM.render(<LoginForm IsAnyRedirectionAfterClose={true} SendDataToSuperParent={ReceiveDataFromSuperChild}></LoginForm>, document.getElementById('LoginPopup'));
        }
    }

    const ReceiveDataFromSuperChild = (res, isRedirect = true) => {
        if (res.length > 0) {
            CommonHelper.CreateLoginTempData(res)
            ReactDOM.render('', document.getElementById('LoginPopup'));
            setIsValidLogin(true)
            set_main_loader(true)
            get_correctAns_count()
            get_test()
        }
    }

    function get_correctAns_count() {
        axios.get('Nishant/Qubits/CorrectAnswersCountbyEmail?Type=7&email=' + localStorage.getItem('cordinator_email')).then(function (response) {
            set_correctAnd_count(response.data[0].TotalCorrectAnswer)
        })
    }

    function get_test() {
        let cordinator_id = localStorage.getItem("cordinator_id")
        let cord_type = localStorage.getItem("cordinator_type")
        if (cordinator_id > 0) {
            axios.get('Nishant/Qubits/get_QubitsDataByStudent?email=' + localStorage.getItem('cordinator_email')).then(function (response) {
                setdata(response.data)
                set_main_loader(false)
            })
            axios.get('Nishant/Qubits/get_QubitsDataByStudent?email=' + localStorage.getItem('cordinator_email') + '&type=1').then(function (response) {
                setCoursedata(response.data)
                set_main_loader(false)
            })
        }
    }

    const CreateNotAttemptedQuestion = (CourseId, ClientId) => {
        var NoOfQuestion = document.getElementById('txtTotalNoOfQuestionsSeletected_' + CourseId).value;
        axios.get('Nishant/Qubits/CreateCustomQubitsTest?Course=' + CourseId + '&ClientId=' + ClientId + '&type=2&NoOfQuestion=' + NoOfQuestion).then(function (response) {
            if (response.data != null) {
                window.open('' + window.location.origin + '/My_tes?id=' + CourseId + '&trainerid=0&clientid=' +
                ClientId + '&paperset=' + response.data[0].PaperSet, 'mywindow'
                )
            }
        })
    }

    // const RenderModuleWise = () => { ReactDOM.render(<ModuleWise CId={CId} PS={PS} ClientId={ClientId} SendMail={SendMail} isForLet="1"></ModuleWise>, document.getElementById('divMainContent')) }
    
    const RendorBody = () => {
        return (
            <>
                <div className='row'>
                    <div className='col-md-3'>
                        <strong>Qubits Dashboard</strong>
                    </div>
                    <div className='col-md-9 text-right'>
                        <strong className='badge-pill'>{(correctAns == 0 ? "" : "Correct Answer - " + correctAns)}</strong>
                        <a href='/' className='btn btn-xs btn-info'>Take New Test</a>
                    </div>
                </div>
                <hr></hr>
                {Coursedata.length > 0 ? <>
                    <div className='text-center'><strong>Below are the score as per your skill</strong></div>
                    <Table celled singleLine className="responsivTable" style={{width:'max-content'}}>
                        <Table.Header className='tabHead'>
                            <Table.Row>
                                <Table.HeaderCell style={{ fontSize: 16, fontWeight: 'bold' }}>Course </Table.HeaderCell>
                                <Table.HeaderCell style={{ fontSize: 16, fontWeight: 'bold', textAlign: 'center' }}>Total Questions</Table.HeaderCell>
                                <Table.HeaderCell style={{ fontSize: 16, fontWeight: 'bold', textAlign: 'center' }}>Total Attempted</Table.HeaderCell>
                                <Table.HeaderCell style={{ fontSize: 16, fontWeight: 'bold', textAlign: 'center' }}>Correct Answer</Table.HeaderCell>
                                <Table.HeaderCell style={{ fontSize: 16, fontWeight: 'bold', textAlign: 'center' }}>Score (%)</Table.HeaderCell>
                                <Table.HeaderCell style={{ fontSize: 16, fontWeight: 'bold', textAlign: 'center' }}>Total (Unused  + Incorrect)</Table.HeaderCell>
                            </Table.Row>
                        </Table.Header>
                        <Table.Body>
                            {
                                Coursedata.map((row) => (
                                    <Table.Row>
                                        <Table.Cell
                                            style={{ width: 320, fontSize: 14, fontWeight: 'bold' }}
                                            title={row.Course}
                                            mob-title={'Test'}
                                        >
                                            <span title={row.Course}> {row.Course.length > 40 ? row.Course.substring(0, 40) + '...' : row.Course} </span>
                                        </Table.Cell>
                                        <Table.Cell mob-title={'TotalNoOfActiveQuestion'} style={{ fontSize: 14, fontWeight: 'bold', textAlign: 'center' }}> {row.TotalNoOfActiveQuestion}</Table.Cell>
                                        <Table.Cell mob-title={'TotalAttemptedQuestion'} style={{ fontSize: 14, fontWeight: 'bold', textAlign: 'center' }}>{row.TotalAttemptedQuestion}</Table.Cell>
                                        <Table.Cell mob-title={'TotalCorrectAttemptedQuestion'} style={{ fontSize: 14, fontWeight: 'bold', textAlign: 'center' }}>{row.TotalCorrectAttemptedQuestion}</Table.Cell>
                                        <Table.Cell mob-title={'TotalCorrectAttemptedQuestion'} style={{ fontSize: 14, fontWeight: 'bold', textAlign: 'center' }}>{row.Score}%</Table.Cell>
                                        <Table.Cell mob-title={'Incorrect'} style={{ fontSize: 14, fontWeight: 'bold', textAlign: 'center' }}>
                                            <stron className='mr-2'>{parseInt(row.TotalNoOfActiveQuestion) - parseInt(row.TotalCorrectAttemptedQuestion)}</stron>
                                            {/* <input type='number' id={'txtTotalNoOfQuestionsSeletected_' + row.CourseId}
                                                className='form-control col-3' style={{ display: 'inherit' }}
                                                max={parseInt(row.TotalNoOfActiveQuestion) - parseInt(row.TotalCorrectAttemptedQuestion)}
                                                defaultValue={parseInt(row.TotalNoOfActiveQuestion) - parseInt(row.TotalCorrectAttemptedQuestion) <= 20 ? parseInt(row.TotalNoOfActiveQuestion) - parseInt(row.TotalCorrectAttemptedQuestion) : 20}
                                                ></input>  */}
                                            {/* <Chip className='ml-2' onClick={() => { CreateNotAttemptedQuestion(row.CourseId, row.StdId) }} size="small" label="Attempt Now" /> */}
                                            <Chip className='ml-2' onClick={() => { window.location.href = window.location.origin + '/ModuleWise?CId='+row.CourseId }} size="small" label="View Module And Attempt" />
                                        </Table.Cell>
                                    </Table.Row>
                                ))}
                        </Table.Body>
                    </Table>
                    <br></br>
                </> : ""}
                <div className='text-center'><strong>Test wise detail</strong></div>
                <Table celled singleLine className="responsivTable">
                    <Table.Header className='tabHead'>
                        <Table.Row>
                            <Table.HeaderCell style={{ fontSize: 16, fontWeight: 'bold' }}>Test</Table.HeaderCell>
                            <Table.HeaderCell style={{ fontSize: 16, fontWeight: 'bold', textAlign: 'center' }}>Date</Table.HeaderCell>
                            <Table.HeaderCell style={{ fontSize: 16, fontWeight: 'bold', textAlign: 'center' }}>Correct</Table.HeaderCell>
                            <Table.HeaderCell style={{ fontSize: 16, fontWeight: 'bold', textAlign: 'center' }}>Incorrect</Table.HeaderCell>
                            <Table.HeaderCell style={{ fontSize: 16, fontWeight: 'bold', textAlign: 'center' }}>Not Attempted</Table.HeaderCell>
                            <Table.HeaderCell></Table.HeaderCell>
                        </Table.Row>
                    </Table.Header>
                    <Table.Body>
                        {
                            data.map((row) => (
                                <Table.Row>
                                    <Table.Cell
                                        style={{ width: 320, fontSize: 14, fontWeight: 'bold' }}
                                        title={row.Course}
                                        mob-title={'Test'}
                                    >
                                        <Label ribbon>{row.PaperType}</Label>
                                        <span title={row.Course}> {row.Course.length > 40 ? row.Course.substring(0, 40) + '...' : row.Course} </span>
                                    </Table.Cell>
                                    <Table.Cell mob-title={'Date'} style={{ fontSize: 14, fontWeight: 'bold', textAlign: 'center' }}> {row.CreatedDate}</Table.Cell>
                                    <Table.Cell mob-title={'Correct'} style={{ fontSize: 14, fontWeight: 'bold', textAlign: 'center' }}>{row.CorrectAnswer}</Table.Cell>
                                    <Table.Cell mob-title={'Incorrect'} style={{ fontSize: 14, fontWeight: 'bold', textAlign: 'center' }}>{row.InCorrectAnswer}</Table.Cell>
                                    <Table.Cell mob-title={'Not Attempted'} style={{ display: 'block', fontSize: 14, fontWeight: 'bold', textAlign: 'center' }}>
                                        {row.NotAttemptedQuestion} {"  "} {(row.NotAttemptedQuestion > 0) ?
                                            <Chip onClick={() => {
                                                window.open('' + window.location.origin + '/My_tes?id=' + row.CourseId + '&trainerid=0&clientid=' +
                                                    row.ClientId + '&paperset=' + row.PaperSet, 'mywindow'//, 'width=640,height=360,margin=100'
                                                )
                                            }} size="small" label="Attempt Now" /> : ""}
                                    </Table.Cell>
                                    {/* <Table.Cell style={{ display: (row.NotAttemptedQuestion == 0) ? 'block' : 'none', fontSize: 14, fontWeight: 'bold', textAlign: 'center' }}>
                                        Not Attempted
                                    </Table.Cell> */}
                                    <Table.Cell className="btnTd" style={{ justifyContent: 'center', textAlign: 'center' }}>
                                        {
                                            row.NotAttemptedQuestion == 0 ? <><Button style={{ display: 'inline-flex' }} color='teal' size='mini' onClick={() => {
                                                window.open('' + window.location.origin + '/My_tes?id=' + row.CourseId + '&trainerid=0&clientid=' +
                                                    row.ClientId + '&paperset=' + row.PaperSet, 'mywindow'//, 'width=640,height=360,margin=100'
                                                )
                                            }}>View Result</Button></>
                                                : <>
                                                    <Button style={{ display: 'flex' }} onClick={() => {
                                                        window.open('' + window.location.origin + '/My_tes?id=' + row.CourseId + '&trainerid=0&clientid=' +
                                                            row.ClientId + '&paperset=' + row.PaperSet, 'mywindow'//, 'width=640,height=360,margin=100'
                                                        )
                                                    }} color='teal' size='mini'> Attempt Now</Button>
                                                </>
                                        }
                                    </Table.Cell>
                                </Table.Row>
                            ))}
                    </Table.Body>
                </Table>
            </>
        )
    }

    const RendorMainContent = () => {
        return (
            <>
                {RendorBody()}
                <span style={{ display: main_loader ? 'block' : 'none', position: 'relative', left: 500, justifyContent: 'center', alignItems: 'center' }}>
                    <CircularProgress color="secondary" />
                </span>
            </>
        )
    }

    return (
        <>
            <div id="LoginPopup"></div>
            <div style={{ display: IsValidLogin ? "flex" : 'none' }}>
                <AppBarMenu BoxContent={RendorMainContent()} HeaderText="Dashboard" WelcomeText={localStorage.getItem("user_name")}></AppBarMenu>
            </div>
        </>
    );
}
