import React from 'react'
import { useParams } from 'react-router-dom';
const axios = require('axios');

const CADRedirect = () => {
    const { VId } = useParams();

    React.useEffect(() => {
        GeneratePaperSet()
    }, [])

    const GeneratePaperSet = async () => {
        var obj = {
            VId: VId
        };
        await axios.post('/Nishant/Qubits/CADQubits', obj).then(function (response) {
            if (response.data != null) {
                window.location.href = "/CD/" + VId + "/" + response.data[0].PaperSet;
            }
            else alert('Not a valid link or Seems link is expired.');
        })
    }

  return (
    <></>
  )
}

export default CADRedirect