import React from 'react';
//import { Button } from '@material-ui/core';
import ReactDOM from 'react-dom';
import Countdown from "react-countdown";
import Dialog from '@material-ui/core/Dialog';
import { Email, Item, renderEmail } from 'react-html-email'
import Alert from '@material-ui/lab/Alert';
import { Button } from 'semantic-ui-react'
import SubmitSound from '../src/Check.mp3'
import Question from './Nishant/Reusable/Question';
import './random_test.css'
const axios = require('axios');
const queryString = require('query-string');


export default function My_tes() {
    const [show_info_pop_up, set_show_info_pop_up] = React.useState(false);
    const [ShowFeedbackPopUp, setShowFeedbackPopUp] = React.useState(false);
    const [c_name, set_c_name] = React.useState("")
    const [c_URL, set_c_URL] = React.useState("")
    const [total_q, set_total_q] = React.useState("")
    const [attempted_q, set_attempted_q] = React.useState("")
    const [left_q, set_left_q] = React.useState("")
    const [student_name, set_student_name] = React.useState("")
    const [p_type, set_p_type] = React.useState("")
    const [duration, set_duration] = React.useState("")
    const [duration1, set_duration1] = React.useState("")
    const [TotalQuestion, setTotalQuestion] = React.useState(20)
    const [TestDate, setTestDate] = React.useState("")
    const [CorrectAnswer, setCorrectAnswer] = React.useState("")
    const [LifeTimeCorrectAnswer, setLifeTimeCorrectAnswer] = React.useState("")

    const [all_questions, set_all_questions] = React.useState([])
    //const [show_question, set_show_question] = React.useState(false);
    const [, set_pop_up] = React.useState(false);
    const [test_detail, set_test_detail] = React.useState({});
    const [, set_show_result] = React.useState(false);
    const [show_question, set_show_question] = React.useState(false);
    const [show_result_box, set_show_result_box] = React.useState(false);
    const [used_seconds, set_used_secods] = React.useState(0);
    const [left_seconds, set_left_seconds] = React.useState(0);
    const [test_complete_pop, set_test_complete_pop] = React.useState(false);
    const [main_loader, set_main_loader] = React.useState(true);

    const [is_safari, set_is_safari] = React.useState(false);


    const [answer_data, set_answer_data] = React.useState([]);

    const [FBlabelText, SetFBlabelText] = React.useState('')
    const [FBText, SetFBText] = React.useState('')
    const [PaperSet, SetPaperSet] = React.useState('')

    // const [scrollPosition, setScrollPosition] = useState(false);
    // const handleScroll = () => {
    //     const position = window.pageYOffset > 190;
    //     setScrollPosition(position);
    // };
    React.useEffect(() => {
        if (window['safari'] != undefined) {
            set_is_safari(true)
            //alert("its a safari browser")
        }

        if (window.location.href.includes('%0D%20')) {
            window.location.href = window.location.href.replace('%0D%20', "")
        }
        else if (window.location.href.includes('%20')) {
            window.location.href = window.location.href.replace('%20', "")
        }
        else if (window.location.href.includes('%0D')) {
            window.location.href = window.location.href.replace('%0D', "")
        }

        //let correct_url = window.location.search
        //alert( ss)
        const url_param = queryString.parse(window.location.search)
        let c_id = url_param.id
        let trainerid = url_param.trainerid
        let clientid = url_param.clientid
        let paperset = url_param.paperset
        SetPaperSet(url_param.paperset)
        GetTestInfo(c_id, trainerid, clientid, paperset)

        // window.addEventListener("scroll", handleScroll);

        // return () => {
        //     window.removeEventListener("scroll", handleScroll);
        // };
    }, [])

    const GetTestInfo = (CId, TId, ClId, PaperSetId) => {
        axios.get('api/qubits/get_qubit_test_info?c_id=' + CId + '&clientid=' + ClId + '&paperset=' + PaperSetId + '&trainerid=' + TId)
        .then(function (response) {
            let info = JSON.parse(response.data)
            set_c_name(info[0].c_name)
            RenderInfoPopUp(info[0]);
        })
        .catch(function (error) {
            alert(error);
        })
    }

    const RenderInfoPopUp = (data) => {
        ReactDOM.render(RenderInfoPopUpContent(data), document.getElementById('ModelPopUp'))
    }

    const RenderInfoPopUpContent = (data) => {
        return(
            <Dialog maxWidth="xs" aria-labelledby="simple-dialog-title" open={true}>
                {/* <DialogTitle id="simple-dialog-title" style={{  flexDirection: 'row' }}>Test Info</DialogTitle> */}
                <Alert style={{ display: (data.attempted_q > 0 && data.total_q == data.attempted_q) ? 'flex' : 'none' }} severity="success">Test Completed</Alert>
                <div style={{ margin: 20 }}>
                    <div style={{ display: 'flex', flex: 1, width: 400, margin: 10 }}>
                        <div style={{ flex: 2, display: 'flex', fontFamily: 'Roboto', justifyContent: 'flex-end', fontWeight: 'bold' }}> Date&nbsp; : </div>
                        <div style={{ flex: 2, display: 'flex', fontFamily: 'Roboto', alignItems: 'start' }}> &nbsp; {data.TestDate}</div>
                    </div>
                    <div style={{ display: 'flex', flex: 1, width: 400, margin: 10 }}>
                        <div style={{ flex: 2, display: 'flex', fontFamily: 'Roboto', justifyContent: 'flex-end', fontWeight: 'bold' }}> Course&nbsp; : </div>
                        <div style={{ flex: 2, display: 'flex', fontFamily: 'Roboto', alignItems: 'start' }}><a target="_blank" href={"https://www.koenig-solutions.com/" + data.c_URL}> &nbsp; {data.c_name}</a></div>
                    </div>
                    <div style={{ display: 'flex', flex: 1, width: 400, margin: 10 }}>
                        <div style={{ flex: 2, display: 'flex', fontFamily: 'Roboto', justifyContent: 'flex-end', fontWeight: 'bold' }}> Student&nbsp; : </div>
                        <div style={{ flex: 2, display: 'flex', fontFamily: 'Roboto', alignItems: 'start' }}> &nbsp; {data.student_name}</div>
                    </div>
                    <div style={{ display: 'flex', flex: 1, width: 400, margin: 10 }}>
                        <div style={{ flex: 2, display: 'flex', fontFamily: 'Roboto', justifyContent: 'flex-end', fontWeight: 'bold' }}> Total Questions&nbsp; : </div>
                        <div style={{ flex: 2, display: 'flex', fontFamily: 'Roboto', alignItems: 'start' }}> &nbsp; {data.total_q}</div>
                    </div>
                    <div style={{ display: (data.CorrectAnswer > 0) ? 'flex' : 'none', flex: 1, width: 400, margin: 10 }}>
                        <div style={{ flex: 2, display: 'flex', fontFamily: 'Roboto', justifyContent: 'flex-end', fontWeight: 'bold' }}> Correct Answer&nbsp; : </div>
                        <div style={{ flex: 2, display: 'flex', fontFamily: 'Roboto', alignItems: 'start' }}> &nbsp; {data.CorrectAnswer}</div>
                    </div>
                    <div style={{ display: (data.LifeTimeCorrectAnswer > 0) ? 'flex' : 'none', flex: 1, width: 400, margin: 10 }}>
                        <div style={{ flex: 2, display: 'flex', fontFamily: 'Roboto', justifyContent: 'flex-end', fontWeight: 'bold' }}>Lifetime Correct Answer&nbsp; : </div>
                        <div style={{ flex: 2, display: 'flex', fontFamily: 'Roboto', alignItems: 'start' }}> &nbsp; {data.LifeTimeCorrectAnswer}</div>
                    </div>
                    <div style={{ display: (data.attempted_q > 0 && data.total_q == data.attempted_q) ? 'none' : 'flex', flex: 1, width: 400, margin: 10 }}>
                        <div style={{ flex: 2, display: 'flex', fontFamily: 'Roboto', justifyContent: 'flex-end', fontWeight: 'bold' }}> Duration&nbsp; : </div>
                        <div style={{ flex: 2, display: 'flex', fontFamily: 'Roboto', alignItems: 'start' }}> &nbsp; {data.duration} - Minutes</div>
                    </div>
                    <div style={{ display: (data.used_seconds > 0 && data.total_q != data.attempted_q) ? 'flex' : 'none', flex: 1, width: 400, margin: 10 }}>
                        <div style={{ flex: 2, display: 'flex', fontFamily: 'Roboto', justifyContent: 'flex-end', fontWeight: 'bold' }}> Time Left&nbsp; : </div>
                        <div style={{ flex: 2, display: 'flex', fontFamily: 'Roboto', alignItems: 'start' }}> &nbsp; {data.left_q}:00 - Minutes</div>
                    </div>
                    <div style={{ display: 'flex', flex: 1, width: 400, marginTop: 30 }}>
                        <div style={{ flex: 1, display: (data.left_q > 0) ? 'flex' : 'none', fontFamily: 'Roboto', justifyContent: 'center', fontWeight: 'bold' }}>
                            <Button color='teal' onClick={() => {
                                RenderTest(data.total_q, data.duration, data.left_q * 60)
                            }} >
                                {(data.used_seconds > 0) ? 'Resume Test' : 'Start Test'}
                            </Button>
                        </div>
                        <div style={{ flex: 1, display: (data.left_q == 0) ? 'flex' : 'none', fontFamily: 'Roboto', justifyContent: 'center', fontWeight: 'bold' }}>
                            <Button color='teal' onClick={() => {
                                set_show_info_pop_up(false)
                                set_show_result_box(true)
                                view_result()
                            }} >
                                View Result
                            </Button>
                        </div>
                    </div>
                </div>
            </Dialog>
        )
    }

    const RenderTest = (TotalNoOfQuestion, TotalGivenTime, TotalLeftTime) => {
        ReactDOM.render(RenderTestContent(TotalNoOfQuestion, TotalGivenTime, TotalLeftTime), document.getElementById('divMainContent'))
        ReactDOM.render('', document.getElementById('ModelPopUp'))
    }

    const RenderTestContent = (TotalNoOfQuestion, TotalGivenTime, TotalLeftTime) => {
        return (
            <>
                {/* questions box */}
                <audio className="audio-elements">
                    <source src={SubmitSound}></source>
                </audio>
                <div className="container">
                    {RenderInstructionText(TotalNoOfQuestion, TotalGivenTime, TotalLeftTime)}
                    <div style={{
                        //display: show_question ? 'flex' : 'none',
                        flexDirection: 'column'
                    }}>
                        <div id="divQList">
                            {RenderAllQuestion()}
                        </div>
                        <div style={{ flex: 1, display: 'flex', fontFamily: 'Roboto', justifyContent: 'center', fontWeight: 'bold', margin: 20 }}>
                            <a href="#" color="teal" className="ui teal small button  submitQuestionMobile form-control w-auto" size="small" onClick={() => SubmitTest()} variant="contained">
                                Submit
                            </a>
                        </div>
                    </div>
                </div>
            </>
        )
    }

    function get_test_info(c_id, trainerid, clientid, paperset) {

        axios.get('api/qubits/get_qubit_test_info?c_id=' + c_id + '&clientid=' + clientid + '&paperset=' + paperset + '&trainerid=' + trainerid + '')
            .then(function (response) {
                //alert(JSON.stringify(response.data))
                let info = JSON.parse(response.data)
                //alert(info[0].student_name)
                set_student_name(info[0].student_name)
                set_p_type(info[0].p_type)
                set_total_q(info[0].total_q)
                setTestDate(info[0].TestDate)
                setCorrectAnswer(info[0].CorrectAnswer)
                setLifeTimeCorrectAnswer(info[0].LifeTimeCorrectAnswer)
                set_attempted_q(info[0].attempted_q)
                set_left_q(info[0].left_q)
                set_c_name(info[0].c_name)
                set_c_URL(info[0].CourseURL)
                set_show_info_pop_up(true)
                set_duration(info[0].duration)
                set_duration1(info[0].duration1)
                get_all_questions(c_id, clientid, paperset)
                set_used_secods(info[0].used_seconds)
                set_left_seconds(info[0].left_seconds)
                set_main_loader(false)

            })
            .catch(function (error) {
                alert(error);
            })
    }

    function start_countdown() {
        //alert("countdown started" + '--' + used_seconds)
        // used_seconds_ = used_seconds
        // setInterval(function () {
        //     used_seconds_++
        // }, 1000)
    }

    const Completionist = () => <span style={{ color: '#fff', fontSize: 30, display: 'flex', fontFamily: 'Roboto', fontWeight: 'bold' }}>Time Over</span>;
    const renderer = ({ minutes, seconds, completed }) => {
        if (completed) {
            // Render a complete state
            return <Completionist />;
        } else {
            // Render a countdown
            return (
                <span style={{ color: '#fff', fontSize: 30, display: 'flex', fontFamily: 'Roboto', fontWeight: 'bold' }}>
                    {minutes}:{seconds}
                </span>
            );
        }
    };

    function get_all_questions(c_id, clientid, paperset) {
        //get_qbit_non_attempted_que_web
        axios.get('api/qubits/get_qbit_non_attempted_que_web?c_id=' + c_id + '&clientid=' + clientid + '&paperset=' + paperset + '')
            .then(function (response) {
                //alert(JSON.stringify(response.data))
                let questions = JSON.parse(response.data)
                set_all_questions(questions)

            })
            .catch(function (error) {
                alert(error);
            })
    }

    function view_result() {
        const url_param = queryString.parse(window.location.search)
        let c_id = url_param.id
        let clientid = url_param.clientid
        let paperset = url_param.paperset


        set_pop_up(true)
        // cid, int paperset, int clientId
        axios.get('api/qubits/proc_getresultValue?cid=' + c_id + '&paperset=' + paperset + '&clientId=' + clientid + '')
            .then(function (response) {
                //alert(JSON.stringify(response.data))
                let detail = JSON.parse(response.data)

                set_test_detail(detail[0])
                set_show_result(true)
                // set_result_data(JSON.parse(response.data))
            })
            .catch(function (error) {

                alert(error);
            })


        axios.get('api/qubits/pro_get_quesDetails?cid=' + c_id + '&paperset=' + paperset + '&clientId=' + clientid + '')
            .then(function (response) {
                //alert(JSON.stringify(response.data))
                let detail = JSON.parse(response.data)
                console.log(detail)
                set_answer_data(detail)
                //set_show_result(true)
                // set_result_data(JSON.parse(response.data))
            })
            .catch(function (error) {

                alert(error);
            })

        // fetch("/api/student/get_qbit_attempted?stdid=" + clientid + "&cid=" + c_id + "&paper_set=" + paperset + "")
        //     .then(function (data) { return data.json() })
        //     .then(function (json_data) {
        //         //let parse_json = JSON.parse(json_data);
        //         alert(JSON.stringify(json_data))

        //     })
    }
    function formatDate(timestamp) {
        return timestamp
    }
    function download_result() {

        // const input = document.getElementById('divIdToPrint');
        // html2canvas(input)
        //   .then((canvas) => {
        //     const imgData = canvas.toDataURL('image/png');
        //      const pdf = new jsPDF();
        //      pdf.addImage(imgData, 'PNG', 0, 0);
        //      pdf.save("download.pdf"); 
        //   })

        const url_param = queryString.parse(window.location.search)
        let clientid = url_param.clientid

        const emailHTML = renderEmail(
            <Email title="Hello World!">
                <Item align="center">
                    <div style={{
                        height: "90vh",
                        display: show_result_box ? 'flex' : 'none',
                        flexDirection: 'column',
                        backgroundColor: '#fff', width: '50vw',
                        overflow: 'scroll'

                    }}>


                        <div style={{ margin: 50, flexDirection: 'row' }}>
                            <div>TEST RESULT  </div>

                            <table style={{ fontFamily: 'Roboto' }} className="w-100">
                                <tr>
                                    <td style={{ padding: 5, textAlign: 'end' }}><b>Course Name</b></td>
                                    <td style={{ padding: 5, textAlign: 'start' }}>{test_detail.CName}</td>
                                </tr>
                                <tr>
                                    <td style={{ padding: 5, textAlign: 'end' }}><b>Test Taken On</b></td>

                                    <td style={{ padding: 5, textAlign: 'start' }}>{formatDate(test_detail.Date)}</td>
                                </tr>
                                <tr>



                                    <td style={{ padding: 5, textAlign: 'end' }}><b>Total Questions</b></td>
                                    <td style={{ padding: 5, textAlign: 'start' }}>{test_detail.TotalQuestionAttempts}</td>
                                </tr>
                                <tr>
                                    <td style={{ padding: 5, textAlign: 'end' }}><b>Questions Attempted</b></td>
                                    <td style={{ padding: 5, textAlign: 'start' }}>{test_detail.questionattemped}</td>
                                </tr>
                                <tr>
                                    <td style={{ padding: 5, textAlign: 'end' }}><b>Correct Answers</b></td>
                                    <td style={{ padding: 5, textAlign: 'start' }}>{test_detail.CorrectAnswer}</td>
                                </tr>
                                <tr>
                                    <td style={{ padding: 5, textAlign: 'end' }}><b>Incorrect Answers</b></td>
                                    <td style={{ padding: 5, textAlign: 'start' }}>{test_detail.IncorrectAnswer}</td>
                                </tr>

                            </table>
                            <div style={{ backgroundColor: '#fa3e74', height: 3, width: 300, textAlign: 'center', margin: 15 }}></div>
                            {answer_data.map((row) => (
                                <Mail_Result row={row} key={row.q_no}></Mail_Result>
                            ))}

                        </div>


                    </div>

                </Item>
            </Email>
        )



        var customer = { client_id: clientid, html: emailHTML };

        fetch("/api/qubits/insert_device_id_trainer_N", {
            method: 'POST',
            headers: {
                'Content-Type': "application/json"
            },
            body: JSON.stringify(customer),
            //body: '='+{client_id:'123',text:'7'}, test
        })
            .then(async (returnRawDataFromApi) => { return returnRawDataFromApi.json() })
            .then(async function (api_data) {
                alert(JSON.stringify(api_data))
            })

    }

    function SubmitTest() {
        //sound()
        //alert('from child')
        set_show_info_pop_up(false)
        set_show_result_box(true)
        set_show_question(false)
        set_test_complete_pop(false)

        view_result()

    }


    const OpenFeedbackForm = () => {
        setShowFeedbackPopUp(true);
    }

    const SubmitFeedbackForm = () => {
        if (FBText.length == 0) {
            SetFBlabelText('Please Enter Some Text');
        }
        else {
            SendFB(FBText);
            setShowFeedbackPopUp(false);
        }
    }

    const SendFB = (text) => {
        var SendTo = 'editor@qubits42.com'
        //var SendTo = 'nishant.sinha@koenig-solutions.com'
        var HTML = 'Hi <br/><br/> Paperset: ' + PaperSet + ' <br/> Client Name: ' + student_name + '<br/> Course: ' + c_name + ' <br/> Feedback: ' + text;
        SendMail('info@qubits.com', SendTo, '', 'nishant.sinha@koenig-solutions.com', 'Feedback from Qubits Test taker', HTML);
        alert("Thank you for submit your Feedback.");
    }

    async function SendMail(uname, to, cc, bcc, subject, html) {
        try {
            var obj = {
                _uname: uname,
                _to: to,
                _cc: cc,
                _bcc: bcc,
                _subject: subject,
                _html: html
            };
            await axios.post("Nishant/sendemail", obj)
        } catch (er) {
            //alert(JSON.stringify(er));
        }
    }
    const RenderInstructionText = (TQ, TM, LQ) => {
        return (
            <div className="test-outer" >
                <span className="card-body q-instractions mt-4">
                    <div className="heading" style={{ fontSize: '15px' }}><b>Instractions</b></div>
                    <div className="p-3">
                        <div className="mb-2"> 1. There are {TQ} Question in the test which you to finish {TM} minutes</div>
                        <div className="mb-2"> 2. You can share question by clicking on <i className="fa fa-upload ml-2"></i></div>
                        <div className="mb-2">
                            {/* <i className="fa fa-hand-o-right mr-3" style={{ paddingLeft: '1.25rem' }}></i> */}
                            3. You can provide feedback/suggestion by clicking on
                            <i className="fa fa-commenting ml-2"></i>
                        </div>
                        <div>
                            {/* <i className="fa fa-hand-o-right mr-3" style={{ paddingLeft: '1.25rem' }}></i> */}
                            4. * is indicating the difficulty level. * is for Basic, ** is for Intermediate, *** for Advance
                        </div>
                        {/* <div><i className="fa fa-hand-o-right mr-3" style={{ paddingLeft: '1.25rem' }}></i> 4. Question (Unattampted) might be replaced.</div> */}
                    </div>
                </span>
                <div className="countdown">
                    <Countdown date={Date.now() + LQ * 1000} renderer={renderer} />
                </div>
            </div>
        )
    }

    const RenderAllQuestion = () => {
        let UrlParameter = queryString.parse(window.location.search);
        return (
            <>
                <Question c_id={UrlParameter.id} Cl_Id={UrlParameter.clientid} paperset={UrlParameter.paperset}></Question>
            </>
        )
    }

    return (
        <div>
            <Dialog aria-labelledby="simple-dialog-title" fullWidth={true} maxWidth="sm" open={ShowFeedbackPopUp}>
                <div style={{ margin: 20 }}>
                    <div style={{ flex: 1, marginTop: 30 }}>
                        <div className="row">
                            <div className="col-md-12">
                                <label>Your feedback for Qubits</label><br></br>
                                <textarea rows="3" placeholder="Please type here..." className="form-control" onChange={(e) => { SetFBText(e.target.value); SetFBlabelText('') }}></textarea>
                                <label style={{ 'color': 'red' }}>{FBlabelText}</label>
                            </div>
                            <div className="col-md-12 mt-3">
                                <Button color='teal' onClick={() => { SubmitFeedbackForm() }} className="form-control">
                                    Submit Feedback
                                </Button>
                            </div>
                        </div>
                    </div>
                </div>
            </Dialog>
            <Dialog maxWidth="xs" aria-labelledby="simple-dialog-title" open={show_info_pop_up}>
                {/* <DialogTitle id="simple-dialog-title" style={{  flexDirection: 'row' }}>Test Info</DialogTitle> */}
                <Alert style={{ display: (attempted_q > 0 && total_q == attempted_q) ? 'flex' : 'none' }} severity="success">Test Completed</Alert>
                <div style={{ margin: 20 }}>
                    <div style={{ display: 'flex', flex: 1, width: 400, margin: 10 }}>
                        <div style={{ flex: 2, display: 'flex', fontFamily: 'Roboto', justifyContent: 'flex-end', fontWeight: 'bold' }}> Date&nbsp; : </div>
                        <div style={{ flex: 2, display: 'flex', fontFamily: 'Roboto', alignItems: 'start' }}> &nbsp; {TestDate}</div>
                    </div>
                    <div style={{ display: 'flex', flex: 1, width: 400, margin: 10 }}>
                        <div style={{ flex: 2, display: 'flex', fontFamily: 'Roboto', justifyContent: 'flex-end', fontWeight: 'bold' }}> Course&nbsp; : </div>
                        <div style={{ flex: 2, display: 'flex', fontFamily: 'Roboto', alignItems: 'start' }}><a target="_blank" href={"https://www.koenig-solutions.com/" + c_URL}> &nbsp; {c_name}</a></div>
                    </div>
                    <div style={{ display: 'flex', flex: 1, width: 400, margin: 10 }}>
                        <div style={{ flex: 2, display: 'flex', fontFamily: 'Roboto', justifyContent: 'flex-end', fontWeight: 'bold' }}> Student&nbsp; : </div>
                        <div style={{ flex: 2, display: 'flex', fontFamily: 'Roboto', alignItems: 'start' }}> &nbsp; {student_name}</div>
                    </div>
                    <div style={{ display: 'flex', flex: 1, width: 400, margin: 10 }}>
                        <div style={{ flex: 2, display: 'flex', fontFamily: 'Roboto', justifyContent: 'flex-end', fontWeight: 'bold' }}> Total Questions&nbsp; : </div>
                        <div style={{ flex: 2, display: 'flex', fontFamily: 'Roboto', alignItems: 'start' }}> &nbsp; {total_q}</div>
                    </div>
                    <div style={{ display: (CorrectAnswer > 0) ? 'flex' : 'none', flex: 1, width: 400, margin: 10 }}>
                        <div style={{ flex: 2, display: 'flex', fontFamily: 'Roboto', justifyContent: 'flex-end', fontWeight: 'bold' }}> Correct Answer&nbsp; : </div>
                        <div style={{ flex: 2, display: 'flex', fontFamily: 'Roboto', alignItems: 'start' }}> &nbsp; {CorrectAnswer}</div>
                    </div>
                    <div style={{ display: (LifeTimeCorrectAnswer > 0) ? 'flex' : 'none', flex: 1, width: 400, margin: 10 }}>
                        <div style={{ flex: 2, display: 'flex', fontFamily: 'Roboto', justifyContent: 'flex-end', fontWeight: 'bold' }}>Lifetime Correct Answer&nbsp; : </div>
                        <div style={{ flex: 2, display: 'flex', fontFamily: 'Roboto', alignItems: 'start' }}> &nbsp; {LifeTimeCorrectAnswer}</div>
                    </div>
                    <div style={{ display: (attempted_q > 0 && total_q == attempted_q) ? 'none' : 'flex', flex: 1, width: 400, margin: 10 }}>
                        <div style={{ flex: 2, display: 'flex', fontFamily: 'Roboto', justifyContent: 'flex-end', fontWeight: 'bold' }}> Duration&nbsp; : </div>
                        <div style={{ flex: 2, display: 'flex', fontFamily: 'Roboto', alignItems: 'start' }}> &nbsp; {duration} - Minutes</div>
                    </div>
                    <div style={{ display: (used_seconds > 0 && total_q != attempted_q) ? 'flex' : 'none', flex: 1, width: 400, margin: 10 }}>
                        <div style={{ flex: 2, display: 'flex', fontFamily: 'Roboto', justifyContent: 'flex-end', fontWeight: 'bold' }}> Time Left&nbsp; : </div>
                        <div style={{ flex: 2, display: 'flex', fontFamily: 'Roboto', alignItems: 'start' }}> &nbsp; {duration1} - Minutes</div>
                    </div>
                    <div style={{ display: 'flex', flex: 1, width: 400, marginTop: 30 }}>
                        <div style={{ flex: 1, display: (left_q > 0) ? 'flex' : 'none', fontFamily: 'Roboto', justifyContent: 'center', fontWeight: 'bold' }}>
                            <Button color='teal' onClick={() => {
                                set_show_info_pop_up(false)
                                set_show_question(true)
                                //start_countdown()
                            }} >
                                {(used_seconds > 0) ? 'Resume Test' : 'Start Test'}
                            </Button>
                        </div>
                        <div style={{ flex: 1, display: (left_q == 0) ? 'flex' : 'none', fontFamily: 'Roboto', justifyContent: 'center', fontWeight: 'bold' }}>
                            <Button color='teal' onClick={() => {
                                set_show_info_pop_up(false)
                                set_show_result_box(true)
                                view_result()
                            }} >
                                View Result
                            </Button>
                        </div>
                    </div>
                </div>
            </Dialog>
            <Dialog aria-labelledby="simple-dialog-title" open={test_complete_pop}>
                <div style={{ margin: 20 }}>
                    <div style={{ display: 'flex', flex: 1, width: 400, margin: 10 }}>
                        <div style={{ flex: 2, display: 'flex', fontFamily: 'Roboto', justifyContent: 'center', fontWeight: 'bold' }}> Test Completed </div>
                    </div>
                    <div style={{ display: 'flex', flex: 1, width: 400, marginTop: 30 }}>
                        <div style={{ flex: 1, display: 'flex', fontFamily: 'Roboto', justifyContent: 'center', fontWeight: 'bold' }}>
                            <Button color='teal' onClick={() => {
                                set_show_info_pop_up(false)
                                set_show_result_box(true)
                                set_show_question(false)
                                set_test_complete_pop(false)
                                view_result()
                            }} >
                                View Result
                            </Button>
                        </div>
                    </div>
                </div>
            </Dialog>
            <div id="ModelPopUp"></div>
            <div className="header top-sticky">
                <span style={{ color: 'white', fontSize: '25px' }}><b>Test your knowledge for {c_name}</b></span>
            </div>
            <div id="divMainContent" style={{
                flex: 1,
                display: "flex",
                justifyContent: 'center',
                alignItems: 'center'
            }}></div>
        </div>
    );
}

function Result({ row }) {


    return (

        <span style={{
            boxShadow: "10px 10px 15px #aaaaaa", fontSize: 14, fontFamily: 'Roboto',
            padding: '10%', backgroundColor: '#fff', display: 'block', marginTop: '5%', flexDirection: 'column', borderRadius: 5
        }}
        >
            <span style={{ display: 'flex', justifyContent: 'center', fontWeight: 'bold' }}>Question {row.q_no}</span>
            <br></br>
            <p style={{ textAlign: 'left' }}>{row.Question}
            </p>

            <p style={{ textAlign: 'left' }}>correct Answer : {row.correct_answer.replace(/^,|,$/g, '')}</p>

            <p style={{ textAlign: 'left' }}>Your Answer : {row.input_answer.replace(/^,|,$/g, '')}</p>

            <div style={{ flex: 1, display: 'flex', fontFamily: 'Roboto', justifyContent: 'center', fontWeight: 'bold' }}>


                <div style={{ display: (row.IsAnswerCorrect == 1) ? 'block' : 'none', float: 'right', marginLeft: 10 }}>
                    <img src="https://img.icons8.com/fluent/25/000000/checkmark.png" />
                </div>

                <div style={{ display: (row.IsAnswerCorrect == 1) ? 'none' : 'block', float: 'right', marginLeft: 10 }}>
                    <img src="https://img.icons8.com/emoji/25/000000/cross-mark-emoji.png" />
                    {/* <svg height="30px" viewBox="0 0 365.71733 365" width="30px" xmlns="http://www.w3.org/2000/svg"><g fill="#f44336"><path d="m356.339844 296.347656-286.613282-286.613281c-12.5-12.5-32.765624-12.5-45.246093 0l-15.105469 15.082031c-12.5 12.503906-12.5 32.769532 0 45.25l286.613281 286.613282c12.503907 12.5 32.769531 12.5 45.25 0l15.082031-15.082032c12.523438-12.480468 12.523438-32.75.019532-45.25zm0 0"/><path d="m295.988281 9.734375-286.613281 286.613281c-12.5 12.5-12.5 32.769532 0 45.25l15.082031 15.082032c12.503907 12.5 32.769531 12.5 45.25 0l286.632813-286.59375c12.503906-12.5 12.503906-32.765626 0-45.246094l-15.082032-15.082032c-12.5-12.523437-32.765624-12.523437-45.269531-.023437zm0 0"/></g></svg> */}
                </div>
            </div>
            {/* IsAnswerCorrect */}

        </span>

    )
}

function Mail_Result({ row }) {


    return (

        <span style={{
            boxShadow: "10px 10px 15px #aaaaaa", fontSize: 14, fontFamily: 'Roboto',
            padding: 40, backgroundColor: '#fff', display: 'flex', margin: 5, flexDirection: 'column', borderRadius: 5
        }}
        >
            <span style={{ display: 'flex', justifyContent: 'center', fontWeight: 'bold' }}>Question {row.q_no}</span>
            <br></br>
            <p style={{ textAlign: 'left' }}>{row.Question}
            </p>

            <p style={{ textAlign: 'left' }}>correct Answer : {row.correct_answer.replace(/^,|,$/g, '')}</p>

            <p style={{ textAlign: 'left', display: (row.IsAnswerCorrect == 1) ? 'none' : 'block' }}>Your Answer : {row.input_answer.replace(/^,|,$/g, '')}</p>

            <div style={{ flex: 1, display: 'flex', fontFamily: 'Roboto', justifyContent: 'center', fontWeight: 'bold' }}>


                <div style={{ display: (row.IsAnswerCorrect == 1) ? 'block' : 'none', float: 'right', marginLeft: 10 }}>
                    <span style={{ display: 'flex', justifyContent: 'center', fontWeight: 'bold' }}>Correct Answer</span>
                </div>

                <div style={{ display: (row.IsAnswerCorrect == 1) ? 'none' : 'block', float: 'right', marginLeft: 10 }}>
                    <span style={{ display: 'flex', justifyContent: 'center', fontWeight: 'bold' }}>Incorrect Answer</span>

                </div>
            </div>

        </span>

    )
}