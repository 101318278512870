import React from 'react'
import { Container, Input, Image } from 'semantic-ui-react'
import './HomepageStyle.css';
import { Icon, Button, Checkbox, Form } from 'semantic-ui-react'
import { useMediaQuery } from 'react-responsive'
import './Home1.css'
import 'bootstrap/dist/css/bootstrap.min.css';
import Navbar from './Navbar';
import $ from 'jquery'
const axios = require('axios');

const Login = (props) => {
  return (

    <div id="divIdToPrint" className="divIdToPrintDesktop">
      <div className="rightAreaDesktop">
      <Navbar/>
        
        <div className="banner">
          <h1>Feedback</h1>
        </div>
        <div className="container">
          
        </div>
      </div>
      <div class="footer">
        <a href="/DMCA-Copyright">DMCA & LEGAL</a>
      </div>
    </div>

  )
}



export default Login
