import React from 'react'
import ReactDOM from 'react-dom';
import ReactDOMServer from 'react-dom/server';
import { Interweave } from 'interweave';
import '../../../random_test.css'
import { Html } from '@mui/icons-material';

const axios = require('axios');

const Explanation = props => {

    const ShowCorrectAnswer = (QId) => {
        if (props.clientid != null) {
            axios.get("/Nishant/Qubits/GetAnswerStatus?ClientId=" + props.clientid + "&QuesId=" + QId + "&Paperset=" + props.paperset).then(function (r) {
                if (r.data.length > 0) ReactDOM.render(GetAnswerStatement(r.data), document.getElementById('ExplanationSection_ ' + props.QuesId));
                else alert('Please choose your answer first');
            })
        }
    }

    const GetAnswerStatement = (row) => {
        return (
            <>
                <div class="axpCont pt-0">
                    <span className="option">
                        <div className='row'>
                            <div className='col-md-12 text-info'><strong className='border-bottom bordered'>Explanation:</strong></div>
                            <div className='col-md-12 mt-2 text'>
                                <span>
                                    The correct answer is <strong className='text-success'> <Interweave content={row[0].CorrectAnswerExplanation} /></strong>
                                    <br></br>
                                    {row[0].Explanation != "" ? <Interweave content={row[0].Explanation} /> : <></>}
                                </span>
                            </div>
                        </div>
                        <div className='mt-2'>
                            <label className="like-btn" for="like">
                                <input type="Checkbox" name="like" onClick={(e) => LikeThisQuestion(row[0].LinkQuesCourseID)} id={"chkLike_" + row[0].LinkQuesCourseID} />
                                <i className="fa fa-thumbs-o-up"></i>
                                <b>Like</b> <span id={"spNoofLikes_" + row[0].LinkQuesCourseID}>{row[0].NoOfLikesOfExplanation}</span>
                            </label>
                            <label className="like-btn" for="like">
                                <input type="Checkbox" name="Dislike" onClick={(e) => DisLikeThisQuestion(row[0].LinkQuesCourseID)} id={"chkDisLike_" + row[0].LinkQuesCourseID} />
                                <i className="fa fa-thumbs-o-down"></i>
                                <b>Dislike</b> <span id={"spNoofDisLikes_" + row[0].LinkQuesCourseID}>{row[0].NoOfDisLikesOfExplanation}</span>
                            </label>
                            <span id={"spComment_" + row[0].LinkQuesCourseID}>{CommentHTML(row[0].LinkQuesCourseID)}</span>
                        </div>
                        <span id={'spExplanationArea_' + row[0].LinkQuesCourseID}></span>
                    </span>
                </div>
            </>
        )
    }

    const LikeThisQuestion = (Id) => {
        let Val = document.getElementById("chkLike_" + Id).checked;
        axios.get("/Nishant/Qubits/CounterChange?Type=4&QId=" + Id + "&Value=" + Val+"&clientId="+props.clientid).then(function (r) {
            ReactDOM.render(r.data[0].NoOfLikes, document.getElementById("spNoofLikes_" + Id))
        })
    }

    const DisLikeThisQuestion = (Id) => {
        let Val = document.getElementById("chkDisLike_" + Id).checked;
        axios.get("/Nishant/Qubits/CounterChange?Type=6&QId=" + Id + "&Value=" + Val+"&clientId="+props.clientid).then(function (r) {
            ReactDOM.render(r.data[0].NoOfDisLikes, document.getElementById("spNoofDisLikes_" + Id))
        })
    }

    const RenderCommentSection = (Id) => {
        ReactDOM.render(<div className='row'>
            <div className='col-md-12'>
                <textarea className="form-control" id={"txtComment_" + Id} placeholder='Write your comment here' rows={3}></textarea>
            </div>
            <div className='col-md-12 pt-2 text-right'>
                <a className='btn-sm btn-danger mr-1' onClick={() => CancelCommentSection(Id)}><i className='fa fa-close'></i> Cancel</a>
                <a className='btn-sm btn-info' onClick={() => SendExplanationMail(Id)}><i className='fa fa-commenting-o'></i> Comment</a>
            </div>
        </div>, document.getElementById('spExplanationArea_' + Id))
        ReactDOM.render(<></>, document.getElementById('spComment_' + Id))
    }

    const CommentHTML = (Id) => {
        return <a className='btn btn-outline-primary badge' onClick={(e) => RenderCommentSection(Id)}><i className='fa fa-commenting-o'></i> Comment</a>
    }

    const SendExplanationMail = (Id) => {
        var comment = document.getElementById('txtComment_' + Id).value;
        if (comment != '') {
            var obj = {
                Type: 12,
                stdid: props.Cl_Id,
                quesid: Id,
                paper_set: props.paperset,
                Comment: comment
            };
            
            axios.post("/Nishant/Qubits/GetLinkQuestionDetail", obj).then(function (d) {
                if (d.data != null) {
                    saveEmailData(comment,d.data[0].QId)
                    axios.get("/Nishant/Qubits/GetQuestionDetail?QId=" + d.data[0].QId + "&Paperset=" + props.paperset).then(function (response) {
                        if (response.data != null) {
                            let SendTo = GetFeedbackSendTo();
                            var CC = 'editor@qubits42.com';
                            var HTML = ReactDOMServer.renderToStaticMarkup(HTMLDataForFeedback(response.data, comment, false));
                            console.log(HTML);
                            props.SendMail('Qubits@koenig-solutions.com', SendTo, CC, 'Qubits@koenig-solutions.com; vaibhav.aggarwal@koenig-solutions.com;', 'Comment for Qubits', HTML);
                            ReactDOM.render(<label className='text-success'>Thank you for your comment.</label>, document.getElementById('spExplanationArea_' + Id))
                        }
                    }).catch(function (error) {
                        alert(error);
                    });
                }
            })
        }
        else alert('Please enter some comment');
    }

    async function saveEmailData (Feedback,quesId){
        await axios.post("/Nishant/Qubits/SaveFeedBackDetail?AssignmentId=0" + "&Paperset=" + props.paperset + "&Cid=0" + "&ClientId=" + props.clientid+ "&QuesId=" + quesId+ "&FeedBack=" + Feedback+ "&FeedBackType=3").then(function (response) {
            
        }).catch(function (error) {
            alert(error);
        });
    }

    const HTMLDataForFeedback = (d, FB, IsForFeedback = true) => {
        return (
            <>
                <span>Thank you for your {IsForFeedback ? "feedback" : "comment"}.</span> <br /><br />
                <span>URL: </span><span>{window.location.href}</span> <br />
                {GetClientName() != '' ? <><span>Name: </span><span>{GetClientName()}</span> <br /></> : ''}
                <span>Email: </span><span>{GetFeedbackSendTo()}</span> <br />
                <span>Paperset: </span><span>{d[0].PaperSet}</span> <br />
                <span>Course: </span><span>{d[0].CName}</span> <br />
                {d[0].TrainerNameWithEmail != '' ? <><span>Trainer Name: </span><span>{d[0].TrainerNameWithEmail}</span> <br /></> : ''}
                {d[0].Manager_NameWithEmail != '' ? <><span>Delivery Manager: </span><span>{d[0].Manager_NameWithEmail}</span> <br /></> : ''}
                {d[0].AssignmentDeliveryDate != '' ? <><span>Assignment Delivery Date: </span><span>{d[0].AssignmentDeliveryDate}</span> <br /></> : ''}
                {d[0].FeedbackDate != '' ? <><span>Feedback Date: </span><span>{d[0].FeedbackDate}</span> <br /></> : ''}
                <span>Question Id: </span><span>{d[0].QuesWithSource}</span> <br />
                <span>Question Detail: <br /> <div style={{ margin: '5px' }}>{ReturnHtml(d)}</div></span> <br />
                {
                    IsForFeedback ? <><span>Your Feedback: </span><span>{FB}</span> <br /> <br /><br />
                        <span>
                            <div style={{ margin: '5px' }}>We will review and make corrections as required</div>
                            <br /> <br />
                            <div style={{ margin: '5px' }}>Regards, <br /> Qubits-Editors</div>
                        </span></> : <><span>Your Comment: </span><span>{FB}</span> <br /> <br /><br />
                        <span>
                            <div style={{ margin: '5px' }}>Regards, <br /> Qubits-Editors</div>
                        </span></>
                }

            </>
        )
    }

    const GetFeedbackSendTo = () => {
        var SendTo = '';
        if (localStorage.getItem("cordinator_email").toString().includes("qubitusernotreg@qubit.com")) {
            if (localStorage.getItem("TempEmail").toString() != '') SendTo = localStorage.getItem("TempEmail")
            else SendTo = 'editor@qubits.com';
        }
        else SendTo = localStorage.getItem("cordinator_email");
        return SendTo
    }

    const GetClientName = () => {
        var Sender = '';
        if (localStorage.getItem("cordinator_email").toString().includes("qubitusernotreg@qubit.com")) {
            if (localStorage.getItem("TempName").toString() != '') Sender = localStorage.getItem("TempName")
            else Sender = '';
        }
        else Sender = localStorage.getItem("user_name");
        return Sender
    }

    const ReturnHtml = (data) => {
        return (
            <>
                <span>Question: <b> {data[0].Question}</b></span><br></br><br></br>
                <span>Options: </span><br></br><br></br>
                {data[0].Ans1 != "" ? <><span style={{ margin: '5px 20px' }}>A): {data[0].Ans1}</span><br /></> : ""}
                {data[0].Ans2 != "" ? <><span style={{ margin: '5px 20px' }}>B): {data[0].Ans2}</span><br /></> : ""}
                {data[0].Ans3 != "" ? <><span style={{ margin: '5px 20px' }}>C): {data[0].Ans3}</span><br /></> : ""}
                {data[0].Ans4 != "" ? <><span style={{ margin: '5px 20px' }}>D): {data[0].Ans4}</span><br /></> : ""}
                {data[0].Ans5 != "" ? <><span style={{ margin: '5px 20px' }}>E): {data[0].Ans5}</span><br /></> : ""}
            </>
        )
    }

    const CancelCommentSection = (Id) => {
        ReactDOM.render(CommentHTML(Id), document.getElementById('spComment_' + Id))
        ReactDOM.render(<></>, document.getElementById('spExplanationArea_' + Id))
    }

    return (
        <div id={'ExplanationSection_ ' + props.QuesId} className="ans-options">
            {ShowCorrectAnswer(props.QuesId)}
            {/* <div className="text-center p-3"><a className="btn-outline-primary btn text-primary p-2" onClick={(e) => ShowCorrectAnswer(props.QuesId)}>Explanation</a></div> */}
        </div>
    )
}

export default Explanation