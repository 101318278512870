const axios = require('axios');

export function CalculateTotalMinuteWithText (TotalQuestion, TimePerQuestionInSecond, TotalNoOfWordle, NoOfSecondForWordle) {
    //console.log(TotalQuestion, TimePerQuestionInSecond, TotalNoOfWordle, NoOfSecondForWordle)
    var TotalSecond = (TotalQuestion * TimePerQuestionInSecond) + (TotalNoOfWordle * NoOfSecondForWordle)
    return parseInt(TotalSecond / 60) + " minutes ";// + TotalSecond % 60 + " seconds";
}

export const CopyTimerButtonLog = (PaperSet, ClientId, OnOff) => {
    axios.get("/Nishant/Qubits/CopyPausePlayButtonData?PaperSet=" + PaperSet + "&ClientId=" + ClientId + "&OnOff=" + OnOff)
}
