import React from 'react'
import CommonHelper from '../Reusable/Utility/CommonHelper';

const HandleDailyTest = () => {
    const queryString = require('query-string');
    const url_param = queryString.parse(window.location.search)
    React.useEffect(() => {
        window.location.href = '/MyTest/' + CommonHelper.EnCodeURL(url_param.id) + '/' + CommonHelper.EnCodeURL(url_param.trainerid) + '/' + CommonHelper.EnCodeURL(url_param.clientid) + '/' + CommonHelper.EnCodeURL(url_param.paperset)
    }, [])

    return (
        <></>
    )
}

export default HandleDailyTest
