import React from 'react'
import  "./Managementteam.css"
import 'bootstrap/dist/css/bootstrap.min.css';
import Navbar from './Navbar';
import $ from 'jquery'
export const ManagementTeam = () => {
  return (
    <div id="divIdToPrint" className="divIdToPrintDesktop">
    <div className="rightAreaDesktop">
  
    <Navbar/>
    
    <div className='container-fluid bg-grey pb-5'>
    <div className="container mont">
          <div className="row mt-5 justify-content-around">
            <div className="col-md-3 mt-5 mb-5 p-0">
              <div className="main-card p-3">
                <div className="img-bg-card">
                    <img src="team-01.png" alt="team-1"/>
                </div>
                <div className='text-box'>
                    <h3 className='mb-0'>Richa Singh</h3>
                    <h6 className='mt-0'>
                    Please report any content-related issues to Richa at <br/>
                     <a href='mailto:richa.singh@rayontara.com'>richa.singh@rayontara.com</a>
                    </h6>
                </div>
              </div>
            </div>
          
            <div className="col-md-3 mt-5 mb-5 p-0">
              <div className="main-card p-3">
                <div className="img-bg-card">
                    <img src="team-02.png" alt="team-1"/>
                </div>
                <div className='text-box'>
                    <h3 className='mb-0'>Vaibhav Aggarwal</h3>
                    <h6 className='mt-0'>
                    Please report any UI/UX-related issues to Vaibhav at  <br/>
                     <a href='mailto:Vaibhav.Aggarwal@rayontara.com '>Vaibhav.Aggarwal@rayontara.com </a>
                    </h6>
                </div>
              </div>
            </div>
          </div>
        </div>
    </div>
    </div>
    {/* <div class="footer">
      <a href="/DMCA-Copyright">DMCA & LEGAL</a>
    </div> */}
  </div>

  )
}
