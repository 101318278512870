import React from 'react'
import { useParams } from 'react-router-dom';
import ReactDOM from 'react-dom';
import Random_test from '../../Random_test';
import CommonHelper from '../Reusable/Utility/CommonHelper';

const axios = require('axios');
const HandleRandomTestURL = () => {
    const { CId } = useParams();
    const { PaperSet } = useParams();

    React.useEffect(() => {
        FetchActualPaperSet()
    }, [])
    
    const FetchActualPaperSet = async () => {
        let PS = 0
        if (typeof PaperSet === 'undefined') {
            await axios.get('/Nishant/Qubits/GetAllModule?Type=19&CId=' + CId).then(function (response) {
                if (response.data[0].QubitsPaperSet != "0") PS = response.data[0].QubitsPaperSet;
            })
        } 
        else PS = CommonHelper.EnCodeURL(PaperSet);
        CallTestComponant(PS)
    }

    const CallTestComponant = (PS) => {
        ReactDOM.render(<Random_test PaperSet={PS} CId={CommonHelper.EnCodeURL(CId)}></Random_test>, document.getElementById('divMain'))
    }

    return (
        <div>
            <div id='divMain'></div>
        </div>
    )
}

export default HandleRandomTestURL
