import React, { useEffect } from 'react'
import ReactDOM from 'react-dom';
import { confirmAlert } from 'react-confirm-alert'; // Import
import 'react-confirm-alert/src/react-confirm-alert.css'; // Import css
import CircularProgress from '@material-ui/core/CircularProgress';
import { Label, Table, Button } from 'semantic-ui-react';
import AppBarMenu from './Nishant/Reusable/QLAppbar'
import Chip from '@material-ui/core/Chip';
import ReactDOMServer from 'react-dom/server';
import { Interweave } from 'interweave';
const queryString = require("query-string");

const axios = require('axios');

export default function ModuleWise () {
    const [IsValidLogin, setIsValidLogin] = React.useState(false);
    const [ModuleData, setModuleData] = React.useState([]);
    const [main_loader, set_main_loader] = React.useState(false);

    useEffect(() => {
        if (localStorage.getItem("cl_id") > 0) {
            setIsValidLogin(true)
            set_main_loader(true)
            get_test()
        }
        console.log('ashish')
    }, [])

    function get_test() {
        console.log('ashish')
        let cordinator_id = localStorage.getItem("cordinator_id")
        let cord_type = localStorage.getItem("cordinator_type")
        const url_param = queryString.parse(window.location.search)
        if (cordinator_id > 0) {
            axios.get('Nishant/Qubits/get_QubitsDataByStudent?email=' + localStorage.getItem('cordinator_email') + '&type=4&CourseId='+url_param.CId).then(function (response) {
                setModuleData(response.data)
                console.log(response.data)
                // set_main_loader(false)
            })
        }
    }

    const CreateNotAttemptedQuestion = (CourseId, ClientId,ModuleId) => {
        var NoOfQuestion = document.getElementById('txtTotalNoOfQuestionsSeletected_' + ModuleId).value;
        console.log(document.getElementById('txtTotalNoOfQuestionsSeletected_' + ModuleId).value,CourseId,ClientId,ModuleId)
        axios.get('Nishant/Qubits/CreateCustomQubitsTest?Course=' + CourseId + '&ClientId=' + ClientId + '&type=5&NoOfQuestion=' + NoOfQuestion+'&moduleId='+ModuleId).then(function (response) {
            if (response.data != null) {
                window.open('' + window.location.origin + '/My_tes?id=' + CourseId + '&trainerid=0&clientid=' +
                ClientId + '&paperset=' + response.data[0].PaperSet, 'mywindow'
                )
            }
        })
    }


    const RendorBody=()=>{
       return (<> {ModuleData.length > 0 ? <>
            <div className='text-left'><button class="ui circular labeled icon button blue" onClick={() => {
              window.location.href = window.location.origin + '/student';
            }}>
    <i class="left chevron icon"></i>
      Back
  </button></div>
            <Table celled singleLine className="responsivTable">
                <Table.Header className='tabHead'>
                    <Table.Row>
                        <Table.HeaderCell style={{ fontSize: 16, fontWeight: 'bold' }}>Module Name </Table.HeaderCell>
                        <Table.HeaderCell style={{ fontSize: 16, fontWeight: 'bold', textAlign: 'center' }}>Total Questions</Table.HeaderCell>
                        <Table.HeaderCell style={{ fontSize: 16, fontWeight: 'bold', textAlign: 'center' }}>Total Attempted</Table.HeaderCell>
                        <Table.HeaderCell style={{ fontSize: 16, fontWeight: 'bold', textAlign: 'center' }}>Correct Answer</Table.HeaderCell>
                        <Table.HeaderCell style={{ fontSize: 16, fontWeight: 'bold', textAlign: 'center' }}>Score (%)</Table.HeaderCell>
                        <Table.HeaderCell style={{ fontSize: 16, fontWeight: 'bold', textAlign: 'center' }}>Total (Unused  + Incorrect)</Table.HeaderCell>
                    </Table.Row>
                </Table.Header>
                <Table.Body>
                    {
                        ModuleData.map((row) => (
                            <Table.Row>
                                <Table.Cell
                                    style={{ width: 320, fontSize: 14, fontWeight: 'bold' }}
                                    title={row.Module}
                                    mob-title={'Test'}
                                >
                                    <span title={row.Module}> {row.Module.length > 40 ? row.Module.substring(0, 40) + '...' : row.Module} </span>
                                </Table.Cell>
                                <Table.Cell mob-title={'TotalNoOfActiveQuestion'} style={{ fontSize: 14, fontWeight: 'bold', textAlign: 'center' }}> {row.TotalNoOfActiveQuestion}</Table.Cell>
                                <Table.Cell mob-title={'TotalAttemptedQuestion'} style={{ fontSize: 14, fontWeight: 'bold', textAlign: 'center' }}>{row.TotalAttemptedQuestion}</Table.Cell>
                                <Table.Cell mob-title={'TotalCorrectAttemptedQuestion'} style={{ fontSize: 14, fontWeight: 'bold', textAlign: 'center' }}>{row.TotalCorrectAttemptedQuestion}</Table.Cell>
                                <Table.Cell mob-title={'TotalCorrectAttemptedQuestion'} style={{ fontSize: 14, fontWeight: 'bold', textAlign: 'center' }}>{row.Score}%</Table.Cell>
                                <Table.Cell mob-title={'Incorrect'} style={{ fontSize: 14, fontWeight: 'bold', textAlign: 'center' }}>
                                    <stron className='mr-2'>{parseInt(row.TotalNoOfActiveQuestion) - parseInt(row.TotalCorrectAttemptedQuestion)}</stron>
                                    <input type='number' id={'txtTotalNoOfQuestionsSeletected_' + row.ModuleId}
                                        className='form-control col-3' style={{ display: 'inherit' }}
                                        max={parseInt(row.TotalNoOfActiveQuestion) - parseInt(row.TotalCorrectAttemptedQuestion)}
                                        defaultValue={parseInt(row.TotalNoOfActiveQuestion) - parseInt(row.TotalCorrectAttemptedQuestion) <= 20 ? parseInt(row.TotalNoOfActiveQuestion) - parseInt(row.TotalCorrectAttemptedQuestion) : 20}
                                        ></input> 
                                    <Chip className='ml-2' onClick={() => { CreateNotAttemptedQuestion(row.CourseId, row.StdId,row.ModuleId) }} size="small" label="Attempt Now" />
                                </Table.Cell>
                            </Table.Row>
                        ))}
                </Table.Body>
            </Table>
            <br></br>
        </> : ""}</>)
    }

    // const RendorBody=()=>{
    //     return <><h1>Hello There</h1></>
    // }

    const RendorMainContent = () => {
        return (
            <>
                {RendorBody()}
                <span style={{ display: main_loader ? 'block' : 'none', position: 'relative', left: 500, justifyContent: 'center', alignItems: 'center' }}>
                    {/* <CircularProgress color="secondary" /> */}
                </span>
            </>
        )
    }

    return (
        <>
            <div id="LoginPopup"></div>
            <div style={{ display: IsValidLogin ? "flex" : 'none' }}>
                <AppBarMenu BoxContent={RendorMainContent()} HeaderText="Dashboard" WelcomeText={localStorage.getItem("user_name")}></AppBarMenu>
            </div>
        </>
    );

}

