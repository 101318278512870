import React, { useEffect, createContext } from 'react';
import { Dialog } from '@material-ui/core';
import LoginScreen from './LoginComponant/LoginScreen';

const IsAnyRedirectionAfterClose = createContext();
const Login = (props)  => {

    const ReceiveDataFromChild = (data) => {
        props.SendDataToSuperParent(data);
    }

    return (
        <>
            <Dialog aria-labelledby="simple-dialog-title" open={true}>
                <div id="MainContent">
                    <IsAnyRedirectionAfterClose.Provider value={typeof props.IsAnyRedirectionAfterClose !== "undefined" ? props.IsAnyRedirectionAfterClose : true}>
                        <LoginScreen SendDataToParent={ReceiveDataFromChild}></LoginScreen>
                    </IsAnyRedirectionAfterClose.Provider>
                </div>
            </Dialog>
        </>
    )
}

export default Login
export {IsAnyRedirectionAfterClose};

