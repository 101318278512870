import React, { useEffect, useContext } from 'react';
import ReactDOM from 'react-dom';
import Divider from "@material-ui/core/Divider";
import { Alert } from '@material-ui/lab';
import DialogTitle from '@material-ui/core/DialogTitle';
import TextField from '@material-ui/core/TextField';
import CircularProgress from '@material-ui/core/CircularProgress';
import { Icon, Button } from 'semantic-ui-react'
import ForgotPassword from './ForgotPassword';
import CreateNewAccount from './CreateNewAccount'
import { IsAnyRedirectionAfterClose } from '../Login';

const axios = require('axios');

const LoginScreen = ({SendDataToParent}) => {
    const IsAnyRedirectionAfterCloseValue = useContext(IsAnyRedirectionAfterClose)
    const [invalid_login, set_invalid_login] = React.useState(false);
    const [login_text, set_login] = React.useState('login');
    const [email, set_email] = React.useState('');
    const [pwd, set_pwd] = React.useState('');
    const [ErrorMsg, setErrorMsg] = React.useState(false);

    const login = () => {
        if (email == "") {
            setErrorMsg('Please enter a valid Email id')
            set_invalid_login(true)
        }
        else if (pwd == "") {
            setErrorMsg('Please enter a valid password')
            set_invalid_login(true)
        }
        if (email != "" && pwd != "") {
            set_login('logging...')
            var obj = {
                Email: email.trim(),
                PWD: pwd.trim()
            };
            axios.post("/Nishant/Qubits/QubitsLogin", obj).then(function (response) {
                if (response.data.length == 0) {
                    set_invalid_login(true)
                    setErrorMsg('Invalid Credentials')
                    set_login('login')
                }
                else {
                    let res = response.data;
                    SendDataToParent(res);
                }
            })
        }
    }

    const RendorForgotPasswordSection = () => {
        ReactDOM.render(<ForgotPassword></ForgotPassword>, document.getElementById('MainContent'))
    }

    const CreateNewLogin = () => {
        ReactDOM.render(<CreateNewAccount></CreateNewAccount>, document.getElementById('MainContent'))
    }

    const PurchaseQubits = () => {
        window.open("https://www.koenig-solutions.com/qubits-test", "_blank")
    }

    const CloseModel = () =>{
        if(IsAnyRedirectionAfterCloseValue) window.location.href = window.location.origin;
        else ReactDOM.render("", document.getElementById('LoginPopup'))
    }

    return (
        <div>
            <div>
                <Alert style={{ display: invalid_login ? 'flex' : 'none' }} severity="error">{ErrorMsg}</Alert>
                <Icon onClick={() => { CloseModel() }} style={{ margin: 10 }} color="orange" name='close' size='large' />
                <DialogTitle id="simple-dialog-title" style={{ flexDirection: 'row' }}><h2 style={{ fontSize: 18, textAlign:'center', fontWeight: "bolder", marginTop: 10 }}>Login</h2> </DialogTitle>
                <div style={{ margin: 30, flexDirection: 'column', display: 'flex', alignItems: 'center' }}>
                    <TextField
                        style={{ width: 250 }}
                        onChange={(event) => {
                            set_email(event.target.value)
                        }}
                        id="outlined-basic-Email"
                        label="Email"
                        variant="outlined" />
                    <br></br>
                    <br></br>
                    <TextField
                        style={{ width: 250 }}
                        onChange={(event) => {
                            set_pwd(event.target.value)
                        }}
                        type='password'
                        id="outlined-basic-PWD"
                        label="Password"
                        variant="outlined" />
                    <br></br>
                    <br></br>
                    <Button style={{
                        backgroundColor: "#21b6ae", fontSize: "14px",
                        color: '#fff', width: 250,
                        flexDirection: 'row'
                    }} onClick={() => { login() }} variant="contained" >
                        {login_text}
                        <CircularProgress style={{ display: (login_text == 'login') ? 'none' : '', height: 20, width: 20, marginleft: 10, color: '#fff' }} />
                    </Button>
                    <br></br>
                    <br></br>
                    <span style={{
                        //display:new_user?'none':'flex',
                        color: "#21b6ae",
                        padding: "1px 30px",
                        fontSize: "14px",
                        cursor: 'pointer',
                        fontWeight: 'bold',
                        flexDirection: 'row'
                    }} onClick={() => {
                        //login()
                        RendorForgotPasswordSection()
                    }}>
                        Forgotten Password ?
                    </span>
                    <br></br>
                    <br></br>
                    <Divider />
                    <Button
                        onClick={() => {
                            PurchaseQubits()
                        }}
                        style={{ marginTop: 20 }}
                        positive>Purchase Qubits
                    </Button>
                    {/* <Button
                        onClick={() => {
                            CreateNewLogin()
                        }}
                        style={{ marginTop: 20 }}
                        positive>Create New Account
                    </Button> */}
                </div>
            </div>
        </div>
    )
}

export default LoginScreen
