import React from 'react'
import { FcFeedback } from "react-icons/fc";

const TestInstruction = (props) => {
    return (
        <>
            <div className="test-outer" >
                <span className="card-body q-instractions mt-4">
                    <div className="heading" style={{ fontSize: '15px' }}><b>Instructions</b></div>
                    <div className="p-3" style={{fontSize: '16px', fontWeight:'bold'}}>
                        {/* <div className="mb-2">
                            1. An explanation is provided for every Q&A. You cannot change your answer after viewing the explanation.
                        </div> */}
                        {props.TotalNoOfWordle > 0 ?
                            <div className="mb-2">
                                1. <span style={{ fontStyle: "italic", color:"green" }}>"Wordle!"</span> This is a one-word answer. Please use acronyms wherever possible. 
                            </div>
                            : <></>
                        }
                        {/* <div className="mb-2">
                            {props.TotalNoOfWordle > 0 ? 2 : 1}. <i className="fa fa-star" aria-hidden="true" style={{ color: "yellow" }}></i> is indicating the difficulty level.
                            <i className="fa fa-star" aria-hidden="true" style={{ color: "yellow" }}></i> for Basic,
                            <i className="fa fa-star" aria-hidden="true" style={{ color: "yellow" }}></i>
                            <i className="fa fa-star" aria-hidden="true" style={{ color: "yellow" }}></i> for Intermediate,
                            <i className="fa fa-star" aria-hidden="true" style={{ color: "yellow" }}></i>
                            <i className="fa fa-star" aria-hidden="true" style={{ color: "yellow" }}></i>
                            <i className="fa fa-star" aria-hidden="true" style={{ color: "yellow" }}></i> for Advance
                        </div> */}
                        <div className="mb-2">
                        {props.TotalNoOfWordle > 0 ? 2 : 1}. Pause and play options are provided in the test for your convenience.
                        </div>
                        <div className="mb-2">
                        {props.TotalNoOfWordle > 0 ? 3 : 2}. Once you submit your answers, explanations will be provided for each Q & A.
                        </div>
                        <div className="mb-2">
                        {props.TotalNoOfWordle > 0 ? 4 : 3}. Seeking your help to make Qubits better. Questions are collated from various sources. Though we try our best to ensure their correctness, if you suspect any question is incorrect, please let us know using the comments icon. <FcFeedback style={{fontSize:"19px"}} />
                        </div>
                        {/*<div className="mb-2">
                            {props.TotalNoOfWordle > 0 ? 5 : 4}. Be careful to select the answers. There is no separate submit button for each question.
                        </div>
                         <div>
                            {props.TotalNoOfWordle > 0 ? 5 : 4}. Use <FcFeedback className="ml-1" /> to suggest new question for Qubits.
                        </div> */}
                    </div>
                </span>
            </div>
        </>
    )
}

export default TestInstruction