import React, { useEffect } from 'react';
import ReactDOM from 'react-dom';
import DialogTitle from '@material-ui/core/DialogTitle';
import TextField from '@material-ui/core/TextField';
import { Icon, Button } from 'semantic-ui-react'
import VerifyOtp from '../LoginComponant/VerifyOtp';
import LoginScreen from './LoginScreen';

const axios = require('axios');

const ForgotPassword = (props) => {
    const [email, set_email] = React.useState('');

    const send_password_link = () => {
        axios.get('api/student/SendChangePasswordLink?emailid=' + email + '').then(function (response) {
            alert(JSON.stringify(response.data))
        })
    }

    const ResetPasswordForEmployee = () => {
        axios.get('/Nishant/FGTAPI/VerifyUser?id=' + email).then(function (response) {
            if (!response.data.Ok) alert('Invalid Email')
            else {
                ReactDOM.render(<VerifyOtp OTPValue={response.data.OTP} Email={response.data.data[0].UserEmail}></VerifyOtp>, document.getElementById('ForgotPasswordContent'));
            }
        }).catch(function (error) {
            alert(error);
        })
    }

    const change_password = () => {
        if (email != "") {
            axios.get('api/Student/qubit_EmailExist?email=' + email + '').then(function (response) {
                let res = JSON.parse(response.data)
                if (res[0].res == '1') {
                    if (res[0].EmailType == 'STD') send_password_link()
                    else ResetPasswordForEmployee()
                }
                else {
                    alert("this email does not exist! try create new account")
                }
            })
        }
        else {
            alert("Enter Your Email")
        }
    }

    const ReturnToLogin = () => {
        ReactDOM.render(<LoginScreen></LoginScreen>, document.getElementById('MainContent'))
    }

    return (
        <>
            <div>
                {/* <Alert style={{ display: invalid_login ? '' : 'none' }} severity="error">Invalid Login</Alert> */}
                <Icon onClick={() => { window.location.href = window.location.origin }} style={{ marginTop: 5 }} color="orange" name='close' size='large' />
                <DialogTitle id="simple-dialog-title" style={{ flexDirection: 'row' }}>
                    <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'center' }}>
                        <Icon onClick={() => {
                            ReturnToLogin()
                        }} color="teal" size="large" style={{ cursor: 'pointer', marginTop: 22 }} name='arrow left' />
                        <h3 style={{ fontWeight: "bolder" }}>Change Password</h3>
                    </div>
                </DialogTitle>
                <div style={{ margin: '50px 30px', flexDirection: 'column', display: 'flex', alignItems: 'center' }} id="ForgotPasswordContent">
                    <TextField
                        style={{ width: 250 }}
                        onChange={(event) => {
                            set_email(event.target.value)
                        }}
                        id="outlined-basic"
                        label="Email"
                        variant="outlined" />
                    <br></br>
                    <br></br>
                    <Button style={{
                        backgroundColor: "#21b6ae", fontSize: "14px",
                        color: '#fff', maxWidth: 250, width: '100%',
                        flexDirection: 'row'
                    }} onClick={() => {
                        change_password()
                    }} variant="contained" >
                        Change Password
                    </Button>
                    <br></br>
                    <br></br>
                </div>
            </div>
        </>
    )
}

export default ForgotPassword
