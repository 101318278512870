class CommonHelper {
    static CalculateTotalMinute = (TotalQuestion, TimePerQuestionInSecond) => {
        return TotalQuestion * TimePerQuestionInSecond
    }

    static CalculateTotalMinute = (TotalQuestion, TimePerQuestionInSecond, TotalNoOfWordle, NoOfSecondForWordle) => {
        return (TotalQuestion * TimePerQuestionInSecond) + (TotalNoOfWordle * NoOfSecondForWordle)
    }

    static CreateLoginTempData = (res, pwd = 0,rms) => {
        if (res.length > 0) {
            if(rms)
            {
                localStorage.setItem("rms", 1)
            }
            localStorage.setItem("user_name", res[0].Name)
            if (res[0].type == 'cordinator') {
                localStorage.setItem("co_id", res[0].StdId)
                localStorage.setItem("cl_email", res[0].Email)
                localStorage.setItem("cordinator_id", res[0].StdId)
                localStorage.setItem("cordinator_email", res[0].Email)
                localStorage.setItem("cordinator_type", res[0].type)
            }
            localStorage.setItem("cl_id", res[0].StdId)
            localStorage.setItem("cl_email", res[0].Email)
            localStorage.setItem("cordinator_id", res[0].StdId)
            localStorage.setItem("cordinator_email", res[0].Email)
            localStorage.setItem("cordinator_type", res[0].type)
            return res;
        }
    }

    static EnCodeURL = (mystring) => {
        return mystring.replace(/%20/g, "").replace(/&/g, "").replace(/>/g, "").replace(/</g, "").replace(/"/g, "").replace(/ /g, "");
    }

    static getRandomInt = (min, max) => {
        min = Math.ceil(min);
        max = Math.floor(max);
        return Math.floor(Math.random() * (max - min + 1)) + min;
    }

    static CopyEvent = (e) => {
        e.preventDefault();
    }

    static CampareTwoArray = (Arr1, Arr2) => { return Arr1.filter(f => !Arr2.includes(f)) }

    static customEncodeURIComponent = (input) => {
        return encodeURIComponent(input)
          .replace(/%20/g, '+')
          .replace(/%2F/g, '/')
          .replace(/%3D/g, '=');
      }
}

export default CommonHelper