import React from "react";
import ReactDOM from "react-dom";
import "bootstrap/dist/css/bootstrap.min.css";
import "./HomepageStyle.css";
import Autocomplete from '@material-ui/lab/Autocomplete';
import TextField from '@material-ui/core/TextField';
import $, { data } from "jquery";
import CommonHelper from "./Nishant/Reusable/Utility/CommonHelper";
import Navbar from './Navbar';
import { Icon } from "semantic-ui-react";
import NoOfQuestionSelection from "./Nishant/Componant/NoOfQuestionSelection";
import LoginForm from './Nishant/Reusable/Login';

const axios = require("axios");
const queryString = require("query-string");

export default function Homepage() {
  const [searchResults, setSearchResults] = React.useState([]);
  const [cid, set_cid] = React.useState(0);
  const [TotalQuestionSelected, setTotalQuestionSelected] = React.useState(0);
  const [loading, set_loading] = React.useState(false);
  const [IsFromRMS, setIsFromRMS] = React.useState(false);

  React.useEffect(() => {
    const url_param = queryString.parse(window.location.search)
    if (typeof (url_param.id1) != 'undefined' && typeof (url_param.Id2)) {
      CheckLoginFromRMS()
      CheckLoginStatus()
      search()
    }
    else window.location.href = "https://www.koenig-solutions.com/qubits-test";
  }, []);

  const CheckLoginFromRMS = async () => {
    const url_param = queryString.parse(window.location.search)
    let Email = await GetEncryptedValueMVCAPI(CommonHelper.customEncodeURIComponent(url_param.id1));
    let PWD = await GetEncryptedValueMVCAPI(CommonHelper.customEncodeURIComponent(url_param.Id2));
    console.log(Email+" "+PWD)
    if (Email != "N/A" && PWD != "N/A") {
      setIsFromRMS(true)
      var obj = {
        Email: Email.trim(),
        PWD: PWD.trim()
      };
      axios.post("Nishant/Qubits/QubitsLogin", obj).then(function (response) {
        if (response.data.length == 0) {
          setIsFromRMS(false)
          window.location.href = "https://www.koenig-solutions.com/qubits-test"
        } 
        else {
          let res = response.data;
          ReceiveDataFromSuperChild(res, false);
        }
      })
    }
    else
    {
      window.location.href = "https://www.koenig-solutions.com/qubits-test"
    }
  }

  const GetEncryptedValueMVCAPI = async (Value) => {
    let V = "";
    await axios.get("https://api.koenig-solutions.com/api/Qubits/GetDValue?Evalue=" + Value).then(function (response) {
      console.log(response)
      V = response.data;
    }).catch(function (error) {
      alert(error);
    });
    return V;
  }

  const CheckLoginStatus = () => {
    if (localStorage.getItem("user_name") == "null") localStorage.clear();
  }

  function search() {
    axios.get("/Nishant/Qubits/get_CLT_Search").then(function (response) {
      let obj = response.data;
      setSearchResults(obj);
    })
  }

  function generate_paper_set(q) {
    if (localStorage.getItem("cordinator_email") == null) {
      ReactDOM.render(<LoginForm IsAnyRedirectionAfterClose={false} SendDataToSuperParent={ReceiveDataFromSuperChild}></LoginForm>, document.getElementById('LoginPopupHomepage'));
    }
    else if(IsFromRMS==false)
    {
      window.location.href = "https://www.koenig-solutions.com/qubits-test"
    }
    else {
      if (cid != 0) {
        set_loading(true);
        axios.get("Nishant/qubits/get_random_qubit_question?c_id=" + cid + "&no=" + TotalQuestionSelected).then(function (response) {
          let paper_set = parseInt(response.data[0].PaperSet);
          if (isNaN(paper_set)) {
            set_loading(false);
          } else {
            set_loading(false);
            window.location.href = window.location.origin + "/test/" + cid + "/" + paper_set;
          }
        })
          .catch(function (error) {
            alert(error);
          });
      }
      else alert('Please select any course');
    }
  }

  const ReceiveDataFromSuperChild = (res, isRedirect = true) => {
    if (res.length > 0) {
      CommonHelper.CreateLoginTempData(res)
      if (isRedirect) window.location.href = window.location.origin + '/student'
      localStorage.setItem("user_name", res[0].Name)
    }
  }

  const RenderCodeAfterCourseSelected = (value) => {
    set_cid(value.CId);
    if (IsFromRMS == 1) {
      ReactDOM.render(<NoOfQuestionSelection QDetail={value} ReceiveNoOfTotalSelectedQuestion={TotalQuestionSelectedFormChild}></NoOfQuestionSelection>, document.getElementById('divRendorQuestionSelection'))
    }
    else setTotalQuestionSelected(value.total_question < 20 ? value.total_question : 20)
  }

  const TotalQuestionSelectedFormChild = (d) => {
    setTotalQuestionSelected(d)
  }

  return (
    <div>
      <div id="divIdToPrint" className="divIdToPrintDesktop">
      <div id="LoginPopupHomepage"></div>
        <div className="rightAreaDesktop">
        <Navbar />
          
          <div className="divQubitImgDesktopOuter">
            <div className="divQubitImgDesktop">
              <img
                //src="/qb.png"
                src="/qbLT.png"
                alt="QUBITS"
                style={{ height: 166, width: 278 }}
                //src="http://qubits.training/Images/logo/qubit-logo-3.jpg"
                className="QubitImgDesktop"
              />
            </div>
            <span><b>Welcome to the <a className="text-primary">Qubits42.com</a>!</b></span>
            <br></br>
            <div className="row justify-content-center" style={{ textAlign: "left", width: '100%' }}>
                <div className="col-md-12">
                  <span className="no-break">
                    Qubits is the question bank of IT Questions to help increase your knowledge.
                  </span>
                </div>
            </div>
              <div className="row justify-content-center" style={{ textAlign: "left", width: '100%' }}>
                <div className="col-md-10 mt-3">
                  <Autocomplete
                    autoComplete={true}
                    id="SearchVendor"
                    fullWidth={true}
                    size="small"
                    defaultValue={null}
                    options={searchResults}
                    getOptionLabel={(option) => option.CName}
                    onChange={(e, value) => {
                      RenderCodeAfterCourseSelected(value);
                    }}
                    getOptionSelected={(option, value) => option.CId === value.CId }
                    renderInput={(params) => <TextField onChange={(e) => { search(e.target.value) }}  {...params} label="Search Course" variant="outlined" />}
                  />
                </div>
                <div className="col-md-2 mt-3" style={{ textAlign: "center"}}>
                  <div className="start-btn" style={{ display: "inline-flex", flexDirection: "row" }} onClick={() => generate_paper_set(20)}>
                    <h3 style={{ margin: "0px 10px",  fontSize: "16px", }}>
                      Start
                    </h3>
                    <Icon
                      size="large"
                      style={{
    
                        fontSize: "16px",
                        display: loading ? "none" : "flex",
                      }}
                      name="arrow right"
                    />
                    <Icon
                      style={{
                        opacity: 0.8,
                        marginTop: 12,
                        display: loading ? "flex" : "none",
                      }}
                      loading
                      size="large"
                      name="circle notch"
                    />
                  </div>
                </div>
              </div>
              <div className="row justify-content-center" style={{ textAlign: "left", width: '100%' }}>
              <div className="col-md-6" id="divRendorQuestionSelection"></div>
              
              <div className="col-md-3"></div>
              </div>
            </div>
          </div>
         <div class="footer">
          <a href="/DMCA-Copyright">DMCA & LEGAL</a>
        </div>
      </div>
    </div>
  );
}

$(document).ready(function () {
  $(".rightHeaderMobile").hide();
  $(".fa-bars").click(function () {
    debugger;
    $(".fa-times").show();
    $(".fa-bars").hide();
    $(".rightHeaderMobile").fadeIn(500);
  });
  $(".fa-times").click(function () {
    $(".fa-bars").show();
    $(".fa-times").hide();
    $(".rightHeaderMobile").fadeOut(500);
  });

  $(".listSearchDesktop").click(function () {
    $(".SearchBoxItemDesktop").hide();
  });
});
