import React, { useEffect } from 'react'
import { useParams } from 'react-router-dom';

const axios = require('axios');
const CreateTest = () => {
    const { CId } = useParams();
    const { ClientId } = useParams();
    const { ModuleId } = useParams();
    const { AssignmentId } = useParams();
    const { PT } = useParams();
    const { NoOfQuestions } = useParams();

    useEffect(() => {
        if(typeof NoOfQuestions === 'undefined') GetTestStatus()
        else GenerateTest()
    }, [])

    const GetTestStatus = async () => {
        var obj = {
            Type: 2,
            CId: CId,
            ModuleId: ModuleId,
            AssignmentId: AssignmentId,
            PT: PaperType(PT)
        }
        await axios.post('/Nishant/Qubits/GenerateTestLET', obj).then(function (response) {
            if (response.data != null) {
                window.location.href = "/MyTest/" + CId + "/0/" + ClientId + '/' + response.data[0].PaperSet;
            }
        })
    }

    const GenerateTest = () => {
        axios.get("/Nishant/qubits/get_random_qubit_question?c_id=" + CId + "&no=" + NoOfQuestions + (ModuleId != "0" && ModuleId != "" ? ("&ModuleId=" + ModuleId) : "") ).then(function (response) {
            window.location.href = window.location.origin + "/test/" + CId + "/" + response.data[0].PaperSet;
        })
    }

    const PaperType = (P) => {
        var str = "";
        switch (P) {
            case "0": str = "Pre"; break;
            case "1": str = "Daily"; break;
            case "2": str = "Post"; break;
        }
        return str;
    }

    return (
        <></>
    )
}

export default CreateTest