import React, { useEffect } from 'react'
import Dialog from '@material-ui/core/Dialog';
import ReactDOM from 'react-dom';

const axios = require('axios');

const GatStatsOfQuestion = props => {
    useEffect(() => {
        GetData()
    }, [])

    const GetData = () => {
        axios.get("/Nishant/Qubits/GetQubitsStats?QId=" + props.QId + "&Paperset=" + props.Paperset).then(function (d) {
            if (d.data != null) ReactDOM.render(GetReturnedHTLMStatsData(d.data), document.getElementById('divData'))
            else ReactDOM.render("No Stats Available", document.getElementById('divData'))
        })
    }

    const GetReturnedHTLMStatsData = (data) => {
        return (
            <>
                <div className='row' style={{marginLeft:'0px', marginRight:'0px'}}>
                    <div className='col-6 text-right mb-3 pl-0'><strong>Question Added On:</strong></div>
                    <div className='col-6 mb-3 pl-0 mb-3'><strong>{data[0].CreatedOn}</strong></div>
                    <div className='col-6 text-right pl-0'># Of Attempts:</div>
                    <div className='col-6 mb-3 pl-0'>{data[0].TotalCount}</div>
                    <div className='col-6 text-right pl-0'># Of Correct Attempts:</div>
                    <div className='col-6 mb-3 pl-0'>{data[0].TotalCorrect}</div>
                    <div className='col-6 text-right pl-0'>% Correct Attempts:</div>
                    <div className='col-6 pl-0'>{data[0].Rate + '%'}</div>
                    {data[0].TotalLikes >= 10 ? <>
                        <div className='col-6 text-right pl-0'># of Likes:</div>
                        <div className='col-6 pl-0'>{data[0].TotalLikes}</div>
                    </> : ''}
                </div>
            </>
        )
    }

    const CloseModel = () => {
        ReactDOM.render('', document.getElementById("divModel"));
    }

    return (<>
        <Dialog aria-labelledby="simple-dialog-title" open={true}>
            <div>
                <div style={{ display: 'flex', flex: 1 }}>
                    <div className="row mx-0">
                        <div className="col-12 p-3">
                            <strong>Question Stats</strong>
                            <span className="close-dialog" onClick={() => { CloseModel(); }}><i className="fa fa-close"></i></span>
                        </div>
                        <div className="row mx-0">
                            <div className="col-md-12 px-0">
                                <div id='divData' className="py-4"></div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </Dialog>
    </>
    )
}

export default GatStatsOfQuestion