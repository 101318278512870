import React, { useEffect, useState } from "react";
// import { Button } from 'semantic-ui-react'
import ReactDOM from 'react-dom';
import Countdown, { zeroPad } from "react-countdown";
import SubmitSound from '../src/Check.mp3'
import Question from './Nishant/Reusable/Question';
import LoginForm from './Nishant/Reusable/Login';
import { CalculateTotalMinuteWithText } from './Nishant/Reusable/Utility/CommonUtilityClass';
import CommonHelper from './Nishant/Reusable/Utility/CommonHelper';
import './random_test.css'
import { confirmAlert } from 'react-confirm-alert'; // Import
import 'react-confirm-alert/src/react-confirm-alert.css'; // Import css
import TestInstruction from "./Nishant/Componant/PartialComponant/TestInstruction";
import ResultArea from "./Nishant/Componant/PartialComponant/ResultArea";
import { CopyTimerButtonLog } from './Nishant/Reusable/Utility/CommonUtilityClass';
const axios = require('axios');

function sounds() {
    const audioEl = document.getElementsByClassName("audio-elements")[0]
    audioEl.play();
};

const Random_test = (props) => {
    const [isTrackingInactivity, setIsTrackingInactivity] = useState(false);

    const [c_name, set_c_name] = React.useState("")
    const [left_seconds, set_left_seconds] = React.useState(6000);
    const [cl_id, set_cl_id] = React.useState(1)
    const [TotalQuestion, setTotalQuestion] = React.useState(0)
    const [TotalMCQQuestion, setTotalMCQQuestion] = React.useState(0)
    const [TotalNoOfWordle, setTotalNoOfWordle] = React.useState(0)
    const [RusultShowed, setRusultShowed] = React.useState(false)
    const [StdLoginId,setStdLoginId] = React.useState('')
    const [StdLoginPwd,setStdLoginPwd] = React.useState('')
    const NoOfSecondForEachQuestion = 90;
    const NoOfSecondForWordle = 120;

    // Create a function to handle inactivity
    const handleInactivity = () => {
        //alert("User is inactive for 1 minute.");
        DisplayInactivityPopUp()
    };

    React.useEffect(() => {
        const inactivityTimeout = 1000*60*2; // 1 minute

        // Initialize a timer variable
        let inactivityTimer;

        // Function to reset the timer
        const resetTimer = () => {
            clearTimeout(inactivityTimer); // Clear the previous timer
            if (isTrackingInactivity) {
                inactivityTimer = setTimeout(handleInactivity, inactivityTimeout); // Set a new timer
            }
        };

        // Add event listeners for user activity
        window.addEventListener("mousemove", resetTimer);
        window.addEventListener("keydown", resetTimer);
        window.addEventListener("scroll", resetTimer);

        // Initialize the timer when the component mounts
        resetTimer();
        get_all_questions()

         // Clean up event listeners when the component unmounts
         return () => {
            window.removeEventListener("mousemove", resetTimer);
            window.removeEventListener("keydown", resetTimer);
            window.removeEventListener("scroll", resetTimer);
            clearTimeout(inactivityTimer);
        };
    }, [isTrackingInactivity])
    
    // Function to start inactivity tracking
    const startInactivityTracking = () => {
        //console.log('started')
        setIsTrackingInactivity(true);
        // ... other logic to start the test
    };

    // Function to pause inactivity tracking
    const pauseInactivityTracking = () => {
        //console.log('paused')
        setIsTrackingInactivity(false);
        // ... other logic to pause the test
    };

    function get_qubit_client_id(email_) {
        localStorage.setItem("cl_email", email_)
        axios.get('/Nishant/FGTAPI/get_qubit_client_id?email=' + email_ + '').then(function (response) {
            let client_id = parseInt(response.data.data[0].StdId)
            set_cl_id(client_id)
            localStorage.setItem("cl_id", client_id)
        })
    }

    const get_all_questions = () => {
        let c_id = props.CId
        let paper_set = props.PaperSet
        var obj = {
            c_id: c_id,
            Type: 1,
            clientid: cl_id,
            paperset: paper_set,
            paper_type: ''
        };
        axios.post('/Nishant/Qubits/QubitsNonAttamptedQuestion', obj).then(function (response) {
            if (response.data != null) {
                let questions = response.data
                setTotalQuestion(questions[0].total);
                set_left_seconds(questions[0].total * 60)
                set_c_name(questions[0].CName)
                setTotalNoOfWordle(questions[0].TotalNoOfWordle)
                setTotalMCQQuestion(questions[0].total - questions[0].TotalNoOfWordle)
            }
        })
    }

    const RenderResultArea = () => {
        var notAttemptedQuestion = GetUnAttemptedQuestion()
        if(notAttemptedQuestion.length == 0) RenderResult() 
        else DisplayConfirmPopUp(notAttemptedQuestion)
    }

    const RenderResult = () => {setRusultShowed(true); ReactDOM.render(<ResultArea CId={props.CId} PS={props.PaperSet} ClientId={localStorage.getItem("cl_id")} SendMail={SendMail}></ResultArea>, document.getElementById('divMainContent'))}

    const DisplayConfirmPopUp = (data, IsFromHomebutton) => {
        //GetStudentDetail(localStorage.getItem("cl_id"))
        confirmAlert({
            closeOnClickOutside: false,
            customUI: ({ onClose }) => {
                return (
                    <div className='custom-ui' style={{borderRadius:"15px"}}>
                        <h2>There are still {data.length.toString()} questions left</h2>
                        <h3>Are you sure you want to {IsFromHomebutton ? "Leave" : "submit"} this test?</h3>
                        {/* <h5>We recommend you to please attempt all questions in the test.</h5> */}
                        <button className="btn" style={{width:"auto"}} onClick={() => { onClose();setIsTrackingInactivity(true) }}>Go Back</button>
                        <button className="btn"  style={{borderColor:'#19387e',width:"auto"}} onClick={() => { {IsFromHomebutton ? (window.location.href = "/") : RenderResult() } onClose(); }}>{IsFromHomebutton ? "Leave" : "Submit"}</button>
                    </div>
                );
            }
        });
    }

    const DisplayInactivityPopUp = () => {
        handlePause();
        setIsTrackingInactivity(false);
        confirmAlert({
            closeOnClickOutside: false,
            customUI: ({ onClose }) => {
                return (
                    <div className='custom-ui' style={{borderRadius:"15px"}}>
                        <h2>The test has been paused due to inactivity.</h2>
                        <h3>Would you like to resume this test?</h3>
                        {/* <h5>We recommend you to please attempt all questions in the test.</h5> */}
                        <button className="btn" style={{width:"auto"}} onClick={() => { onClose();handleStart();setIsTrackingInactivity(true); }}>YES</button>
                        <button className="btn" style={{borderColor:'#19387e',width:"auto"}} onClick={() => { { RenderResult() } onClose(); }}>{"Submit"}</button>
                    </div>
                );
            }
        });
    }

    // const GetStudentDetail = () => {
    //     //set_loading(true);
    //     axios.get("/Nishant/Qubits/GetStudentLoginCredential?StdId=" + localStorage.getItem("cl_id")).then(function (response) {
    //         console.log(response.data[0].StdLoginId+' '+response.data[0].StdPwd)
    //         setStdLoginId(response.data[0].StdLoginId)
    //         setStdLoginPwd(response.data[0].StdPwd)
    //         console.log(StdLoginId+' $$ '+StdLoginPwd)
    //     }).catch(function (error) {
    //         alert(error);
    //     });
    // }

    // const GoToLET = async (SID, SPWD) => {
    //     if (SID != '' || SPWD != '') {
    //         let SIDEncryptedValue = await GetEncryptedValue(SID);
    //         let SPWDEncryptedValue = await GetEncryptedValue(SPWD);
    //         if (SIDEncryptedValue != '' && SPWDEncryptedValue != '') {
    //             let link = "https://www.koenig-solutions.com/account/index?id=" + SIDEncryptedValue + "&pwd=" + SPWDEncryptedValue + "&t=1";
    //             window.open(link, "_self");
    //         }
    //     }
    //     else alert("Student credentials are not availabe");
    // }

    // const DisplayConfirmPopUp = (data, IsFromHomebutton) => {
    //     console.log(localStorage.getItem("cl_id"));
    //     GetStudentDetail(localStorage.getItem("cl_id")).then(() => {
    //         console.log(StdLoginId+' - '+StdLoginPwd)
    //         confirmAlert({
    //             closeOnClickOutside: false,
    //             customUI: ({ onClose }) => {
    //                 return (
    //                     <div className='custom-ui'>
    //                         <h2>There are still {data.length.toString()} questions left</h2>
    //                         <h3>Are you sure you want to {IsFromHomebutton ? "Leave" : "submit"} this test?</h3>
    //                         {/* <h5>We recommend you to please attempt all questions in the test.</h5> */}
    //                         <button onClick={() => { onClose(); }}>Go Back</button>
    //                         <button style={{borderColor:'#19387e'}} onClick={() => { {IsFromHomebutton ? (GoToLET(StdLoginId,StdLoginPwd)) : RenderResult() } onClose(); }}>{IsFromHomebutton ? "Leave" : "Submit"}</button>
    //                     </div>
    //                 );
    //             }
    //         });
    //     }).catch(function (error) {
    //         alert(error);
    //     });
    // }
    
    // const GetStudentDetail = (stdid) => {
    //     return new Promise((resolve, reject) => {
    //         //set_loading(true);
    //         axios.get("/Nishant/Qubits/GetStudentLoginCredential?StdId=" + stdid).then(function (response) {
    //             console.log(response.data[0].StdLoginId+' '+response.data[0].StdPwd)
    //             setStdLoginId(response.data[0].StdLoginId);
    //             setStdLoginPwd(response.data[0].StdPwd);
    //             console.log(StdLoginId+' $$ '+StdLoginPwd)
    //             resolve(); // Resolve the promise once the student details are fetched
    //         }).catch(function (error) {
    //             reject(error); // Reject the promise if there's an error
    //         });
    //     });
    // }
    
    // const GoToLET = async (SID, SPWD) => {
    //     if (SID != '' || SPWD != '') {
    //         let SIDEncryptedValue = await GetEncryptedValue(SID);
    //         let SPWDEncryptedValue = await GetEncryptedValue(SPWD);
    //         if (SIDEncryptedValue != '' && SPWDEncryptedValue != '') {
    //             let link = "https://www.koenig-solutions.com/account/index?id=" + SIDEncryptedValue + "&pwd=" + SPWDEncryptedValue + "&t=1";
    //             window.open(link, "_blank");
    //         }
    //     } else {
    //         alert("Student credentials are not available");
    //     }
    // }
    

    const GetEncryptedValue = async (Value) => {
        let V = "";
        await axios.get("/Nishant/Qubits/GETStudedentEncryptedValue?type=0&TextValue=" + Value).then(function (response) {
            V = response.data[0].EValue;
        }).catch(function (error) {
            alert(error);
        });
        return V;
    }

    async function SendMail(uname, to, cc, bcc, subject, html) {
        try {
            var obj = {
                _uname: uname,
                _to: to,
                _cc: cc,
                _bcc: bcc,
                _subject: subject,
                _html: html
            };
            await axios.post("/Nishant/sendemail", obj)
            alert('Result sent to ' + to);
        }
        catch (er) {
            //alert(JSON.stringify(er));
        }
    }

    const Completionist = () => <span style={{ color: '#000', fontSize: 30, display: 'flex', fontFamily: 'Roboto', fontWeight: 'bold', flexFlow: 'column' }}>Time Over</span>;
    const rendererAfterQuestionLoaded = ({ hours, minutes, seconds, completed }) => {
        if (completed) {
            RenderResult() 
            return <Completionist></Completionist>
        } else {
            minutes = minutes + hours * 60;
            //if (minutes == 0 && seconds == 1) { window.location.href = window.location.origin }
            return (
                <span className="mr-2 mt-2" style={{ color: '#000', fontSize: 30, display: 'block', fontFamily: 'Roboto', fontWeight: 'bold', textAlign: 'center' }}>
                    {zeroPad(minutes)}:{zeroPad(seconds)}
                </span>
            );
        }
    };

    const clockRef = React.useRef();
    const handleStart = () => {
        startInactivityTracking();
        clockRef.current.start();
        document.getElementById( 'divQList' ).style.display = 'block';
        ReactDOM.render(<><a href="#" color="teal" className="form-control btn btn-warning" size="small" style={{ width: 'fit-content' }} onClick={() => handlePause()} variant="contained">
            <i className="fa fa-pause"></i>
        </a></>, document.getElementById('SpanCountDownButton'))
        CopyTimerButtonLog(props.PaperSet, localStorage.getItem("cl_id"), 1);
    }
    
    const handlePause = () => {
        pauseInactivityTracking();
        clockRef.current.pause();
        document.getElementById( 'divQList' ).style.display = 'none';
        ReactDOM.render(<><a href="#" color="teal" className="form-control btn btn-success" size="small" style={{ width: 'fit-content' }} onClick={() => handleStart()} variant="contained">
            <i className="fa fa-play"></i>
        </a></>, document.getElementById('SpanCountDownButton'))
        CopyTimerButtonLog(props.PaperSet, localStorage.getItem("cl_id"), 0);
    }

    const QuestionsDetail = (d) => {
        ReactDOM.render(<>
        <span>Questions: <span id='AttemptedCount'>{d[0].total - d.length}</span><input type='hidden' id='hiddenCount' value={d[0].total - d.length}/> / {d[0].total}</span>
        <div style={{display:"flex"}}>
        <Countdown ref={clockRef} date={Date.now() + (CommonHelper.CalculateTotalMinute(d[0].total - TotalNoOfWordle, d[0].TimerCount, TotalNoOfWordle, NoOfSecondForWordle) * 1000)} renderer={rendererAfterQuestionLoaded} /> 
        <div id="SpanCountDownButton" style={{display:"flex"}}>
        
                <a href="#" color="teal" className="form-control btn btn-warning" size="small" style={{ width: 'fit-content' }} onClick={() => handlePause()} variant="contained">
                    <i className="fa fa-pause"></i>
                </a>
            </div>
            </div>
            <a href="#" color="teal" className="ui teal small button  submitQuestionMobile form-control w-auto" size="small" onClick={() => {RenderResultArea();setIsTrackingInactivity(false)}} variant="contained">
        Submit
    </a>
        </>, document.getElementById('divCountDown'))
        ReactDOM.render(<a href="#" color="teal" className="ui teal small button  submitQuestionMobile form-control w-auto" size="small" onClick={() => {RenderResultArea();setIsTrackingInactivity(false)}} variant="contained">
            Submit
        </a>, document.getElementById('divButton'))
    }

    const RenderAllQuestion = () => {
        return (
            <>
                <Question c_id={props.CId} Cl_Id={localStorage.getItem("cl_id")} paperset={props.PaperSet} getDetailFormQuestion={QuestionsDetail}></Question>
            </>
        )
    }
    const GetQuestion = () => {
        debugger;
        if (localStorage.getItem("cordinator_email") == null) {
            ReactDOM.render(<LoginForm IsAnyRedirectionAfterClose={false} SendDataToSuperParent={ReceiveDataFromSuperChild}></LoginForm>, document.getElementById('LoginPopup'));
        }
        else ReactDOM.render(RenderAllQuestion(), document.getElementById('divQList'))
    }

    const RenderInstructionText = (TQ, LQ) => {
        return (
            <>
                <TestInstruction TQ={TQ} TimeText={CalculateTotalMinuteWithText(TotalMCQQuestion, NoOfSecondForEachQuestion, TotalNoOfWordle, NoOfSecondForWordle)} TotalNoOfWordle={TotalNoOfWordle}></TestInstruction>
                <div className="countdown">
                    <span className='mt-2' style={{ float: 'left', fontSize: '30px', fontFamily: 'Roboto', fontWeight: 'bold' }}><div id='QuestionStatus'></div></span>
                    <div id="divCountDown" style={{ display: 'flex', justifyContent: 'space-between' ,alignItems:'center'}}>
                        <label className="m-0">
                            <button class="ui green small button start-test" onClick={(e) => {startInactivityTracking();GetQuestion()}}>Start Test</button>
                        </label>
                    </div>
                    <div id="DivSwitchView"></div>
                </div>
            </>
        )
    }

    const ReceiveDataFromSuperChild = (data) => {
        if (data.length > 0) {
            CommonHelper.CreateLoginTempData(data)
            get_qubit_client_id(localStorage.getItem("cordinator_email"))
            ReactDOM.render('', document.getElementById('LoginPopup'));
            ReactDOM.render(RenderAllQuestion(), document.getElementById('divQList'))
        }
    }

    const GetUnAttemptedQuestion = () => {
        var arr = [], arrAttempted = [];
        // document.getElementsByClassName('print-heading').forEach((o, i) => arr.push(o.getAttribute('title')));
        // document.getElementsByClassName('print-heading-attempted').forEach((o, i) => arrAttempted.push(o.getAttribute('title')));
        Array.from(document.getElementsByClassName('print-heading')).forEach((o, i) => arr.push(o.getAttribute('title')));
        Array.from(document.getElementsByClassName('print-heading-attempted')).forEach((o, i) => arrAttempted.push(o.getAttribute('title')));
        return CommonHelper.CampareTwoArray(arr, arrAttempted)
    }
    
    const RestrictBack = () => {
        if (!RusultShowed) {
            var notAttemptedQuestion = GetUnAttemptedQuestion()
            if (notAttemptedQuestion.length == 0) window.location.href = "/"
            else DisplayConfirmPopUp(notAttemptedQuestion, true)
        }
        else window.location.href = "/";
    }

    return (
        <div>
            <div id="LoginPopup"></div>
            <div className="header top-sticky row">
                <div className='col-md-2 p-2'>
                    <a href='javascript:void(0)' onClick={() => RestrictBack()}>
                        <img src="/qbLT.png" width={110}></img>
                    </a>
                </div>
                <div class="col-md-9 text-center-box">
                    <span style={{ color: 'black', fontSize: '25px' }}><b> {c_name}</b></span>
                </div>
            </div>
            <div id="divMainContent" style={{
                flex: 1,
                display: "flex",
                justifyContent: 'center',
                alignItems: 'center'
            }} onCopy={(e) => CommonHelper.CopyEvent(e)} oncut={(e) => CommonHelper.CopyEvent(e)} onpaste={(e) => CommonHelper.CopyEvent(e)}>
                <audio className="audio-elements">
                    <source src={SubmitSound}></source>
                </audio>
                <div className="container" >
                    {RenderInstructionText(TotalQuestion, left_seconds)}
                    <div style={{
                        flexDirection: 'column',
                        backgroundColor: '#fafafa'
                    }}>
                        <div id="divQList"></div>
                        <div style={{ flex: 1, display: 'flex', fontFamily: 'Roboto', justifyContent: 'center', fontWeight: 'bold', margin: 20 }} id="divButton"></div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Random_test