import React, { useState, useEffect } from 'react';
import ReactDOM from 'react-dom';
import CSDataTable from '../Reusable/CSDataTable';
import { Interweave } from 'interweave';

const axios = require("axios");
const SubjectiveQuestionList = (props) => {
    const [selectedRows, setSelectedRows] = useState([]);
    const Coulumn = [
        {
            text: '',
            key: '',
            sortable: false,
            cell: d => {
                return (
                    <>{
                        d.Selected ? <label></label> :<input
                        type="checkbox"
                        checked={selectedRows.includes(d.QuestionId)}
                        onChange={() => handleRowSelect(d.QuestionId)}
                    />
                    }
                    </>
                );
            }
        },
        { text: 'Test Name', key: 'TestName', sortable: true },
        {
            text: 'Question',
            key: '',
            sortable: true,
            cell: d => {
                return (<Interweave content={d.Question} />);
            }
        },
        { text: 'Test Maker', key: 'TestMAkerName', sortable: true },
        {
            text: 'Add',
            key: '',
            sortable: false,
            cell: d => {
                return (
                    <>
                        {
                            d.Selected ? <label>Added</label> :
                                d.FromMCQ == "1" ? <div id={'ListQId_' + d.QuestionId}><a onClick={() => AddMCQQuestionToTest(d.QuestionId)} className="badge btn btn-outline-primary text-primary">Add</a></div>
                                    : <div id={'ListQId_' + d.QuestionId}><a onClick={() => AddQuestionToTest(d.QuestionId)} className="badge btn btn-outline-primary text-primary">Add</a></div>
                        }
                    </>
                );
            }
        },
    ]
    const [Rows] = useState(props.Data);

    const GetDataTable = () => {
        if (Rows.length > 0) return <CSDataTable Rows={Rows} Columns={Coulumn} PageSize={10} Length_menu={[10, 20, 50, 100]}></CSDataTable>
        else return <div className="text-center"><label className="alert-danger alert">No Question added</label></div>
    }

    const handleRowSelect = (QuestionId) => {
        const isSelected = selectedRows.includes(QuestionId);
        let updatedSelection = [];

        if (isSelected) {
            updatedSelection = selectedRows.filter(id => id !== QuestionId);
        } else {
            updatedSelection = [...selectedRows, QuestionId];
        }

        setSelectedRows(updatedSelection);
        props.onSelectedRowsChange(updatedSelection);
    }

    const AddQuestionToTest = async (QId) => {
        if (props.TID != 0) {
            var obj = {
                Type: 16,
                TestId: props.TID,
                QId: QId
            };
            axios.post('/Nishant/Qubits/AddQuestionFromList', obj).then(function (response) {
                if (response.data != null) {
                    ReactDOM.render(<label>Added</label>, document.getElementById('ListQId_' + response.data[0].QId))
                }
            })
        }
        else alert('Please Create test first');
    }

    const AddMCQQuestionToTest = (QId) => {
        if (props.TID != 0) {
            var obj = {
                Type: 21,
                QId: QId
            };
            axios.post("/Nishant/Qubits/GetMCQQuestionDetail", obj).then(function (response) {
                console.log(response.data)
                if (response.data != null) {
                    AddMCQ(response.data[0].Question, response.data[0].QuesId,response.data[0].Ans1,response.data[0].Ans2,response.data[0].Ans3,response.data[0].Ans4,response.data[0].Tag1,response.data[0].Tag2,response.data[0].Tag3,response.data[0].Tag4)
                    console.log(QId,response.data[0].Question, response.data[0].QuesId,response.data[0].Question,response.data[0].Ans1,response.data[0].Ans2,response.data[0].Ans3,response.data[0].Ans4,response.data[0].Tag1,response.data[0].Tag2,response.data[0].Tag3,response.data[0].Tag4)
                }
            })

        }
        else alert('Please Create test first');
    }

    const AddMCQ = (Question, QId,ans1,ans2,ans3,ans4,tag1,tag2,tag3,tag4) => {
        if (Question != "") {
            var obj = {
                Question: Question,
                trainerID: props.UserId,
                TestId: props.TID,
                IsMCQ:1,
                OptionATag:tag1,
                OptionBTag:tag2,
                OptionCTag:tag3,
                OptionDTag:tag4,
                OptionA:ans1,
                OptionB:ans2,
                OptionC:ans3,
                OptionD:ans4
            };
            axios.post("/Nishant/Qubits/AddQuestion", obj).then(function (response) {
                if (response.data != null) {
                    ReactDOM.render(<label>Added</label>, document.getElementById('ListQId_' + QId))
                }
            })
        }
    }

    return (
        <div className="pl-2 pr-2">
            {GetDataTable()}
        </div>
    )
}

export default SubjectiveQuestionList
