import React from 'react'
import 'devextreme/dist/css/dx.light.css';
import { HtmlEditor } from 'devextreme-react/html-editor';
import {
  // ...
  Toolbar,
  Item,
} from "devextreme-react/html-editor";

const sizeValues = ["8pt", "10pt", "12pt", "14pt", "18pt", "24pt", "36pt"];
const fontValues = ["Arial", "Georgia", "Tahoma", "Times New Roman", "Verdana"];
const headerValues = [false, 1, 2, 3, 4, 5];
const htmlEditorAttributes = {
  id: 'txtEditor',
  class: 'text-left'
}

const HtmlEditorBox = props => {
  return (
    <HtmlEditor onValueChanged={(e) => props.SetValue(e.value)} 
      value={props.Value}
      multiline={true}
      placeholder={props.placeholder}
      elementAttr={htmlEditorAttributes}
    >
      <Toolbar multiline={true}>
        <Item name="undo" />
        <Item name="redo" />
        <Item name="separator" />
        <Item name="size" acceptedValues={sizeValues} />
        <Item name="font" acceptedValues={fontValues} />
        <Item name="separator" />
        <Item name="bold" />
        <Item name="italic" />
        <Item name="strike" />
        <Item name="underline" />
        <Item name="separator" />
        <Item name="alignLeft" />
        <Item name="alignCenter" />
        <Item name="alignRight" />
        <Item name="alignJustify" />
        <Item name="separator" />
        <Item name="orderedList" />
        <Item name="bulletList" />
        <Item name="separator" />
        <Item name="header" acceptedValues={headerValues} />
        <Item name="separator" />
        <Item name="color" />
        <Item name="background" />
        <Item name="separator" />
        <Item name="link" />
        <Item name="image" />
        <Item name="separator" />
        <Item name="clear" />
        <Item name="codeBlock" />
        <Item name="blockquote" />
        <Item name="separator" />
        <Item name="insertTable" />
        <Item name="deleteTable" />
        <Item name="insertRowAbove" />
        <Item name="insertRowBelow" />
        <Item name="deleteRow" />
        <Item name="insertColumnLeft" />
        <Item name="insertColumnRight" />
        <Item name="deleteColumn" />
      </Toolbar>
    </HtmlEditor>
  )
}

export default HtmlEditorBox