import React from "react";
import { BrowserRouter, Switch, Route } from "react-router-dom";
import stdDashboard from "./stdDashBoard";
import My_tes from "./Nishant/HandlePrevRouting/HandleDailyTest";
import Homepage from "./Homepage";
import Random_test from "./Random_test";
import TestRnd from "./TestRnd";
import HandleRandomTestURL from "./Nishant/Componant/HandleRandomTestURL";
import LetDirect from "./LetDirect";
import ITest from "./My_tes";
import Contact_Us from "./Contact_Us";
import about from "./about";
import feedback from "./feedback";
import SubTrainerTest from "./Nishant/Componant/TrainerSubjectiveTest";
import QubitsLiveSub from "./Nishant/HandlePrevRouting/QubitsLiveTestMakerRouting";
import LiveTest from "./Nishant/Componant/QubitsliveS";
import QubitsLive from "./Nishant/Componant/QubitsLive";
import DMCACopyright from "./Nishant/Componant/DMCACopyright";
import ModuleWise from "./ModuleWise";
//import SF from "./Nishant/Reusable/Utility/SocketFile";
import CADExam from "./Nishant/Componant/CADExam";
import CDMain from "./Nishant/Componant/CADRedirect";
import CreateTest from "./Nishant/Componant/CreateTest";
import {ManagementTeam}  from "./ManagementTeam";

//import Homepage from "./Homepage.js";

export default function Routes() {
  return (
    <BrowserRouter>
      <Switch>
        <Route path="/" exact component={Homepage} />
        <Route path="/Random_test" exact component={Random_test} />
        <Route path="/TestRnd" exact component={TestRnd} />
        <Route path="/contact_us" exact component={Contact_Us} />
        <Route path="/about-us" exact component={about} />
        <Route path="/feedback" exact component={feedback} />
        <Route path="/ModuleWise" exact component={ModuleWise}/>

        <Route path="/test/:CId/:PaperSet" exact component={HandleRandomTestURL} />
        <Route path="/test/:CId" exact component={HandleRandomTestURL} />
        <Route path="/MyTest/:CId/:TrainerId/:ClientId/:PS" exact component={ITest} />
        <Route path="/CreateTest/:CId/:ClientId/:ModuleId/:AssignmentId/:PT" exact component={CreateTest} />
        <Route path="/CreateTest/:CId/:NoOfQuestions/:ModuleId" exact component={CreateTest} />
        <Route path="/student" exact component={stdDashboard} />
        <Route path="/ModuleWise" exact component={ModuleWise} />

        <Route path="/My_tes" exact component={My_tes} />
        <Route path="/live/:TestId" exact component ={SubTrainerTest}/>
        <Route path="/QubitsLiveSub" exact component ={QubitsLiveSub}/>
        <Route path="/LiveTest/:UserId" exact component ={QubitsLive}/>
        <Route path="/LiveTest" exact component ={LiveTest}/>
        <Route path="/LiveTest/:UserId/:QLId" exact component ={LiveTest}/>
        <Route path="/DMCA-Copyright" exact component ={DMCACopyright}/>
        {/* <Route path="/SF" exact component ={SF}/> */}
        <Route path="/CD/:VId/:PaperSet" exact component ={CADExam}/>
        <Route path="/LetDirect" exact component ={LetDirect}/>
        <Route path="/CDRedirect/:VId" exact component ={CDMain}/>
        <Route path="/management" exact component={ManagementTeam} />
      </Switch>
    </BrowserRouter>
  );
}
