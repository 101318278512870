import React from 'react'
import { useParams } from 'react-router-dom';

const QubitsLive = () => {
    const { UserId } = useParams();
    const { QLId } = useParams();

    React.useEffect(() => {
        GetPrevTestStatus();
    }, []);

    const GetPrevTestStatus = () => {
        let Id = typeof QLId === 'undefined' ? 0 : QLId;
        localStorage.removeItem("QLTrainerUserId")
        localStorage.removeItem("QLQubitsLiveId")
        localStorage.setItem("QLTrainerUserId", UserId)
        localStorage.setItem("QLQubitsLiveId", Id)
        window.location.href = "/LiveTest";
    }

    return (
        <div>
            <div id='divMain'></div>
        </div>
    )
}

export default QubitsLive