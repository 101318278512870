import React from 'react'
import OTPInput from '../../../reusable/OTPInput'
import ReactDOM from 'react-dom';
import TextField from '@material-ui/core/TextField';

const axios = require('axios');

const CADWordleComponant = props => {
    let c_id = props.c_id
    let paperset = props.paperset
    
    const SaveWordly = (QId) => {
        var Answer = document.getElementById('hdWordlyQId' + QId).value
        if (Answer != "") {
            let cl_id = props.Cl_Id
            var obj = {
                Type: 3,
                stdid: cl_id,
                quesid: QId,
                Answer: Answer,
                PaperSet: paperset
            };
            axios.post("/Nishant/Qubits/CADQubits", obj).then(function (r) {
                if (r.data != null) {
                    // if (r.data[0].IsCorrect == 1) {
                    //     ReactDOM.render(<span style={{ color: 'green' }}>Correct Answer</span>, document.getElementById('divWordlyQId' + QId))
                    //     props.GetQuestion()
                    // }
                    document.getElementById('divHeader_' + QId).classList.add("print-heading-attempted");
                    //props.CheckStatus(true)
                }
            })
        }
        else window.location.href = "https://www.koenig-solutions.com/course-advice-desk?rid=" + paperset + "&vid=" + props.VId;;
    }

    const Hint = (QId) => {
        axios.get("/Nishant/Qubits/CounterChange?Type=9&QId=" + QId).then(function (r) {
            ReactDOM.render(<OTPInput noOfBoxes={r.data[0].LenthOfAnswer} QId ={QId} value='' GetAnswer={SetAnswerWalue} />, document.getElementById('wordleTextChange' + QId))
            ReactDOM.render(<a className="btn-outline-primary btn text-primary py-2 px-4 ml-2" onClick={(e) => Hint1(QId, r.data[0].LenthOfAnswer)}>Get Another Hint</a>, document.getElementById('spHintCounter' + QId))
            ReactDOM.render(<span>show <span id={'spHintCounterDisplay' + QId}>1</span> letter of the Answer.</span>, document.getElementById('spHintText' + QId))
        })
    }

    const SetAnswerWalue = (d) => SetHiddenValue(d.OTP, d.QId)

    const SetHiddenValue = (value, QId) => document.getElementById('hdWordlyQId' + QId).value = value

    const Hint1 = (QId, MaxLength) => {
        let cnt = document.getElementById('spHintCounterDisplay' + QId).innerText;
        cnt = parseInt(cnt) + 1;
        var obj = {
            Type : 10,
            QId: QId,
            ClientId: props.Cl_Id,
            paperSet: paperset,
        };
        axios.post("/Nishant/Qubits/GetHint", obj).then(function (r) {
            if (r.data != null) {
                var value = r.data[0].Hint.replace(/_/g, ' ')
                ReactDOM.render(<OTPInput noOfBoxes={MaxLength} QId={QId} value={value} GetAnswer={SetAnswerWalue} />, document.getElementById('wordleTextChange' + QId))
                if (r.data[0].CanShowMoreInt == 0) {
                    ReactDOM.render('', document.getElementById('spHintCounter' + QId));
                    ReactDOM.render('', document.getElementById('spHintText' + QId))
                }
                else document.getElementById('spHintCounterDisplay' + QId).innerText = cnt
            }
        })
    }

    return (
        <>
            <div className="print-questions">
                <span className='d-inline' style={{ fontStyle: "italic", color: 'green' }}>Wordle! </span><span className='d-inline'>{props.obj.Question}</span>
            </div>
            <div className="ans-options" id={"AnswerStatus_" + props.obj.QuesId}>
                <div className='wordle-outer mt-3 mb-3 mx-0'>
                    <div className='wordle-inputs' id={'wordleTextChange' + props.obj.QuesId}>
                        <TextField className="form-control" id={'txtWordlyQId' + props.obj.QuesId} onChange={(e) => SetHiddenValue(e.target.value, props.obj.QuesId)} size="small" label="Type your one word answer here" variant="outlined"></TextField>
                        {/* <OTPInput noOfBoxes={props.obj.LenthOfAnswer} QId ={ props.obj.QuesId} value='' GetAnswer={SetAnswerWalue} /> */}
                    </div>
                    <input type='hidden' id={'hdWordlyQId' + props.obj.QuesId}></input>
                    <div className='wordle-actions' id={'divAnswerStatus_' + props.obj.QuesId}>
                        <a className="btn-outline-primary btn text-primary py-2 px-4" onClick={(e) => SaveWordly(props.obj.QuesId)}>Done</a>
                        {/* <span id={'spHintCounter' + props.obj.QuesId}><a className="btn-outline-primary btn text-primary py-2 px-4 ml-2" onClick={(e) => Hint(props.obj.QuesId)}>Hint Required</a></span> */}
                        {/* <span className={"text-info badge px-3"} id={'spHintText' + props.obj.QuesId}>You will get # of character of the Answer</span> */}
                    </div>
                </div>
            </div>
        </>
    )
}

export default CADWordleComponant