import React from 'react'
import Dialog from '@material-ui/core/Dialog';
import { Button } from 'semantic-ui-react'
import TextField from '@material-ui/core/TextField';
import ReactDOM from 'react-dom';

const SubmitQuestionFeedback = props => {
    //const [FeedBackFor, setFeedBackFor] = React.useState(0);
    const [ShareQuestionlabelFeedbackText, SetShareQuestionlabelFeedbackText] = React.useState('')
    const [ShareQuestionTextFeedback, SetShareQuestionTextFeedback] = React.useState('')
    const [Email, setEmail] = React.useState('')
    const [Name, setName] = React.useState('')
    const [ErrorTextName, setErrorTextName] = React.useState('')
    const [ErrorTextEmail, setErrorTextEmail] = React.useState('')

    const ReceiveDataFromChild = () => {
        localStorage.setItem("TempName", Name)
        localStorage.setItem("TempEmail", Email)
        var obj = {
            QId: props.QId,
            FB: ShareQuestionTextFeedback,
            SenderName: Name,
            SenderEmail: Email
        };
        props.SendDataToSuperParent(obj);
    }
    
    const CloseModel = () => {
        ReactDOM.render('', document.getElementById("divModel"));
    }

    const SubmitQuetionFeedback = () => {
        if (ShareQuestionTextFeedback == '') {
            SetShareQuestionlabelFeedbackText('Please Enter Some Text');
        }
        else if (localStorage.getItem("TempEmail") == null || localStorage.getItem("TempEmail") == '') {
            if (localStorage.getItem("cordinator_email") == null) {
                if (Name == '') setErrorTextName("Please enter sender name")
                else if (Email == '') setErrorTextEmail("Please enter sender email")
                else if (!validateEmail(Email)) setErrorTextEmail("Please enter valid email")
                else {
                    ReceiveDataFromChild();
                    SetShareQuestionTextFeedback('');
                }
            }
            else if (localStorage.getItem("cordinator_email").toString().includes("qubitusernotreg@qubit.com")){
                if (Name == '') setErrorTextName("Please enter sender name")
                else if (Email == '') setErrorTextEmail("Please enter sender email")
                else if (!validateEmail(Email)) setErrorTextEmail("Please enter valid email")
                else {
                    ReceiveDataFromChild();
                    SetShareQuestionTextFeedback('');
                }
            }
            else {
                ReceiveDataFromChild();
                SetShareQuestionTextFeedback('');
            }
        }
        else {
            ReceiveDataFromChild();
            SetShareQuestionTextFeedback('');
        }
    }

    const validateEmail = (email) => {
        const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        return re.test(String(email).toLowerCase());
    }
    
    return (
        <>
            <Dialog aria-labelledby="simple-dialog-title" open={true}>
                <div>
                    <div style={{ display: 'flex', flex: 1 }}>
                        <div className="row mx-0">
                            <div className="col-12 p-3">
                                <strong>Please Share your Feedback</strong>
                                <span className="close-dialog" onClick={() => { CloseModel(); SetShareQuestionTextFeedback(''); }}><i className="fa fa-close"></i></span>
                            </div>
                            {
                                localStorage.getItem("TempEmail") == null || localStorage.getItem("TempEmail") == '' ?
                                    localStorage.getItem("cordinator_email") == null ?
                                        <div className="col-md-12 mb-3">
                                            <TextField className="form-control" size="small" value={Name}
                                                onChange={(e) => {
                                                    setName(e.target.value); setErrorTextName('');
                                                }}
                                                label="Enter Your Name" variant="outlined" />
                                            <label className="mt-2" style={{ 'color': 'red' }}>{ErrorTextName}</label>
                                            <div className="mt-3">
                                                <TextField className="form-control" size="small" value={Email}
                                                    onChange={(e) => {
                                                        setEmail(e.target.value); setErrorTextEmail('')
                                                    }}
                                                    label="Enter Your Email" variant="outlined" />
                                                <label className="mt-2" style={{ 'color': 'red' }}>{ErrorTextEmail}</label>
                                            </div></div>
                                        : localStorage.getItem("cordinator_email").toString().includes("qubitusernotreg@qubit.com") ?
                                            <div className="col-md-12 mb-3">
                                                <TextField className="form-control" size="small" value={Name}
                                                    onChange={(e) => {
                                                        setName(e.target.value); setErrorTextName('');
                                                    }}
                                                    label="Enter Your Name" variant="outlined" />
                                                <label className="mt-2" style={{ 'color': 'red' }}>{ErrorTextName}</label>
                                                <div className="mt-3">
                                                    <TextField className="form-control" size="small" value={Email}
                                                        onChange={(e) => {
                                                            setEmail(e.target.value); setErrorTextEmail('')
                                                        }}
                                                        label="Enter Your Email" variant="outlined" />
                                                    <label className="mt-2" style={{ 'color': 'red' }}>{ErrorTextEmail}</label>
                                                </div></div>
                                            : ""
                                    : ""
                            }
                            <div className="col-md-12">
                                <textarea rows="3" placeholder="Provide your feedback…" className="form-control" onChange={(e) => { SetShareQuestionTextFeedback(e.target.value); SetShareQuestionlabelFeedbackText('') }}></textarea>
                                <label style={{ 'color': 'red' }}>{ShareQuestionlabelFeedbackText}</label>
                            </div>
                            <div className="col-md-12 my-3">
                                <Button color='blue' onClick={() => { SubmitQuetionFeedback() }} className="form-control">
                                    Submit
                                </Button>
                            </div>
                        </div>
                    </div>
                </div>
            </Dialog>
        </>
    )
}

export default SubmitQuestionFeedback
