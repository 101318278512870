import React, { useEffect } from 'react'
import LoginForm from './Nishant/Reusable/Login';
import ReactDOM from "react-dom";
import CommonHelper from "./Nishant/Reusable/Utility/CommonHelper";

const axios = require('axios');
const Navbar = props => {
  
  const Login = () => {
    if (localStorage.getItem("cl_id") > 0) {
      window.location.href = window.location.origin + "/student";
    } else {
      ReactDOM.render(<LoginForm IsAnyRedirectionAfterClose={false} SendDataToSuperParent={ReceiveDataFromSuperChild}></LoginForm>, document.getElementById('LoginPopup'));
    }
  }

  const ReceiveDataFromSuperChild = (res, isRedirect = true) => {
    if (res.length > 0) {
      CommonHelper.CreateLoginTempData(res)
      if (isRedirect) window.location.href = window.location.origin + '/student'
      localStorage.setItem("user_name", res[0].Name)
    }
  }
  
  const GenerateLink = async (ParentLink, SID) => {
    if (SID != '') {
      let SIDEncryptedValue = await GetEncryptedValue(SID);
      if (SIDEncryptedValue != '') {
        let link = ParentLink + SIDEncryptedValue;
        window.open(link, "_blank");
      }
    }
    else alert("Not able to Login");
  }

  const GetEncryptedValue = async (Value) => {
    let V = "";
    await axios.get("Nishant/GETStudedentEncryptedValue?TextValue=" + Value).then(function (response) {
      V = response.data[0].EValue;
    }).catch(function (error) {
      alert(error);
    });
    return V;
  }

  return (
    <div>
      <div id="LoginPopup"></div>
      <div className="rightHeaderDesktop">
        <div className='col-md-3 p-2'>
          <a href='/'>
            <img src="qbLT.png" width={120}></img>
          </a>
        </div>
        <div className='col-md-9 right-nav'>
          <span
            onClick={() => {
              window.location.href = window.location.origin + "/about-us";
            }}
            className="dashButtonDesktop"
          >
            About Us
          </span>
          <span
            onClick={() => {
              window.location.href = window.location.origin + "/contact_us";
            }}
            className="dashButtonDesktop"
          >
            Contact Us
          </span>
          <span
            onClick={() => {
              window.location.href = window.location.origin + '/management';
            }}
            className="dashButtonDesktop"
          > Management Team
          </span>
          <span
            style={{
              display:
                (localStorage.getItem("user_name") !== null)
                  ? "none"
                  : "flex",
            }}
            onClick={() => {
              Login()
            }}
            className="dashButtonDesktop"
          >
            Login
          </span>
          <span className="dashButtonDesktop"
            style={{
              display:
                (localStorage.getItem("user_name") !== null)
                  ? "flex"
                  : "none",
            }}
            onClick={() => {
              window.location.href = window.location.origin + '/student';
            }}
          >
            {"Welcome " + (localStorage.getItem("user_name") == null ? "Guest" : localStorage.getItem("user_name"))}
          </span>

          <span style={{
            display:
              (localStorage.getItem("user_name") !== null)
                ? ((localStorage.getItem("cordinator_email") !== null ? ((localStorage.getItem("cordinator_email").toString().includes("qubitusernotreg@qubit.com")) ? "none" : "flex") : ''))
                : "none",
          }}
            className="dashButtonDesktop dropdown"
          >
            {" "}

            <i className="fa fa-user-circle-o size12"></i>
            <div className="dropdown-menu p-0">
              <div className="set"
                onClick={() => {
                  localStorage.clear();
                  window.location.href = window.location.href;
                }}
              >

                Logout
                {" "}

              </div>
              <div className="set-outer">
                <div>
                  {" "}
                  <div
                    style={{
                      display:
                        (localStorage.getItem("cordinator_id") !== null && localStorage.getItem("cordinator_type") !== 'student')
                          ? "block"
                          : "none",
                    }}
                  >
                    <div onClick={() => GenerateLink('https://rms.koenig-solutions.com/new-rms/ILo_RecordingDetails.aspx?EmpId=', localStorage.getItem("cordinator_id"))} className="text-primary set">Request ILO Recording</div>

                    <div onClick={() => GenerateLink('https://rms.koenig-solutions.com/Forms/TrainerPanel/TrainerAssignments.aspx?EmpId=', localStorage.getItem("cordinator_id"))} className="text-primary set">Request TBT</div>
                  </div>
                </div>
              </div>
            </div>


          </span>
        </div>
      </div>
    </div>
  )
}

export default Navbar