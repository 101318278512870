import React from 'react'

const QubitsLiveTestMakerRouting = () => {
    const queryString = require('query-string');
    const url_param = queryString.parse(window.location.search)
    React.useEffect(() => {
        window.location.href = '/LiveTest/' + url_param.id
    }, [])

    return (
        <></>
    )
}

export default QubitsLiveTestMakerRouting