import React, { useEffect } from 'react';
import ReactDOM from 'react-dom';
import DialogTitle from '@material-ui/core/DialogTitle';
import { Alert } from '@material-ui/lab';
import TextField from '@material-ui/core/TextField';
import CircularProgress from '@material-ui/core/CircularProgress';
import { Icon, Button } from 'semantic-ui-react'
import LoginScreen from './LoginScreen';

const axios = require('axios');

const CreateNewAccount = (props) => {
    const [login_text, set_login] = React.useState('login');
    const [email, set_email] = React.useState('');
    const [pwd, set_pwd] = React.useState('');
    const [pwd1, set_pwd1] = React.useState('');
    const [Status, setStatus] = React.useState(false);
    const [ErrorMsg, setErrorMsg] = React.useState('');

    const send_new_user_verification_link = () => {
        axios.get('api/Student/qubit_Create_User?email=' + email + '&pwd=' + pwd + '').then(function (response) {
            alert("Please check you e-mail for verification link");
            window.location.href = window.location.origin + '/'
        })
    }

    const Createlogin = () => {
        if (email == "") {
            SendErrorMessage('Please enter a valid user name', true)
        }
        else {
            var mailformat = /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;
            if (email.match(mailformat)) {
                axios.get('/Nishant/Qubits/qubit_EmailExist?email=' + email + '').then(function (response) {
                    let res = response.data
                    if (res[0].res == '1') {
                        SendErrorMessage('Email already exist. Please use another', true)
                    }
                    else {
                        if (pwd == "") {
                            SendErrorMessage('Please enter a valid password', true)
                        }
                        else if (pwd != pwd1) {
                            SendErrorMessage('Password and Retype password is not same', true)
                        }
                        else {
                            send_new_user_verification_link()
                        }
                    }
                })
            }
            else {
                SendErrorMessage('Please add valid email.', true)
            }
        }
    }

    const SendErrorMessage = (EMsg, Flag) => {
        setErrorMsg(EMsg)
        setStatus(Flag)
    }

    const ReturnToLogin = () => {
        ReactDOM.render(<LoginScreen></LoginScreen>, document.getElementById('MainContent'))
    }

    return (
        <div>
            <Alert style={{ display: Status ? 'flex' : 'none' }} severity="error">{ErrorMsg}</Alert>
            <Icon onClick={() => { window.location.href = window.location.origin }} style={{ margin: 10 }} color="orange" name='close' size='large' />
            <DialogTitle id="simple-dialog-title" style={{ flexDirection: 'row' }}>
                <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'center' }}>
                    <Icon onClick={() => {
                        ReturnToLogin()
                    }} color="teal" size="large" style={{ cursor: 'pointer', marginTop: 22 }} name='arrow left' />
                    <h3 style={{ fontWeight: "bolder" }}>Create New Account</h3>
                </div>
            </DialogTitle>
            <div style={{ margin: 30, flexDirection: 'column', display: 'flex', alignItems: 'center' }}>
                <TextField
                    style={{ width: 250 }}
                    onChange={(event) => {
                        set_email(event.target.value)
                    }}
                    id="outlined-basic-Email"
                    label="Email"
                    variant="outlined" />
                <br></br>
                <br></br>
                <TextField
                    style={{ width: 250 }}
                    onChange={(event) => {
                        set_pwd(event.target.value)
                    }}
                    type='password'
                    id="outlined-basic-password"
                    label="Password"
                    variant="outlined" />
                <br></br>
                <br></br>
                <TextField
                    style={{ width: 250 }}
                    onChange={(event) => {
                        set_pwd1(event.target.value)
                    }}
                    type='password'
                    id="outlined-basic-confirm-password"
                    label="Re-Type Password"
                    variant="outlined" />
                <br></br>
                <br></br>
                <Button style={{
                    backgroundColor: "#21b6ae", fontSize: "14px",
                    color: '#fff', flexDirection: 'row'
                }} onClick={() => { Createlogin() }} variant="contained" color="primary">
                    Save
                    <CircularProgress style={{ display: (login_text == 'login') ? 'none' : '', height: 20, width: 20, marginleft: 10, color: '#fff' }} />
                </Button>
            </div>
        </div>
    )
}

export default CreateNewAccount
