import React from 'react'
import { useParams } from 'react-router-dom';
import ReactDOM from 'react-dom';
import CircularProgress from '@material-ui/core/CircularProgress';
import Checkbox from '@material-ui/core/Checkbox';
import CADWordleComponant from '../Componant/PartialComponant/CADWordleComponant';
import CommonHelper from '../Reusable/Utility/CommonHelper';
import { confirmAlert } from 'react-confirm-alert'; // Import
import 'react-confirm-alert/src/react-confirm-alert.css'; // Import css

const axios = require('axios');

const CADExam = () => {
    const { VId } = useParams();
    const { PaperSet } = useParams();
    const [MainLoader, SetMainLoader] = React.useState(true);
    const [CName, SetCName] = React.useState('');
    const [NextCName, SetNextCName] = React.useState('');
    const [NextCId, SetNextCId] = React.useState('');

    React.useEffect(() => {
        GetQuestion()
    }, [])

    const GetUnAttemptedQuestion = () => {
        var arr = [], arrAttempted = [];
        document.getElementsByClassName('print-heading').forEach((o, i) => arr.push(o.getAttribute('title')));
        document.getElementsByClassName('print-heading-attempted').forEach((o, i) => arrAttempted.push(o.getAttribute('title')));
        return CommonHelper.CampareTwoArray(arr, arrAttempted)
    }

    const GetQuestion = async () => {
        var obj = {
            Type: 1,
            VId: VId,
            PaperSet: PaperSet
        };
        await axios.post('/Nishant/Qubits/CADQubits', obj).then(function (response) {
            if (response.data.length > 0) {
                SetMainLoader(false)
                ReactDOM.render(RenderAllQuestion(response.data), document.getElementById('divQList'))
                GetCourseStatus()
            }
            else {
                ReactDOM.render(<></>, document.getElementById('divCourse'))
                ReactDOM.render(<span>
                    <div className='text-center'><h1>Link has been expired.</h1></div>
                    <div className='text-center'><h3>Please visit below link.</h3></div>
                    <br></br>
                    <a className='text-center' href={'https://www.koenig-solutions.com/course-advice-desk?vid=' + VId}>https://www.koenig-solutions.com</a>
                </span>, document.getElementById('divButton'))
            }
        })
    }

    const GetCourseStatus = () => {
        var obj = {
            Type: 6,
            VId: VId,
            PaperSet: PaperSet
        };
        axios.post('/Nishant/Qubits/CADQubits', obj).then(function (response) {
            if (response.data.length > 0) {
                response.data.map((d, i) => {
                    if (d.CurrentlyAttempting == '1') {
                        SetCName(d.CName)
                        SetNextCName(typeof response.data[i + 1] != 'undefined' ? response.data[i + 1].CName  : "")
                        SetNextCId(typeof response.data[i + 1] != 'undefined' ? response.data[i + 1].CourseId  : "")
                    }
                })
            }
        })
    }

    const UpdateNextTest = async () => {
        var obj = {
            Type: 1,
            VId: VId,
            CId: NextCId,
            PaperSet: PaperSet
        };
        await axios.post('/Nishant/Qubits/CADQubits', obj).then(function (response) {
            if (response.data.length > 0) {
                SetMainLoader(false)
                ReactDOM.render(RenderAllQuestion(response.data), document.getElementById('divQList'))
                GetCourseStatus()
            }
            else {
                ReactDOM.render(<></>, document.getElementById('divCourse'))
                ReactDOM.render(<span>
                    <div className='text-center'><h1>Link has been expired.</h1></div>
                    <div className='text-center'><h3>Please visit below link.</h3></div>
                    <br></br>
                    <a className='text-center' href={'https://www.koenig-solutions.com/course-advice-desk?vid=' + VId}>https://www.koenig-solutions.com</a>
                </span>, document.getElementById('divButton'))
            }
        })
    }

    const GetQuestionRating = (Id) => {
        switch (Id) {
            case 1: return "Basic Level Question";
            case 2: return "Intermediate Level Question";
            case 3: return "Advance Level Question";
            default: return "";
        }
    }

    const GetRatingStar = (NoOfRating) => {
        var Content = []
        for (let i = 0; i < NoOfRating; i++) {
            Content.push(<i className="fa fa-star" aria-hidden="true" style={{ color: ' yellow' }}></i>)
        }
        return Content;
    }

    const SubmitAnswerMultiple = (qid) => {
        let a_1 = document.getElementById("Chk1_" + qid).checked;
        let a_2 = document.getElementById("Chk2_" + qid).checked;
        let a_3 = document.getElementById("Chk3_" + qid).checked;
        let a_4 = document.getElementById("Chk4_" + qid).checked;
        SubmitAnswer(qid, a_1, a_2, a_3, a_4)
    }

    const SubmitAnswer = (qid, a_1, a_2, a_3, a_4) => {
        let cl_id = 0
        var obj = {
            Type: 2,
            stdid: cl_id,
            quesid: qid,
            a1: (a_1 ? 1 : 0),
            a2: (a_2 ? 1 : 0),
            a3: (a_3 ? 1 : 0),
            a4: (a_4 ? 1 : 0),
            PaperSet: PaperSet
        };
        axios.post("/Nishant/Qubits/CADQubits", obj).then(function (response) {
            document.getElementById('divHeader_' + qid).classList.add("print-heading-attempted"); //Please don't remove Class Name. Title is using for Summary at the time of Submit
        })
    }

    const CheckStatus = () => {
        var notAttemptedQuestion = GetUnAttemptedQuestion()
        if(notAttemptedQuestion.length == 0) ShowResultData()
        else DisplayConfirmPopUp(notAttemptedQuestion)
    }

    const ShowResultData = (IsFromWordle = false) => {
        var obj = {
            Type: 5,
            PaperSet: PaperSet
        };
        axios.post("/Nishant/Qubits/CADQubits", obj).then(function (response) {
            if (response.data != null) {
                if (!IsFromWordle) {
                    response.data.map((d) => {
                        if (d.IsAnswerCorrect == 1) ReactDOM.render(<span className='text-success'>Correct Answer</span>, document.getElementById('divAnswerStatus_' + d.QuesId))
                        else ReactDOM.render(<span className='text-danger'>InCorrect Answer</span>, document.getElementById('divAnswerStatus_' + d.QuesId))
                    })
                    let result = response.data.find((i) => i.IsAnswerCorrect == 0); //console.log(result)
                    if (typeof result == 'undefined') GetQuestion()
                    else {
                        ReactDOM.render(<>
                            <div className='text-center'><h1>Course Suggested: {response.data[0].SuggestedCourse == "" ? "No Course available" : response.data[0].SuggestedCourse}</h1></div>
                            <br></br>
                            <a className='text-center btn-outline-primary btn btn-sm text-primary py-2 px-4' href={"https://www.koenig-solutions.com/course-advice-desk?rid=" + PaperSet + "&vid=" + VId}>Go Back</a>
                        </>, document.getElementById('divSubmitButton'))
                    }
                }
            }
        })
    }

    const DisplayConfirmPopUp = (data, IsFromHomebutton) => {
        confirmAlert({
            closeOnClickOutside: false,
            customUI: ({ onClose }) => {
                return (
                    <div className='custom-ui'>
                        <h2>There are still {data.length.toString()} questions left.</h2>
                        <h3>Please attempt all before submit.</h3>
                        {/* <h5>We recommend you to please attempt all questions in the test.</h5> */}
                        <button onClick={() => { onClose(); }}>Go Back</button>
                    </div>
                );
            }
        });
    }

    // const RedirectOnWebsite = async () => {
    //     await axios.get("Nishant/GETStudedentEncryptedValue?type=0&TextValue=" + PaperSet).then(function (response) {
    //         V = response.data[0].EValue;
    //     })
    // }

    const RenderAllQuestion = (QList) => {
        return (
            <>
                <div id="divModel"></div>
                <div id="divClapping"></div>
                <span style={{ display: QList == null ? 'block' : 'none', textAlign: 'center' }} className="main-laoder">
                    <CircularProgress size={80} color="secondary" />
                </span>
                {
                    QList.map((obj, i) => {
                        return <div className="print-container" key={i}>
                            <input type='hidden' className="hdQuestionId" value={obj.QuesId}></input>
                            <div className="print-heading" id={'divHeader_' + obj.QuesId} title={obj.q_no}> {/* //Please don't remove Class Name. Title is using for Summary at the time of Submit */}
                                <span className="heading-text">Question {obj.q_no}/{obj.total}</span>
                                <div className="actions-btn">
                                    <a title={GetQuestionRating(obj.rating)}>{GetRatingStar(obj.rating)}</a>&nbsp;  &nbsp;
                                </div>
                            </div>
                            {obj.IsWordly == 0 ?
                                <>
                                    <div className="print-questions">
                                        <span>{obj.Question}</span>
                                        <span>{obj.QuestionImage != '' ? <><img className="mt-3" src={"https://rms.koenig-solutions.com" + obj.QuestionImage}></img></> : ""}</span>
                                    </div>
                                    <div className="ans-options" id={"AnswerStatus_" + obj.QuesId}>
                                        <span className="option" style={{ display: (obj.Ans1 == '') ? 'none' : 'flex' }}>
                                            <div className="ans-radio">
                                                <Checkbox id={'Chk1_' + obj.QuesId} style={{ display: (obj.multi == '0') ? 'none' : 'flex' }}
                                                    onChange={() => {
                                                        SubmitAnswerMultiple(obj.QuesId)
                                                    }}
                                                />
                                                <input type="radio" style={{
                                                    display: (obj.multi == '1') ? 'none' : 'flex'
                                                    , height: 30
                                                    , marginRight: 20, marginTop: 5, marginLeft: 12
                                                }} name={obj.QuesId}
                                                    onChange={() => {
                                                        SubmitAnswer(obj.QuesId, true, false, false, false)
                                                    }}
                                                />
                                            </div>
                                            <div className="ans-content">
                                                <div>{obj.Ans1}</div>
                                                {obj.OptionOneFilepath != '' ? <><img src={"https://rms.koenig-solutions.com" + obj.OptionOneFilepath}></img></> : ""}
                                            </div>
                                        </span>
                                        <span className="option" style={{ display: (obj.Ans2 == '') ? 'none' : 'flex' }}>
                                            <div className="ans-radio">
                                                <Checkbox id={'Chk2_' + obj.QuesId} style={{ display: (obj.multi == '0') ? 'none' : 'flex' }}
                                                    onChange={() => {
                                                        SubmitAnswerMultiple(obj.QuesId)
                                                    }}
                                                />
                                                <input type="radio" style={{
                                                    display: (obj.multi == '1') ? 'none' : 'flex'
                                                    , height: 30
                                                    , marginRight: 20, marginTop: 5, marginLeft: 12
                                                }} name={obj.QuesId}
                                                    onChange={() => {
                                                        SubmitAnswer(obj.QuesId, false, true, false, false)
                                                    }}
                                                />
                                            </div>
                                            <div className="ans-content">
                                                <div>{obj.Ans2}</div>
                                                {obj.OptionTwoFilepath != '' ? <><img src={"https://rms.koenig-solutions.com" + obj.OptionTwoFilepath}></img></> : ""}
                                            </div>
                                        </span>
                                        <span className="option" style={{ display: (obj.Ans3 == '') ? 'none' : 'flex' }}>
                                            <div className="ans-radio">
                                                <Checkbox id={'Chk3_' + obj.QuesId} style={{ display: (obj.multi == '0') ? 'none' : 'flex' }}
                                                    onChange={() => {
                                                        SubmitAnswerMultiple(obj.QuesId)
                                                    }}
                                                />
                                                <input type="radio" style={{
                                                    display: (obj.multi == '1') ? 'none' : 'flex'
                                                    , height: 30
                                                    , marginRight: 20, marginTop: 5, marginLeft: 12
                                                }} name={obj.QuesId}
                                                    onChange={() => {
                                                        SubmitAnswer(obj.QuesId, false, false, true, false)
                                                    }}
                                                />
                                            </div>
                                            <div className="ans-content">
                                                <div>{obj.Ans3}</div>
                                                {obj.OptionThreeFilepath != '' ? <><img src={"https://rms.koenig-solutions.com" + obj.OptionThreeFilepath}></img></> : ""}
                                            </div>
                                        </span>
                                        <span className="option" style={{ display: (obj.Ans4 == '') ? 'none' : 'flex' }}>
                                            <div className="ans-radio">
                                                <Checkbox id={'Chk4_' + obj.QuesId} style={{ display: (obj.multi == '0') ? 'none' : 'flex' }}
                                                    onChange={() => {
                                                        SubmitAnswerMultiple(obj.QuesId)
                                                    }}
                                                />
                                                <input type="radio" style={{
                                                    display: (obj.multi == '1') ? 'none' : 'flex'
                                                    , height: 30
                                                    , marginRight: 20, marginTop: 5, marginLeft: 12
                                                }} name={obj.QuesId}
                                                    onChange={() => {
                                                        SubmitAnswer(obj.QuesId, false, false, false, true)
                                                    }}
                                                />
                                            </div>
                                            <div className="ans-content">
                                                <div>{obj.Ans4}</div>
                                                {obj.OptionFourFilepath != '' ? <><img src={"https://rms.koenig-solutions.com" + obj.OptionFourFilepath}></img></> : ""}
                                            </div>
                                        </span>
                                        <div className='row pb-2'>
                                            <div className='col-md-12 text-center' id={'divAnswerStatus_' + obj.QuesId}></div>
                                        </div>
                                    </div>
                                </>
                                : <CADWordleComponant obj={obj} Cl_Id={0} c_id={0} paperset={PaperSet} VId={VId} TimeTaken={0} IsFromCAD={true} GetQuestion={GetQuestion} CheckStatus={ShowResultData}></CADWordleComponant>
                            }
                        </div>
                    })
                }
                <div className='row pb-2'>
                    <div className='col-md-4'></div>
                    <div className='col-md-4 text-center' id={'divSubmitButton'}>
                        <a className="btn-outline-primary btn btn-sm text-primary py-2 px-4" onClick={(e) => CheckStatus()}>Submit</a>
                    </div>
                    <div className='col-md-4'></div>
                </div>
            </>
        )
    }

    const RestrictBack = () => {
       
    }

    return (
        <div>
            <div id="LoginPopup"></div>
            <div className="header top-sticky row">
                <div className='col-md-2 p-2'>
                    <a href='javascript:void(0)' onClick={() => RestrictBack()}>
                        <img src="/qbLT.png" width={110}></img>
                    </a>
                </div>
                <div class="col-md-9 text-center-box" style={{display:'grid'}} id="divCourse">
                    <div style={{ color: 'black', fontSize: '25px' }} className="mt-4"><b> {CName}</b> <a className='ml-2 btn text-primary' onClick={(e) => UpdateNextTest()}>Next</a></div>
                    <br></br>
                    <div style={{ color: 'black', fontSize: '12px' }}>{NextCName != "" ? "Upcoming Course: " : "No Upcoming Course" }<b>{NextCName}</b></div>
                </div>
            </div>
            <div id="divMainContent" style={{
                flex: 1,
                display: "flex",
                justifyContent: 'center',
                alignItems: 'center'
            }} onCopy={(e) => CommonHelper.CopyEvent(e)} oncut={(e) => CommonHelper.CopyEvent(e)} onpaste={(e) => CommonHelper.CopyEvent(e)}>
                <div className="container" >
                    {/* {RenderInstructionText(TotalQuestion, left_seconds)} */}
                    <div style={{
                        flexDirection: 'column',
                        backgroundColor: '#fafafa'
                    }}>
                        <div id="divQList"></div>
                        <div style={{ flex: 1, display: 'flex', fontFamily: 'Roboto', justifyContent: 'center', fontWeight: 'bold', margin: 20 }} id="divButton"></div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default CADExam