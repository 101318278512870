import React from 'react'

const DMCACopyright = () => {
    var styles = {
        heading: {
          fontSize: '24px',
          "@media (minWidth: 767px)": {
            fontSize: '18px'
          }
        }
    };
    return (
        <div>
            <div class=" mb-4 mt-5" style={{textAlign:'center'}}>
                <img src="../../qb.png" alt="QUBITS" style={{ height: 166, width: 278, maxWidth: '278px' }} className="QubitImgDesktop" />    
            </div>
            <hr/>
            <section class="container dmca-container pb-5" style={{fontSize: '16px', lineHeight: '22px'}}>
                <div class="row">
                    <div class="col-12">
                        <h1 style={styles.heading}>NOTICES OF CLAIMED COPYRIGHT INFRINGEMENT (OR OTHER TYPES OF INFRINGEMENT)</h1>
                        <p>
                            If you see anything on our site that you believe infringes your copyright rights, you may notify
                            our Digital Millennium Copyright Act ("DMCA") agent by sending the following information:
                        </p>
                        <ul>
                            <li>Identification of the copyrighted work or works claimed to have been infringed. IMPORTANT: please
                                include your copyright registration number. If your work is not yet registered, please include a
                                copy of the application to register the work that you filed with the Copyright Office. A
                                copyright infringement claim based on a U.S. work can only be filed if the work has been
                                registered (http://www.copyright.gov/eco/). </li>
                            <li>Identification of the material on our servers that is claimed to be infringing and that is to be
                                removed, including the URL or other information to enable us to locate the material.</li>
                            <li>A statement that you have a good faith belief that use of the material in the manner complained
                                of is not authorized by you as copyright owner, or by your agent, or by law.</li>
                            <li>A statement that the information in your notice is accurate, and under penalty of perjury, that
                                you are the owner (or authorized to act on behalf of the owner) of the exclusive copyright right
                                that is allegedly being infringed.</li>
                            <li>Your physical or electronic signature, or of someone authorized to act on your behalf.</li>
                            <li>Instructions on how we may contact you: preferably email, but also address and phone.</li>
                        </ul>

                        <p class="mt-3">Please send your concern to <span style={{color:"blue"}}>Qubits@koenig-solutions.com</span></p>

                        <a class="btn btn-primary show-agent-button" href="/">
                            Go Back
                        </a>


                    </div>
                </div>
            </section>
        </div>
    )
}

export default DMCACopyright
