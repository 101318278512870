import React, { useState, useEffect } from 'react';
import ReactDOM from 'react-dom';
import CSDataTable from '../Reusable/CSDataTable';
import { Interweave } from 'interweave';

const axios = require("axios");
var moment = require('moment'); // require
const AllQubitsLiveTestList = (props) => {
    const Coulumn = [
        { text: 'Test Name', key: 'TestName', sortable: true },
        {
            text: 'Created Date',
            key: '',
            sortable: true,
            cell: d => {
                return (<>
                    <span>{moment(d.CreatedDate).format("DD-MMM-YYYY HH:mm:ss")}</span>
                </>);
            }
        },
        {
            text: 'Finish Status',
            key: '',
            sortable: true,
            cell: d => {
                return (<>
                    <div id={"divTestStatus_" + d.TestId}>
                        <a onClick={e => GetStatusContant(d)}> 
                            {d.IsTestFinished ?
                                <span className='badge btn btn-outline-success text-success'>Yes</span> :
                                <span className='badge btn btn-outline-danger text-danger'>No</span>}</a>
                    </div>
                </>);
            }
        },
        {
            text: 'Add',
            key: '',
            sortable: false,
            cell: d => {
                return (
                    <>
                        {
                            <div id={"divTestAction_" + d.TestId}>
                                <span id={"spTestViewAction_" + d.TestId}>
                                    {!d.IsTestFinished ? <a className="badge btn btn-outline-primary text-primary mr-2" onClick={e => ResumeTest(d)}><i className='fa fa-eye'></i> View / Resume</a> : <></>}
                                </span>
                                <a className="badge btn btn-outline-primary text-primary" onClick={e => {props.SendReport(d.TestId); alert('Successfully sent. Please check your inbox.')}}><i className='fa fa-bar-chart'></i> Send Report</a>
                            </div>
                        }
                    </>
                );
            }
        },
    ]
    const [Rows] = useState(props.Data);

    const GetDataTable = () => {
        if (Rows.length > 0) return <CSDataTable Rows={Rows} Columns={Coulumn} PageSize={10} Length_menu={[10, 20, 50, 100]}></CSDataTable>
        else return <div className="text-center"><label className="alert-danger alert">No Question added</label></div>
    }

    const ResumeTest = (d) => {
        var data ={
            ID: d.TestId,
            Name: d.TestName
        }
        props.ViewResumeTest(data)
        props.hideAlert()
    }

    const GetStatusContant = (d) => {
        props.ChangeTestStatus(d.TestId, d.IsTestFinished)
        ReactDOM.render(<a onClick={e => GetStatusContant(d.TestId, !d.IsTestFinished)}>
            {!d.IsTestFinished ?
                <span className='badge btn btn-outline-success text-success'>Yes</span> :
                <span className='badge btn btn-outline-danger text-danger'>No</span>}</a>, document.getElementById("divTestStatus_" + d.TestId))
        ReactDOM.render(!d.IsTestFinished ? <></> : <a className="badge btn btn-outline-primary text-primary mr-2" onClick={e => ResumeTest(d)}><i className='fa fa-eye'></i> View / Resume</a>, document.getElementById("spTestViewAction_" + d.TestId))
    }

    return (
        <div className="pl-2 pr-2">
            {GetDataTable()}
        </div>
    )
}

export default AllQubitsLiveTestList