import React, { useEffect, useRef } from 'react'
import ApexCharts from 'apexcharts'

const PieChart = (props) => {
    const chartRef = useRef(null)

    useEffect(() => {
        const chart = new ApexCharts(chartRef.current, {
            chart: {
                type: 'pie',
                width: 340
            },
            series: props.series,
            labels: props.labels,
            options: {
                labels: {
                    formatter: function (val, { series, seriesIndex, dataPointIndex, w }) {
                        return series[seriesIndex]["data"]
                    }
                },
                tooltip: {
                    y: {
                        formatter: function (val, { series, seriesIndex, dataPointIndex, w }) {
                            return val + ' (' + (val / w.globals.seriesTotals.reduce((a, b) => {
                            return a + b
                        }, 0) * 100).toFixed(2) + '%)'
                        }
                    }
                }
            }
        })
        chart.render();
    }, [])

    return (
        <div>
            <div ref={chartRef}></div>
        </div>
    )
}

export default PieChart
