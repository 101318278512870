import React from 'react';
import ReactDOM from 'react-dom';
import { Alert, Autocomplete } from '@material-ui/lab';
import ReactDOMServer from 'react-dom/server';
import TextField from '@material-ui/core/TextField';
import CircularProgress from '@material-ui/core/CircularProgress';
import Countdown, {zeroPad} from "react-countdown";
import Checkbox from '@material-ui/core/Checkbox';
import SweetAlert from 'react-bootstrap-sweetalert';
import { confirmAlert } from 'react-confirm-alert'; // Import
import 'react-confirm-alert/src/react-confirm-alert.css'; // Import css
import { Table, Button, Tab } from 'semantic-ui-react';
import { ToastContainer, toast } from 'react-toastify';
import '../../Home1.css'
import 'react-toastify/dist/ReactToastify.css';
import SubjectiveQuestionList from './SubjectiveQuestionList';
import AppBarMenu from '../Reusable/QLAppbar'
import { useParams } from 'react-router-dom';
//import socket from '../Reusable/Utility/Socket';
import HtmlEditorBox from '../Reusable/HtmlEditorBox';
import { Interweave } from 'interweave';
import DiscussionRequired from './DiscussionRequired';
import AllQubitsLiveTestList from './AllQubitsLiveTestList';
import { split } from 'postcss/lib/list';

const axios = require('axios');
const QubitsliveS = () => {
    const [showOptions, setShowOptions] = React.useState(false);
    const [subjectiveValue, setSubjectiveValue] = React.useState(true);

    const [McqTagA, setMcqTagA] = React.useState(false);
    const [McqTagB, setMcqTagB] = React.useState(false);
    const [McqTagC, setMcqTagC] = React.useState(false);
    const [McqTagD, setMcqTagD] = React.useState(false);

    const inputRef = React.useRef(null);
    const [main_loader, set_main_loader] = React.useState(false);
    const [AllSubjective, setAllSubjective] = React.useState([]);
    const [TestId, setTestId] = React.useState(0);

    const [] = React.useState('');
    const [Ques_Subtext, set_Ques_Subtext] = React.useState('');
    const [SubjectiveTestName, setSubjectiveTestName] = React.useState('');
    const [SubjectiveQuestion, setSubjectiveQuestion] = React.useState('');
    const [McqOptionA, setMcqOptionA] = React.useState('');
    const [McqOptionB, setMcqOptionB] = React.useState('');
    const [McqOptionC, setMcqOptionC] = React.useState('');
    const [McqOptionD, setMcqOptionD] = React.useState('');
    const [SubCourseList, setSubCourseList] = React.useState([]);
    const [PositionList, setPositionList] = React.useState([]);
    const [Position, setPosition] = React.useState('');
    const [ExistingList, setExistingList] = React.useState([]);
    const [CourseId, setCourseId] = React.useState('');

    const [ModuleList, setModuleList] = React.useState([]);
    const [MName, setMName] = React.useState('');
    const [IsHR, setIsHR] = React.useState(false)
    const [IsSwitch, setIsSwitch] = React.useState(false)
    const [IsTestFinished, setIsTestFinished] = React.useState(false)
    const [MId, setMId] = React.useState('');
    const [UpdatedQId, setUpdatedQId] = React.useState(0);
    const [selectedValue, setSelectedValue] = React.useState(null);

    const [IsValidLogin, setIsValidLogin] = React.useState(false);
    const [selectedData, setSelectedData] = React.useState([]);
    const [IsUpdating, setIsUpdating] = React.useState(false);
    const [IntervalId, setIntervalId] = React.useState(0);
    const [IsDiscussionNeeded, setIsDiscussionNeeded] = React.useState(false);
    const UserId = localStorage.getItem("QLTrainerUserId");
    const QLId = localStorage.getItem("QLQubitsLiveId");
    

    // React.useEffect(() => {
    //     console.log(SubjectiveQuestion);
    //   }, []);

    React.useEffect(() => {
        let trainerID = CheckValidUserLogin();
        if (trainerID != '') {
            setIsValidLogin(true);
            GetPosition();
            VeriFyTrainerType(trainerID);
            get_trainerDetails(trainerID);
            GetExistingTestList(trainerID);
            localStorage.setItem("QLPaperset", '')
            SetDefaultlocalStorage();
            GetPrevTestStatus(trainerID);
            GetCurrentAsssignment(trainerID);
        }
        else { alert('You are using Invalid link. Please login to https://rms.koenig-solutions.com and come again'); }
    }, []);

    const GetPrevTestStatus = (TId) => {
        let Id = typeof QLId === 'undefined' ? 0 : QLId;
        axios.get('/Nishant/Qubits/VerifyTestMaker?Type=22&trainerID=' + TId + '&QLId=' + Id).then(function (response) {
            if (response.data.length > 0) {
                RendorPrevTestInformationPopUp(response.data[0])
            }
            else
            {
                RendorTestInformationPopUp();
            }
        })
    }

    const handleCheckboxChangeMcq = (e) => {
        if(IsUpdating==true)
        {
            toast.error('Can\'t change the question type at this stage.')
        }
        else
        {
            setShowOptions(e.target.checked);
            setSubjectiveValue(!e.target.checked);
        }
    };

    const handleCheckboxChangeSubjective = (e) => {
        if(IsUpdating==true)
        {
            toast.error('Can\'t change the question type at this stage.')
        }
        else
        {
            setSubjectiveValue(e.target.checked);
            setShowOptions(!e.target.checked);
        }
    };

    const handleCheckboxChangeA = (e) => {
        setMcqTagA(e.target.checked);
    };

    const handleCheckboxChangeB = (e) => {
        setMcqTagB(e.target.checked);
    };

    const handleCheckboxChangeC = (e) => {
        setMcqTagC(e.target.checked);
    };

    const handleCheckboxChangeD = (e) => {
        setMcqTagD(e.target.checked);
    };

    const handleSelectedDataChange = (selectedRows) => {
        setSelectedData(selectedRows);
        localStorage.setItem("selectedRows", selectedRows)
        //console.log(selectedRows)
    };

    const RendorPrevTestInformationPopUp = (data) => {
        confirmAlert({
            closeOnClickOutside: false,
            customUI: ({ onClose }) => {
                return (
                    <div className='custom-ui' style={{width:'600px'}}>
                        <h2>Your previous test was not finished!</h2>
                        <h5>Test Name: {data.Name}</h5>
                        <button className='btn' onClick={() => {ResumePrevTest(data); onClose();}}>Resume Prev Test</button>
                        <button className='btn' onClick={() => {GetAllTestList(); onClose();}}>Get All Test List</button>
                        <button className='btn' onClick={() => {onClose();}}>Create New Test</button>
                    </div>
                );
            }
        });
    }

    const RendorTestInformationPopUp = () => {
        confirmAlert({
            closeOnClickOutside: false,
            customUI: ({ onClose }) => {
                return (
                    <div className='custom-ui' style={{width:'600px'}}>
                        <h2>Please select an option from the choices below.</h2>
                        <button className='btn' onClick={() => {GetAllTestList(); onClose();}}>Get All Test List</button>
                        <button className='btn' onClick={() => {onClose();}}>Create New Test</button>
                    </div>
                );
            }
        });
    }

    const GetAllTestList = () => {
        ReactDOM.render(<SweetAlert
            title="List Of Test taken By You"
            customClass="sweetAlertWidth"
            showCloseButton={true}
            confirmBtnText="Create New"
            cancelBtnText="Close"
            confirmBtnBsStyle="primary"
            onConfirm={() => hideAlert()}
            onCancel={() => {hideAlert(); { TestId == 0 ? GetPrevTestStatus(UserId) : void(0)}}}>
            <div id="FeedbackContent"></div>
        </SweetAlert>, document.getElementById('getSweetModel'))
        GetAllTakenTestList()
    }

    const GetAllTakenTestList = () => {
        axios.get('/Nishant/Qubits/VerifyTestMaker?Type=30&trainerID=' + UserId + '&QLId=0').then(function (response) {
            if (response.data != null) {
                ReactDOM.render(<AllQubitsLiveTestList Data={response.data} SendReport={GetEmailDetail} ViewResumeTest={ResumePrevTest} hideAlert={hideAlert} ChangeTestStatus={FinishPrevTestAndCreateNew}></AllQubitsLiveTestList>, document.getElementById('FeedbackContent'))
            }
        })
    }

    const GetCurrentAsssignment = (userid) => {
        axios.get('/Nishant/Qubits/VerifyTestMaker?Type=33&trainerID=' + userid + '&QLId=0').then(function (response) {
            
            if (response.data != null && response.data.length > 0) {
                console.log(response.data)
                const newValue = { CId: response.data[0].CId, CName: response.data[0].CName }
                setSelectedValue(newValue);
                setCourseId(response.data[0].CId)
                GetAllModuleList(response.data[0].CId == null ? 0 : response.data[0].CId); 
                localStorage.setItem("QLCouId", response.data[0].CId != null ? response.data[0].CId : 0)
            }
        })
    }

    const ResumePrevTest = (data) => {
        //ReactDOM.render(Subjective(), document.getElementById('divSubjective'))
        clearInterval(IntervalId)
        setTestId(data.ID)
        setSubjectiveTestName(data.Name)
        setIsSwitch(true);
        let Link = "https://www.qubits42.com/live/" + data.ID;
        RenderControlAfterTestCreated(Link, data.ID)
        set_Ques_Subtext('');
        var interval = setInterval(() => {
            GetAllSubjectiveQuestion(data.ID);
        }, 3000);
        setIntervalId(interval)
    }

    const FinishPrevTestAndCreateNew = (TestId, Status) => {
        axios.get('/Nishant/Qubits/QubitsLiveTestStatus?Type=31&TestId=' + TestId + '&flag=' + !Status).then(function (response) {
            if (response.data.length > 0) {
                if(response.data[0].IsActive) {GetEmailDetail(response.data[0].ID); alert("Successfully marked as finished. Report sent");}
                else alert('Successfully activated');
            }
        })
    }

    const CheckValidUserLogin = () => {
        if(UserId == null || UserId == '' || UserId == 0) return ''
        else return UserId
    }

    const GetPosition = () => {
        axios.get('/Nishant/Qubits/GetPositionList?Type=22').then(function (response) {
            if (response.data != null) {
                setPositionList(response.data)
            }
        })
    }

    const GetExistingTestList = (TestId) => {
        axios.get('/Nishant/Qubits/VerifyTestMaker?Type=17&trainerID=' + TestId).then(function (response) {
            if (response.data != null) {
                setExistingList(response.data)
            }
        })
    }

    const VeriFyTrainerType = (TId) => {
        axios.get('/Nishant/Qubits/VerifyTestMaker?Type=13&trainerID=' + TId).then(function (response) {
            if (response.data != null) {
                localStorage.setItem("QLuser_name", response.data[0].UserName)
                if (response.data[0].LoginType == 'HR') setIsHR(true)
                else { GetAllCourseList(); setIsHR(false);  }
            }
            setModuleList(response.data);
        })
    }

    const GetAllCourseList = () => {
        axios.get('/Nishant/Qubits/GetAllCourse?Type=12').then(function (response) {
            setSubCourseList(response.data);
        })
    }

    const GetAllModuleList = (CourseIdParameter) => {
        setModuleList([]);
        setMName('');
        axios.get('/Nishant/Qubits/GetAllModule?Type=11&CId=' + CourseIdParameter).then(function (response) {
            setModuleList(response.data);
        })
    }

    function get_trainerDetails(trainer_id) {
        if (trainer_id > 0) {
            axios.get('https://api.koenig-solutions.com/api/qubits/get_TrainerDetails?trainerID=' + trainer_id).then(function (response) {
                    set_main_loader(false)
                    if (JSON.parse(response.data).length != 0) {
                        let res = JSON.parse(response.data)
                        //setCourseList(response.data)
                        localStorage.setItem("QLcordinator_id", res[0].StdId)
                        localStorage.setItem("QLcordinator_email", res[0].Email)
                        localStorage.setItem("QLcordinator_type", res[0].type)
                        localStorage.setItem("QLuser_name", res[0].Name)
                    }
                })
        }
    }

    const CreateSubjectiveTestName = () => {
        if (Ques_Subtext != "") {
            var obj = {
                Name: Ques_Subtext,
                TestMakerId: UserId,
                CourseId: CourseId,
                ModuleId: MId,
                PositionId: Position
            };
            axios.post('/Nishant/Qubits/AddTestName', obj).then(function (r) {
                setTestId(r.data[0].TestId)
                setSubjectiveTestName(r.data[0].TestName)
                setIsSwitch(true);
                //toast.success(r.data[0].TestName + ' successfully created');
                let Link = "https://www.qubits42.com/live/" + r.data[0].TestId;
                RenderControlAfterTestCreated(Link, r.data[0].TestId)
                set_Ques_Subtext('');
                var Interval = setInterval(() => {
                    GetAllSubjectiveQuestion(r.data[0].TestId);
                }, 5000);
                setIntervalId(Interval)
            })
        }
    }

    const RenderControlAfterTestCreated = (Link, TId) => {
        ReactDOM.render(<>
            <span className="mr-2">Share this link with student:</span>
            <span id="LinkContent" className="text-success">{Link}</span>
            <span className="badge btn btn-outline-primary ml-2" onClick={(e) => CopydivContent(Link)}>Copy</span>
        </>, document.getElementById('getLink'));

        ReactDOM.render(<>
            <Button color='blue' className="mt-0 form-control" size="small" onClick={() => {
                GetListOfQuestion(TId)
            }} style={{ width: '' }} >
                Filter Question
            </Button>
        </>, document.getElementById('divbtnAddTest'));
    }

    const GetAllSubjectiveQuestion = (TId) => {
        var obj = {
            Type: 2,
            TestId: TId
        };
        axios.post('/Nishant/Qubits/GetAllSubjectiveQuestionPost', obj).then(function (response) {
            if (response.data.length > 0) { setAllSubjective(response.data); setIsDiscussionNeeded(response.data[0].IsDiscussionneededWithTrainer > 0 ? true : false)}
        })
    }

    const GetListOfQuestion = (TId) => {
        ReactDOM.render(<SweetAlert
            title="Question List"
            customClass="sweetAlertWidth"
            showCloseButton={true}
            confirmBtnText="Add All & Close"
            cancelBtnText="Close"
            confirmBtnBsStyle="primary"
            onConfirm={() => AddAllQuestionOfPrevtest(TId)}
            customButtons={
                <div>
                  <button  onClick={() => AddAllQuestionOfPrevtest(TId)} className="btn btn-outline-primary custom-button">
                  Add All & Close
                  </button>
                  <span style={{ marginRight: '10px' }}></span>
                  <button onClick={() => AddAllSelectedQuestion(TId)} className="btn btn-outline-primary custom-button">
                    Add Selected
                  </button>
                </div>
              }
            onCancel={() => {hideAlert();localStorage.removeItem("selectedRows")}}>
            <div id="FeedbackContent"></div>
        </SweetAlert>, document.getElementById('getSweetModel'))
        GetExistingQuestionList(TId)
    }

    const AddAllQuestionOfPrevtest = (TId) => {
        var obj = {
            Type: 18,
            TId: TId,
            ETestId: localStorage.getItem("QLExistId"),
            CourseId: localStorage.getItem("QLCouId"),
            ModuleId: localStorage.getItem("QLModId"),
            UseMCQ: document.getElementById('ChkMCQ').checked,
            SelectedQues: localStorage.getItem('selectedRows')
        };
        console.log(obj)
        axios.post('/Nishant/Qubits/GetAllSubjectiveQuestionExistingFilter', obj).then(function (response) {
            if (response.data != null) {
                GetAllSubjectiveQuestion(response.data[0].TestId);
                hideAlert()
            }
        })
    }

    const AddAllSelectedQuestion = (TId)=>{
        console.log(localStorage.getItem('selectedRows')+'@'+localStorage.getItem("QLModId"));
        if (!localStorage.getItem('selectedRows')) {
            alert('Please select at least one question.');
            return;
        }
        var obj = {
            Type: 18,
            TId: TId,
            ETestId: localStorage.getItem("QLExistId"),
            CourseId: localStorage.getItem("QLCouId"),
            ModuleId: localStorage.getItem("QLModId"),
            UseMCQ: document.getElementById('ChkMCQ').checked,
            SelectedQues: localStorage.getItem('selectedRows')
        };
        console.log(obj)
        axios.post('/Nishant/Qubits/GetAllSubjectiveQuestionExistingFilter', obj).then(function (response) {
            if (response.data != null) {
                GetAllSubjectiveQuestion(response.data[0].TestId);
                hideAlert();
                localStorage.removeItem("selectedRows");
            }
        })
    }

    const GetExistingQuestionList = (TId) => {
        var obj = {
            Type: 15,
            TId: TId,
            ETestId: localStorage.getItem("QLExistId"),
            CourseId: localStorage.getItem("QLCouId"),
            ModuleId: localStorage.getItem("QLModId"),
            PositionId: localStorage.getItem("QLPosId"),
            UseMCQ: document.getElementById('ChkMCQ').checked
        };
        axios.post('/Nishant/Qubits/GetAllSubjectiveQuestionExistingFilter', obj).then(function (response) {
            if (response.data != null) {
                ReactDOM.render(<SubjectiveQuestionList TID={TId} UserId={UserId} Data= {response.data} onSelectedRowsChange={handleSelectedDataChange}></SubjectiveQuestionList>, document.getElementById('FeedbackContent'))
            }
        })
    }
    
    const hideAlert = () => {
        ReactDOM.render("", document.getElementById('getSweetModel'));
    }

    const SetDefaultlocalStorage = () => {
        localStorage.removeItem("QLExistId")
        localStorage.removeItem("QLCouId")
        localStorage.removeItem("QLModId")
        localStorage.removeItem("QLPosId")
        localStorage.removeItem("selectedRows")
    }

    const AddQuestionTosubjectiveTest = () => {
        if (SubjectiveQuestion != "") {
            var obj = {
                Question: SubjectiveQuestion,
                trainerID: UserId,
                TestId: TestId,
                QId: UpdatedQId,
                IsMCQ: showOptions,
                OptionATag :McqTagA,
                OptionBTag :McqTagB,
                OptionCTag :McqTagC,
                OptionDTag :McqTagD,
                OptionA:McqOptionA,
                OptionB:McqOptionB,
                OptionC:McqOptionC,
                OptionD:McqOptionD,
            };
            
            if (showOptions==true && (!McqOptionA.trim() || !McqOptionB.trim()))
            {
                toast.error('Options A and B are mandatory.')
            }
            else if (showOptions==true && (!McqOptionC.trim() && McqOptionD.trim()))
            {
                toast.error('Option D is filled while option C is empty.')
            }
            else if(showOptions==true && McqTagA==false && McqTagB==false && McqTagC==false && McqTagD==false )
            {
                toast.error('Please Mark One of the options as correct')               
            }
            else if(showOptions==true && ((!McqOptionC.trim() && McqTagC==true) || (!McqOptionD.trim() && McqTagD==true)))
            {
                toast.error('Please mark only valid options as correct.')  
            }
            else
            {
            axios.post("/Nishant/Qubits/AddQuestion", obj).then(function (r) {
                //setSubjectiveTestName(r.data[0].TestName)
                //toast.success('Question successfully Added');
                setSubjectiveQuestion('');
                setMcqOptionA('');
                setMcqOptionB('');
                setMcqOptionC('');
                setMcqOptionD('');
                setMcqTagA(false);
                setMcqTagB(false);
                setMcqTagC(false);
                setMcqTagD(false);
                GetAllSubjectiveQuestion(TestId);
                setIsUpdating(false);
                setUpdatedQId(0)
            })
            }
        }
    }

    const StatusCSSClassName = (Id) => {
        switch (Id) {
            case "1": return "text-success";
            case "0": return "text-danger";
            case "2": return "text-warning";
            default: return "text-default";
        }
    }

    const SendStatusToTrainer = () => {
        confirmAlert({
            title: 'Please Confirm',
            message: "Are you sure to Finish the test?",
            buttons: [
                {
                    label: 'Yes',
                    onClick: () => {
                        SendFinalReport(TestId)
                    }
                },
                {
                    label: 'No',
                    onClick: () => {}
                }
            ]
        });
    }

    const SendFinalReport = (TId, DisplayAlert = 1) => {
        axios.get('/Nishant/Qubits/GetAllSubjectiveQuestion?Type=26&TestId=' + TId).then(function (response) {
            if (response.data != null) {
                GetEmailDetail(response.data[0].TestId);
                if (DisplayAlert == 1) {
                    setIsTestFinished(response.data[0].IsTestFinished)
                    alert("Report sent");
                } 
            }
        })
    }

    const GetEmailDetail = (TId) => {
        axios.get('/Nishant/Qubits/GetAllSubjectiveQuestion?Type=25&TestId=' + TId).then(function (response) {
            if (response.data.length > 0) {
                var detail = response.data
                axios.get('/Nishant/Qubits/GetAllSubjectiveQuestion?Type=24&TestId=' + TId).then(function (r) {
                    if (r.data.length > 0) {
                        var data = r.data
                        var QDetail = ReactDOMServer.renderToStaticMarkup(ReturnHtml(detail, data));
                        var SendTo = data[0].Email;
                        SendMail('info@qubits.com', SendTo, '', 'nishant.sinha@koenig-solutions.com', 'Test on ' + data[0].TestName + " via Qubits Live", QDetail);
                    }
                })
                
            }
        })
    }

    async function SendMail(uname, to, cc, bcc, subject, html) {
        try {
            var obj = {
                _uname: uname,
                _to: to,
                _cc: cc,
                _bcc: bcc,
                _subject: subject,
                _html: html
            };
            await axios.post("/Nishant/sendemail", obj)
        } catch (er) {
            //alert(JSON.stringify(er));
        }
    }
    
    const ReturnHtml = (data, dInner) =>{
        return(
            <>
                <span>Hi { dInner[0].TestTakerName } </span><br></br>
                <span>Student wise Test report - Total Questions given {data[0].TotalQuestion} </span><br></br><br></br>
                <table style={{ width: "100%", color: "#212529", border: "1px solid #dee2e6", marginBottom: "1rem" }}>
                    <tr style={{ padding: "2px", border: "1px solid #dee2e6", marginBottom: "1rem", backgroundColor:'cornflowerblue' }}>
                        <td style={{ padding: "10px", border: "1px solid #dee2e6", marginBottom: "1rem" }}>Rank</td>
                        <td style={{ padding: "10px", border: "1px solid #dee2e6", marginBottom: "1rem" }}>Student Email Id</td>
                        <td style={{ padding: "10px", border: "1px solid #dee2e6", marginBottom: "1rem" }}># of Questions Attempted</td>
                        <td style={{ padding: "10px", border: "1px solid #dee2e6", marginBottom: "1rem" }}>Answered correctly</td>
                        <td style={{ padding: "10px", border: "1px solid #dee2e6", marginBottom: "1rem" }}>Incorrectly</td>
                        <td style={{ padding: "10px", border: "1px solid #dee2e6", marginBottom: "1rem" }}>Not checked</td>
                        <td style={{ padding: "10px", border: "1px solid #dee2e6", marginBottom: "1rem" }}>% score</td>
                    </tr>
                    {data.map((d, i) =>
                        <tr style={{ padding: "2px", border: "1px solid #dee2e6", marginBottom: "1rem" }}>
                            <td style={{ padding: "10px", border: "1px solid #dee2e6", marginBottom: "1rem" }}>{d.Rank}</td>
                            <td style={{ padding: "10px", border: "1px solid #dee2e6", marginBottom: "1rem" }}>{d.ClientEmail}</td>
                            <td style={{ padding: "10px", border: "1px solid #dee2e6", marginBottom: "1rem" }}>{d.TotalAttemptedQuestion}</td>
                            <td style={{ padding: "10px", border: "1px solid #dee2e6", marginBottom: "1rem" }}>{d.TotalCorrectAttemptedWithQuestionDetail}</td>
                            <td style={{ padding: "10px", border: "1px solid #dee2e6", marginBottom: "1rem" }}>{d.TotalInCorrectAttemptedQuestion}</td>
                            <td style={{ padding: "10px", border: "1px solid #dee2e6", marginBottom: "1rem" }}>{d.TotalNotAttemptedQuestion}</td>
                            <td style={{ padding: "10px", border: "1px solid #dee2e6", marginBottom: "1rem", backgroundColor: d.Score >= 80 ? 'Green' : d.Score <= 20 ? 'Red' : 'Yellow' }}>{d.Score}</td>
                        </tr>
                    )}
                </table>
                <hr></hr>
                <span>List of Questions</span>
                <br></br>
                <table style={{ width: "100%", color: "#212529", border: "1px solid #dee2e6", marginBottom: "1rem" }}>
                    <tr style={{ padding: "2px", border: "1px solid #dee2e6", marginBottom: "1rem", backgroundColor:'cornflowerblue' }}>
                        <td style={{ padding: "10px", border: "1px solid #dee2e6", marginBottom: "1rem" }}>SLNo</td>
                        <td style={{ padding: "10px", border: "1px solid #dee2e6", marginBottom: "1rem" }}>Question</td>
                    </tr>
                    {dInner.map((d, i) =>
                        <tr style={{ padding: "2px", border: "1px solid #dee2e6", marginBottom: "1rem" }}>
                            <td style={{ padding: "10px", border: "1px solid #dee2e6", marginBottom: "1rem" }}>{d.QuestionAliasName}</td>
                            <td style={{ padding: "10px", border: "1px solid #dee2e6", marginBottom: "1rem" }}><Interweave content={d.Question} /></td>
                        </tr>
                    )}
                </table>
            </>
        )
    }

    function getFeedbackText(Id, IsCorrect, FD,ismcq) {
        return (
            <>
                <span id={"Status_" + Id} className="ans-action-outer">
                    {IsCorrect == null || IsCorrect == "3" ?
                        <>
                            <a style={{ display: (ismcq == '1') ? 'none' : 'block' }} onClick={() => MarkCorrect(Id, 1)} className="btn btn-outline-success ">
                                Correct
                            </a>
                            <a style={{ display: (ismcq == '1') ? 'none' : 'block' }} onClick={() => MarkCorrect(Id, 2)} className="btn btn-outline-warning ">
                                Partailly Correct
                            </a>
                            <a style={{ display: (ismcq == '1') ? 'none' : 'block' }} onClick={() => MarkCorrect(Id, 0)} className="btn btn-outline-danger ">
                                Incorrect
                            </a>
                            {/* <a onClick={() => MarkCorrect(Id, 3)} className=" btn btn-outline-info">
                                Check via ChatGPT
                            </a> */}
                        </>
                        :
                        <>
                            {IsCorrect == 1 ?
                                <div style={{ display: (ismcq == '1') ? 'none' : 'block' }} className="d-flex w-100">
                                    <span style={{ display: (ismcq == '1') ? 'none' : 'block' }} className=" btn btn-outline-success disabled">Correct</span>
                                    <a style={{ display: (ismcq == '1') ? 'none' : 'block' }} onClick={() => MarkCorrect(Id, 2)} className=" btn btn-outline-warning">
                                        Partially Correct
                                    </a>
                                    <a style={{ display: (ismcq == '1') ? 'none' : 'block' }} onClick={() => MarkCorrect(Id, 0)} className=" btn btn-outline-danger">
                                        Incorrect
                                    </a>
                                    {/* <a onClick={() => MarkCorrect(Id, 3)} className=" btn btn-outline-info">
                                        Check via ChatGPT
                                    </a> */}
                                </div>
                                :
                                <>
                                    {
                                        IsCorrect == 0 ?
                                            <div style={{ display: (ismcq == '1') ? 'none' : 'block' }} className="d-flex w-100">
                                                <a style={{ display: (ismcq == '1') ? 'none' : 'block' }} onClick={() => MarkCorrect(Id, 1)} className=" btn btn-outline-success">
                                                    Correct
                                                </a>
                                                <a style={{ display: (ismcq == '1') ? 'none' : 'block' }} onClick={() => MarkCorrect(Id, 2)} className=" btn btn-outline-warning">
                                                    Partially Correct
                                                </a>
                                                <span style={{ display: (ismcq == '1') ? 'none' : 'block' }} className=" btn btn-outline-danger  disabled">Incorrect</span>
                                                {/* <a onClick={() => MarkCorrect(Id, 3)} className=" btn btn-outline-info">
                                                    Check via ChatGPT
                                                </a> */}
                                            </div>
                                            :
                                            <div style={{ display: (ismcq == '1') ? 'none' : 'block' }} className="d-flex w-100">
                                                <a style={{ display: (ismcq == '1') ? 'none' : 'block' }} onClick={() => MarkCorrect(Id, 1)} className=" btn btn-outline-success">
                                                    Correct
                                                </a>
                                                <span style={{ display: (ismcq == '1') ? 'none' : 'block' }} className=" btn btn-outline-warning disabled">Partially Correct</span>
                                                <a style={{ display: (ismcq == '1') ? 'none' : 'block' }} onClick={() => MarkCorrect(Id, 0)} className=" btn btn-outline-danger">
                                                    Incorrect
                                                </a>
                                                {/* <a onClick={() => MarkCorrect(Id, 3)} className=" btn btn-outline-info">
                                                    Check via ChatGPT
                                                </a> */}
                                            </div>
                                    }
                                    {
                                        FD == 'NA' ? <a onClick={() => ShowFeedbackPopup(Id, FD)} className=" btn btn-outline-primary text-nowrap">
                                            Feedback
                                        </a> : <a onClick={() => ShowFeedbackPopup(Id, FD)} className=" btn btn-outline-primary text-nowrap">
                                            View Feedback
                                        </a>
                                    }   
                                </>
                            }
                        </>
                    }
                </span>
            </>
        )
    }

    const GetChatGPTSection = (data, Id) => {
        return (
            <>
                {
                    data.split('#^GPTText$_#')[0] == 1 ? <>
                        <div className={'card mt-3 ' + StatusCSSClassName(Id)}>
                            <span className={'card-header ' + StatusCSSClassName(Id)}>Answer checked by ChatGPT</span>
                            <span className='card-body'><b>ChatGPT Response:</b> {data.split('#^GPTText$_#')[1]}</span>
                        </div>
                    </> : <></>
                }
            </>
        )
    }
    
    const ShowFeedbackPopup = (d, RV) => ReactDOM.render(OpenPopUp(d, RV), document.getElementById('getSweetModel'))
    const ClosePopup = () => ReactDOM.render(<></>, document.getElementById('getSweetModel'))

    const OpenPopUp = (Id, FD) => {
        confirmAlert({
            closeOnClickOutside: false,
            customUI: ({ onClose }) => {
                return (
                    <div className='custom-ui'>
                        <div className="row">
                            <div className="col-md-11">
                                <h2>Feedback!</h2>
                            </div>
                            <div className="col-md-1 mb-4 text-right">
                                <span onClick={() => { onClose(); }}><i className="fa fa-close"></i></span>
                            </div>
                            <div className="col-md-12">
                                <textarea rows="3" id='txtFeedbackRemarks' placeholder="Please type feedback here..." className="form-control">{FD == "NA" ? "" : FD}</textarea>
                            </div>
                        </div>
                        <button onClick={() => { SubmitFeedbackForm(Id); onClose();}}>Submit </button>
                    </div>
                );
            }
        });
    }

    const SubmitFeedbackForm = (d) => {
        var FBText = document.getElementById('txtFeedbackRemarks').value
        if (FBText.length == 0) {
            alert('Please Enter Some Text');
        }
        else {
            UpdateFeedback(d, FBText);
        }
    }

    const UpdateFeedback = (Id, FD) => {
        if (FD == '') alert('Please enter some text')
        else {
            var obj = {
                Type: 27,
                Id: Id,
                value: FD
            };
            axios.post('/Nishant/Qubits/UpdateFeedback', obj).then(function (response) {
                if (response.data != null) {
                    hideAlert();
                    GetAllSubjectiveQuestion(TestId);
                }
            })
        }
    }

    const MarkCorrect = (Id, value) => {
        if (value != 3) {
            var obj = {
                Type: 9,
                Id: Id,
                value: value
            };
            axios.post('/Nishant/Qubits/UpdateAnswerStatus', obj).then(function (response) {
                if (response.data != null) {
                    GetAllSubjectiveQuestion(TestId);
                }
            })
        }
        else alert('This will be implement soon')
    }

    function CopydivContent(Content) {
        var copyText = Content
        document.addEventListener('copy', function (e) {
            e.clipboardData.setData('text/plain', copyText);
            e.preventDefault();
        }, true);
        document.execCommand('copy');
        //alert('Copied');
        return true;
    }

    const SettingTimerSection = (flag) => {
        if(flag) ReactDOM.render(<TextField defaultValue={20} onBlur={(e) => UpdateTimer(e.target.value, flag)} type='number' size="small" label="Enter Time(In minutes)" variant="outlined" />, document.getElementById('spTimerText'))
        else {ReactDOM.render("", document.getElementById('spTimerText')); ReactDOM.render("", document.getElementById('spTimer'))}
        UpdateTimer(flag ? 20 : 0, flag)
    }

    const UpdateTimer = (TimeInMinute, flag) => {
        var obj = {
            Type: 20,
            TId: TestId,
            value: TimeInMinute,
            flag: flag
        };
        axios.post('/Nishant/Qubits/UpdateTimer', obj).then(function (response) {
            if (response.data != null) {
                //socket.emit('incoming data', TimeInMinute);
                RendorTimerTextSection(response.data[0])
            }
        })
    }

    const RendorTimerTextSection = (d) => {
        if (d.IsTimerAdded) {
            ReactDOM.render(<label> Time Left: <Countdown date={Date.now() + d.TimeLeft * 60000} renderer={renderer} /></label>, document.getElementById('spTimer'));
        }
        else ReactDOM.render('', document.getElementById('spTimer'));
    }

    const renderer = ({ hours, minutes, seconds, completed }) => {
        if (completed) {
            return ReactDOM.render('Time Over', document.getElementById('spTimer'));
        } else {
            minutes = minutes + hours * 60;
            return (
                <span className="time-counter">
                    {zeroPad(minutes)}:{zeroPad(seconds)}
                </span>
            );
        }
    };

    const CheckIfAnyCourseSelected = (flag) => {
        if (flag && (localStorage.getItem("QLCouId") == null || localStorage.getItem("QLCouId") == '' || localStorage.getItem("QLCouId") == '0')) {
            alert('Please Select one course');
            return false
        }
        else {
            return true
        }
    }

    const RemoveLinkedQuestion = (QId) => {
        var obj = {
            Type: 23,
            TId: TestId,
            QId: QId
        };
        axios.post('/Nishant/Qubits/RemoveQubitsLiveQuestion', obj).then(function (response) {
            if (response.data != null) {
                GetAllSubjectiveQuestion(TestId);
            }
        })
    }

    const GetEditorValue = (data) => { if (data !== SubjectiveQuestion) {
        setSubjectiveQuestion(data);
      }; }

      const GetEditorValueOptionA = (data) => { if (data !== McqOptionA) {
        setMcqOptionA(data);
      }; }

      const GetEditorValueOptionB = (data) => { if (data !== McqOptionB) {
        setMcqOptionB(data);
      }; }

      const GetEditorValueOptionC = (data) => { if (data !== McqOptionC) {
        setMcqOptionC(data);
      }; }

      const GetEditorValueOptionD = (data) => { if (data !== McqOptionD) {
        setMcqOptionD(data);
      }; }

    // const panes = [
    //     {
    //     menuItem: 'Tab 1',
    //     render: () => <Tab.Pane attached={false}>Tab 1 Content</Tab.Pane>,
    //     },
    //     {
    //     menuItem: 'Tab 2',
    //     render: () => <Tab.Pane attached={false}>Tab 2 Content</Tab.Pane>,
    //     },
    //     {
    //     menuItem: 'Tab 3',
    //     render: () => <Tab.Pane attached={false}>Tab 3 Content</Tab.Pane>,
    //     },
    // ]
    
    // const TabExampleAttachedFalse = () => (
    //     <Tab menu={{ attached: false }} panes={panes} />
    // );

    const FetchDiscussionDetail = () => {
        ReactDOM.render(<SweetAlert
            title="Discussion List"
            customClass="sweetAlertWidth"
            showCloseButton={true}
            confirmBtnText="All Discussed and Close"
            cancelBtnText="Close"
            confirmBtnBsStyle="primary"
            //onConfirm={() => MarkAllAsDiscussed(TestId)}
            onCancel={() => hideAlert()}>
            <div id="FeedbackContent"></div>
        </SweetAlert>, document.getElementById('getSweetModel'))
        GetDiscussionQuestion(TestId)
    }

    const GetDiscussionQuestion = (TId) => {
        var obj = {
            Type: 29,
            TId: TId
        };
        axios.post('/Nishant/Qubits/DiscussionRequired', obj).then(function (response) {
            if (response.data != null) {
                ReactDOM.render(<DiscussionRequired TID={TId} UserId={UserId} Data= {response.data}></DiscussionRequired>, document.getElementById('FeedbackContent'))
            }
        })
    }

    const EditQuestion = (Question,optionA,optionB,optionC,optionD,TagA,TagB,TagC,TagD,IsMcq, QId) => {
        //setSubjectiveQuestion(Question)
        //console.log('Edit Question- '+Question+'-'+SubjectiveQuestion)
        if (Question !== SubjectiveQuestion) {
            setSubjectiveQuestion(Question);
          }

          if(IsMcq=="1")
          {
            setMcqOptionA(optionA)
            setMcqOptionB(optionB)
            setMcqOptionC(optionC)
            setMcqOptionD(optionD)
            setMcqTagA(TagA)
            setMcqTagB(TagB)
            setMcqTagC(TagC)
            setMcqTagD(TagD)
            setShowOptions(true);
            setSubjectiveValue(false);
          }
          else
          {
            setMcqOptionA('')
            setMcqOptionB('')
            setMcqOptionC('')
            setMcqOptionD('')
            setMcqTagA(false)
            setMcqTagB(false)
            setMcqTagC(false)
            setMcqTagD(false)
            setShowOptions(false);
            setSubjectiveValue(true);
          }
          setIsUpdating(true);
          setUpdatedQId(QId);
    }

    const Subjective = () => {
        return (
            <>
                <div style={{ display: !IsTestFinished ? 'block' : 'none' }}>
                    <div className="test-selections">
                        <div className="row">
                            <div className="col-md-3" id="divtxtCreateTest">
                                <TextField id="outlined-basic" style={{ display: IsSwitch ? 'none' : 'flex' }} label="Test Name" variant="outlined" className="form-control" size="small" value={Ques_Subtext} onChange={(e) => set_Ques_Subtext(e.target.value)} />
                                <Autocomplete
                                    style={{ display: !IsSwitch ? 'none' : 'flex' }}
                                    id="cmbExisting"
                                    options={ExistingList}
                                    fullWidth={true}
                                    //getOptionLabel={(option) => option.Name}
                                    getOptionLabel={(option) => (option && option.Name) ? option.Name : ''}
                                    getOptionSelected={(option, value) => value.ID }
                                    onChange={(event, value) => localStorage.setItem("QLExistId", value != null ? value.ID : 0)}
                                    renderInput={(params) => <TextField {...params} size="small" label="Search Existing or Test Maker" variant="outlined" />}
                                />
                            </div>
                            <div className="col-md-3" style={{ display: IsHR ? 'None' : 'flex' }}>
                                <Autocomplete
                                    //disabled={((Ques_Subtext == "") || (Ques_Subtext == ",")) ? true : false}
                                    id="cmbCourse"
                                    options={SubCourseList}
                                    fullWidth={true}
                                    value={selectedValue}
                                    getOptionLabel={(option) => option.CName}
                                    // getOptionSelected={(option, value) => { setCourseId(value.CId) }}
                                    // onChange={(option, value) => { GetAllModuleList(value == null ? 0 : value.CId); localStorage.setItem("QLCouId", value != null ? value.CId : 0) }}
                                    getOptionSelected={(option, value) => value.CId === selectedValue?.CId}
                                    onChange={(event, value) => {
                                        if (value) {
                                        setCourseId(value.CId);
                                        setSelectedValue(value);
                                        GetAllModuleList(value.CId);
                                        localStorage.setItem("QLCouId", value.CId);
                                        } else {
                                        setCourseId(0);
                                        setSelectedValue(null);
                                        localStorage.setItem("QLCouId", 0);
                                        }
                                    }}
                                    renderInput={(params) => <TextField {...params} inputRef={inputRef} size="small" label="Select Course" variant="outlined" />}
                                />
                            </div>
                            <div className="col-md-3" style={{ display: IsHR ? 'None' : 'flex' }}>
                                <Autocomplete
                                    //disabled={((Ques_Subtext == "") || (Ques_Subtext == ",")) ? true : false}
                                    id="cmbModule"
                                    options={ModuleList}
                                    fullWidth={true}
                                    multiple={true}
                                    getOptionLabel={(option) => option.chapter_name}
                                    getOptionSelected={(option, value) => { setMId(value != null ?value.Chapter_Id:0); }}
                                    onChange={(event, value) => {localStorage.setItem("QLModId", value.length>0 ? value[0].Chapter_Id : 0)}}
                                    renderInput={(params) => <TextField {...params} value={MName} size="small" label="Select Module" variant="outlined" />}
                                />
                            </div>
                            <div className="col-md-4" style={{ display: !IsHR ? 'None' : 'flex' }}>
                                <Autocomplete
                                    //disabled={((Ques_Subtext == "") || (Ques_Subtext == ",")) ? true : false}
                                    id="cmbPosition"
                                    options={PositionList}
                                    fullWidth={true}
                                    getOptionLabel={(option) => option.Position}
                                    getOptionSelected={(option, value) => { setPosition(value.Id); }}
                                    onChange={(event, value) => localStorage.setItem("QLPosId", value != null ? value.Id : 0)}
                                    renderInput={(params) => <TextField {...params} size="small" label="Select Position" variant="outlined" />}
                                />
                            </div>
                            <div className="col-md-1" style={{ display: (!IsHR && !IsSwitch) ? 'None' : 'flex' }}>
                                <span>Use Qubits MCQ</span>
                                <Checkbox id="ChkMCQ" onChange={(e) => { CheckIfAnyCourseSelected(e.target.checked) }} />
                            </div>
                            <div className="col-md-2" id="divbtnAddTest">
                                <Button color='blue' className="form-control" size="small" disabled={((Ques_Subtext == "") || (Ques_Subtext == ",")) ? true : false} onClick={() => {
                                    CreateSubjectiveTestName()
                                }} style={{ width: '' }} >
                                    New Test
                                </Button>
                            </div>
                            <div className="col-md-3" style={{ display: !IsHR ? 'None' : 'flex' }}></div>
                        </div>
                    </div>
                    <div className="row mx-0">
                        <div className="col-md-12" id="getLink"></div>
                    </div>
                    <div className="row add-question" style={{ display: SubjectiveTestName != "" ? "flex" : "none" }}>
                        <div className="col-md-12 text-center use-timer">
                            <div class="timer-section">
                                <div className=" text-right float-right mb-3 pl-3">
                                    <span id='spTimerText'></span>
                                </div>
                                <span className="float-right text-right pr-0">
                                    <span>Use Timer </span>
                                    <Checkbox id="ChkUseTimer" onChange={(e) => { SettingTimerSection(e.target.checked) }} />
                                </span>
                                <span id='spTimer' style={{ marginleft: "15em" }}></span>
                            </div>
                            
                            <span className="float-left col-12 text-left pl-0">Add question for <b>{SubjectiveTestName}</b></span>
                            <span className="float-left col-12 text-left pl-0">
                                <div style={{ display: 'flex', alignItems: 'center' }}>
                                <span style={{ marginRight: '10px' }}><b>Question Type:</b></span>
                                    <Checkbox id="ChkSubjectiveQuestion" onChange={handleCheckboxChangeSubjective} checked={subjectiveValue} />
                                    <span><b>Subjective </b></span>
                                    <Checkbox id="ChkMCQQuestion" onChange={handleCheckboxChangeMcq}  checked={showOptions}/>
                                    <span><b>MCQ </b></span>
                                    </div>
                                </span>
                            <HtmlEditorBox Value={SubjectiveQuestion} SetValue={GetEditorValue} placeholder="Type your question here..."></HtmlEditorBox>
                            
                            
                        </div>
                        <div className="col-md-12 text-left mt-2">
                        <span><font style={{color:'Red'}}>**</font>Please mark any of the options as the correct answer for the mcq </span>
                        </div>
                        <div class='col-md-6 col-sm-12 options' style={{ display: showOptions ? 'block' : 'none' }}>
            <div class='form-group'>
                <label for='optionA'>Option A</label><Checkbox id="ChkOptionA" onChange={handleCheckboxChangeA} checked={McqTagA} />
                {/* <textarea rows='4' class='form-control' id='optionA'></textarea> */}
                <HtmlEditorBox Value={McqOptionA} SetValue={GetEditorValueOptionA} placeholder="Type your question here..."></HtmlEditorBox>
            </div>
        </div>
        <div class='col-md-6 col-sm-12 options' style={{ display: showOptions ? 'block' : 'none' }}>
            <div class='form-group'>
                <label for='optionB'>Option B</label><Checkbox id="ChkOptionB" onChange={handleCheckboxChangeB} checked={McqTagB} />
                {/* <textarea rows='4' class='form-control' id='optionB'></textarea> */}
                <HtmlEditorBox Value={McqOptionB} SetValue={GetEditorValueOptionB} placeholder="Type your question here..."></HtmlEditorBox>
            </div>
        </div>
        <div class='col-md-6 col-sm-12 options' style={{ display: showOptions ? 'block' : 'none' }}>
            <div class='form-group'>
                <label for='optionC'>Option C</label><Checkbox id="ChkOptionC" onChange={handleCheckboxChangeC} checked={McqTagC}/>
                {/* <textarea rows='4' class='form-control' id='optionC'></textarea> */}
                <HtmlEditorBox Value={McqOptionC} SetValue={GetEditorValueOptionC} placeholder="Type your question here..."></HtmlEditorBox>
            </div>
        </div>
        <div class='col-md-6 col-sm-12 options' style={{ display: showOptions ? 'block' : 'none' }}>
            <div class='form-group'>
                <label for='optionD'>Option D</label><Checkbox id="ChkOptionD" onChange={handleCheckboxChangeD} checked={McqTagD}/>
                {/* <textarea rows='4' class='form-control' id='optionD'></textarea> */}
                <HtmlEditorBox Value={McqOptionD} SetValue={GetEditorValueOptionD} placeholder="Type your question here..."></HtmlEditorBox>
            </div>
        </div>
                        <div className="col-md-3 mt-4 pt-1">
                            <Button color='blue' size="small" onClick={() => {
                                GetAllTestList()
                            }} style={{ width: '' }} >
                                Get All Test List
                            </Button>
                        </div>
                        <div className="col-md-9 mt-4 pt-1 text-right">
                            <Button color='blue' disabled={(setSubjectiveQuestion == "") ? true : false} size="small" onClick={() => {
                                AddQuestionTosubjectiveTest()
                            }} style={{ width: '' }} >
                                {IsUpdating ? "Update" : "Add"} question
                            </Button>
                            <span style={{ display: (AllSubjective.length > 0 ? 'inline' : 'none') }}>
                                <Button color='gray' className="mt-2" size="small" onClick={() => {
                                    SendStatusToTrainer()
                                }} style={{ width: '' }} >
                                    Email and Exit
                                </Button>
                            </span>
                        </div>
                    </div>
                    <br></br>
                    <div style={{ display: SubjectiveTestName != "" ? "block" : "none" }}>
                        <div class="ui segment active tab">
                            <div className="ques-table-outer" style={{
                                display: SubjectiveTestName != "" ? "block" : "none"
                                }}>
                                <div id={'tblQues'} striped  className="text-wrap ques-table" celled singleLine>
                                    <div className="generated-que-ans">
                                        {AllSubjective.length > 0 ? AllSubjective.map((row, i) => (
                                            <details open>
                                                <summary>
                                                    <span
                                                        style={{ fontSize: 14, }}
                                                        title={i + 1}
                                                        className="pr-2"
                                                    >
                                                        {i + 1}
                                                    </span>
                                                    <span
                                                        style={{ fontSize: 14, }}
                                                        className="text-wrap"
                                                        dataTitle="Question"
                                                    >
                                                        <Interweave content={row.Question} />
                                                        <div className="ans-options" id={"AnswerStatus_" + row.QId} style={{ display: (row.IsMcq == '1') ? 'block' : 'none' }}>
                                                        <input type='hidden' id={"IsMcq_" + row.QId} value={row.IsMcq}/>
                                                        <input type='hidden' id={"IsMulti_" + row.QId} value={row.multi}/>
                            <span className="option" style={{ display: (row.OptionA == '') ? 'none' : 'flex',color:(row.TagA==true)?'green':'none' }}>
                                <div className="ans-radio">
                                    <Checkbox id={'Chk1_' + row.QId} style={{ display: (row.multi == '0') ? 'none' : 'flex' }}
                                    checked={row.TagA} disabled/>
                                    <input type="radio" style={{
                                        display: (row.multi == '1') ? 'none' : 'flex'
                                        , height: 30
                                        , marginRight: 20, marginTop: 5, marginLeft: 12
                                    }} name={row.QId} value={1} checked={row.TagA} disabled
                                    />
                                </div>
                                <div className="ans-content">
                                    <div><Interweave content={row.OptionA} /></div>
                                </div>
                            </span>
                            <span className="option" style={{ display: (row.OptionB == '') ? 'none' : 'flex',color:(row.TagB==true)?'green':'none'  }}>
                                <div className="ans-radio">
                                    <Checkbox id={'Chk2_' + row.QId} style={{ display: (row.multi == '0') ? 'none' : 'flex' }}
                                    checked={row.TagB} disabled
                                    />
                                    <input type="radio" style={{
                                        display: (row.multi == '1') ? 'none' : 'flex'
                                        , height: 30
                                        , marginRight: 20, marginTop: 5, marginLeft: 12
                                    }} name={row.QId} value={2} checked={row.TagB} disabled
                                    />
                                </div>
                                <div className="ans-content">
                                    <div><Interweave content={row.OptionB} /></div>
                                </div>
                            </span>
                            <span className="option" style={{ display: (row.OptionC == '') ? 'none' : 'flex',color:(row.TagC==true)?'green':'none' }}>
                                <div className="ans-radio">
                                    <Checkbox id={'Chk3_' + row.QId} style={{ display: (row.multi == '0') ? 'none' : 'flex' }}
                                    checked={row.TagC} disabled
                                    />
                                    <input type="radio" style={{
                                        display: (row.multi == '1') ? 'none' : 'flex'
                                        , height: 30
                                        , marginRight: 20, marginTop: 5, marginLeft: 12
                                    }} name={row.QId} value={3} checked={row.TagC} disabled
                                    />
                                </div>
                                <div className="ans-content">
                                    <div><Interweave content={row.OptionC} /></div>
                                </div>
                            </span>
                            <span className="option" style={{ display: (row.OptionD == '') ? 'none' : 'flex',color:(row.TagD==true)?'green':'none' }}>
                                <div className="ans-radio">
                                    <Checkbox id={'Chk4_' + row.QId} style={{ display: (row.multi == '0') ? 'none' : 'flex' }}
                                    checked={row.TagD} disabled
                                    />
                                    <input type="radio" style={{
                                        display: (row.multi == '1') ? 'none' : 'flex'
                                        , height: 30
                                        , marginRight: 20, marginTop: 5, marginLeft: 12
                                    }} name={row.QId} value={4} checked={row.TagD} disabled
                                    />
                                </div>
                                <div className="ans-content">
                                    <div><Interweave content={row.OptionD} /></div>
                                </div>
                            </span>
                            {/* <div className="text-center p-3"><a className="btn-outline-primary btn text-primary p-2" onClick={(e) => ShowCorrectAnswer(obj.QuesId)}>Explanation</a></div> */}
                            {/* {
                                (obj.multi == '1') ? <div className="text-center p-3"><a className="badge btn-outline-primary btn text-primary p-2" onClick={(e) => ShowCorrectAnswer(obj.QuesId)}>Done</a></div> : ''
                            } */}
                        </div>
                                                    </span>
                                                    <span
                                                        style={{ fontSize: 14, }}
                                                        title="Edit"
                                                        className="pr-2"
                                                    >
                                                        <a className='btn-sm btn-info text-light' onClick={() => EditQuestion(row.Question,row.OptionA,row.OptionB,row.OptionC,row.OptionD,row.TagA,row.TagB,row.TagC,row.TagD,row.IsMcq, row.Id)}><i className='fa fa-edit'></i> Edit </a>
                                                    </span>
                                                </summary>
                                                <div
                                                    className="p-0"
                                                    style={{ fontSize: 14, }}
                                                    dataTitle="Attempted By"
                                                >
                                                    {
                                                        row.Participant != null ?
                                                            row.Participant.split('__,').map((data, i) =>
                                                                <div className="attempt-row">
                                                                    <div className={"attempt-outer " + StatusCSSClassName(data.split('@-')[1].split('$QLive*')[0])}>
                                                                        <div className={"attampt-ans text-wrap " + StatusCSSClassName(data.split('@-')[1].split('$QLive*')[0])}>
                                                                            <div className="w-100" style={{wordWrap:'break-word'}}>
                                                                                <div className="student-id">
                                                                                    {data.split('@QLive#$')[0]}
                                                                                </div>
                                                                            </div>
                                                                            <div className="w-100">
                                                                                <div className="student-ans">
                                                                                    <div className="scroll-ans" >
                                                                                        {<Interweave content={data.split('@QLive#$')[1].split('$QLive#@')[0]} />}
                                                                                    </div>
                                                                                    <div className='ChatGPTAns' >{GetChatGPTSection(data.split('#^GPTFlag$_#')[1], data.split('@-')[1].split('$QLive*')[0])}</div>
                                                                                    {getFeedbackText(data.split('$QLive*')[1].split('#^$_#')[0], data.split('@-')[1].split('$QLive*')[0], data.split('$QLive*')[1].split('#^$_#')[1].split('#^GPTFlag$_#')[0],row.IsMcq)}
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            )
                                                        : <div className="p-3">Not Attempted Yet <a className='ml-2 badge btn-danger text-light' onClick={() => RemoveLinkedQuestion(row.Id)}>Delete </a></div>
                                                    }
                                                </div>
                                            </details>
                                        ))
                                            : <div>
                                                <div className="text-center p-5" colSpan={4}>No Question Added for this Test. Please add some Question</div>
                                            </div>
                                        }
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    
                </div>
                <div style={{
                    display: IsTestFinished ? 'flex' : 'none',
                    flexDirection: 'column',
                    backgroundColor: '#fafafa', width: '50vw',
                    alignItems: 'center'
                }}>
                    <span style={{ color: '#35b58e', fontWeight: 'bolder', fontSize: 35, cursor: 'pointer', paddingTop: 250, textAlign: 'center' }} > Test Successfully Finished.!!!</span>
                    <span> Please refresh the page for create new Test</span>
                </div>
                <ToastContainer
                position="top-right"
                autoClose={5000}
                hideProgressBar={false}
                newestOnTop
                closeOnClick
                rtl={false}
                pauseOnFocusLoss
                draggable
                pauseOnHover
            />
            </>
        )
    }

    const RendorMainContent = () => {
        return (
            <>
                <div id='divSubjective'>{Subjective()}</div>
                <span style={{ display: main_loader ? 'block' : 'none', position: 'relative', left: 500, justifyContent: 'center', alignItems: 'center' }}>
                    <CircularProgress color="secondary" />
                </span>
            </>
        )
    }

    return (
        <div style={{display: IsValidLogin ? "flex" : 'none'}}>
            <div id="getSweetModel"></div>
            <AppBarMenu BoxContent={RendorMainContent()} HeaderText="QUBITS LIVE" MiddleText="Test more. Learn more !!!" WelcomeText={localStorage.getItem("QLuser_name")} IsNotification={IsDiscussionNeeded} DiscussionDetail={FetchDiscussionDetail}></AppBarMenu>
        </div>
    );
}

export default QubitsliveS