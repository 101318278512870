import React, { useEffect } from 'react'
import ReactDOM from 'react-dom';
import { confirmAlert } from 'react-confirm-alert'; // Import
import 'react-confirm-alert/src/react-confirm-alert.css'; // Import css
import ReactDOMServer from 'react-dom/server';
import RatingComponant from './RatingComponant';
import PieChart from './PieChart';
import Explanation from './Explanation';
import { Interweave } from 'interweave';

const axios = require('axios');

const ResultArea = props => {
    const [FBlabelText, SetFBlabelText] = React.useState('')

    useEffect(() => {
        RenderResultAreaContent()
    }, [])

    const RenderResultAreaContent = () => {
        let c_id = props.CId
        let paper_set = props.PS
        let clie_id = props.ClientId
        localStorage.setItem("letClientEmail", props.ClientId)
        axios.get('/Nishant/Qubits/GetResultData?cid=' + c_id + '&paperset=' + paper_set + '&clientId=' + clie_id).then(function (response) {
            let detail = response.data
            axios.get('/Nishant/Qubits/GetResultDataDetail?cid=' + c_id + '&paperset=' + paper_set + '&clientId=' + clie_id).then(function (response) {
                let AD = response.data
                let DistScenario = (GetDistinctSCId(AD))
                ReactDOM.render(RenderResult(detail[0]), document.getElementById('divresult'));
                ReactDOM.render(QuestionDetail(AD, DistScenario), document.getElementById('QD'))
                download_result(detail[0])
            }).catch(function (error) {
                alert(error);
            })
        }).catch(function (error) {
            alert(error);
        })
    }

    const GetDistinctSCId = (obj) => {
        var lookup = {};
        var items = obj;
        var result = [];
        for (var item, i = 0; item = items[i++];) {
            var name = item.ScenarioId;
            if (!(name in lookup)) {
                lookup[name] = 1;
                var d = {
                    ScenarioId: name,
                    Heading: item.SCQuestion,
                    ImagePath: item.SCImage
                }
                result.push(d);
            }
        }
        return result
    }

    const QuestionDetail = (AD, DistScenario) => {
        return (
            <>
                {DistScenario.map((d) => {
                    return <> {d.ScenarioId == 0 ? <>{NormalResultSection(d, AD)}</>
                        : <>{GetScenarioResult(d, AD)}</>
                    }
                    </>
                })}
            </>
        )
    }

    const NormalResultSection = (d, AD) => {
        return (
            AD.filter(f => f.ScenarioId == d.ScenarioId).map((row, i) => <div className='col-md-12'>{Result(row, i)}</div>)
        )
    }

    const GetScenarioResult = (d, AD) => {
        return (
            <div className='card mb-2'>
                <div className='card-header bg-info' style={{ color: 'white' }}>
                    {d.Heading}
                    <span>{d.ImagePath != '' ? <><img className="mt-3" src={"https://rms.koenig-solutions.com" + d.ImagePath}></img></> : ""}</span>
                </div>
                <div className='card-body'>
                    {
                        AD.filter(f => f.ScenarioId == d.ScenarioId).map((row, i) => <div className='col-md-12'>{Result(row, i)}</div>)
                    }
                </div>
            </div>
        )
    }

    const RenderResult = (ResultValue) => {
        var arrseries = []; var arrlabels = ['Not attempted', 'Correct', 'InCorrect']
        arrseries.push(parseInt(ResultValue.TotalQuestionAttempts - ResultValue.questionattemped))
        arrseries.push(parseInt(ResultValue.CorrectAnswer))
        arrseries.push(parseInt(ResultValue.IncorrectAnswer))
        return (
            <div className="container">
                <div className="row m-0">
                    <div className="col-md-12 text-center">
                        <h4 className="print-heading justify-content-center">
                            {/* <span className="go-back"><a href="/" className="btn pr-5 text-light text-uppercase"><b className="flip-x display-inline-block">&#10148;</b> Go Back</a></span> */}
                            <span className='text-uppercase mr-1'>{ResultValue.NameOfStudent != '' ? (ResultValue.NameOfStudent.split(' ')[0] + "'S") : ''}</span> TEST RESULT
                            <span style={{ position: 'absolute', right: '8px' }}>Test Taken On: {formatDate(ResultValue.Date)}</span>
                        </h4>
                    </div>
                    <div className="col-md-3 my-4">
                        <img src="/qb.png" alt="QUBITS" style={{ height: 180, width: 320 }} className="QubitImgDesktop" />
                    </div>
                    <div className="col-md-1 my-4"></div>
                    <div className="col-md-8 my-4">
                        <div className='row'>
                            <div className='col-md-10 pb-3'>
                                <div className='row'>
                                    <div className='col-md-4 text-right'>
                                        <b>Course </b>
                                    </div>
                                    <div className='col-md-8 p-0'>
                                        <a href={"https://www.koenig-solutions.com/" + ResultValue.CourseURL} style={{ color: 'blue' }}>{ResultValue.CName}</a>
                                        {/* {ResultValue.IsFlexi ? <a href={"https://www.koenig-solutions.com/flexi?Prmflexi=" + ResultValue.CName} className="badge alert-primary">Buy Flexi</a> : ""} */}
                                    </div>
                                </div>
                            </div>
                            <div className='col-md-2 pb-3'></div>
                            <div className='col-md-4'>
                                <div className='row pb-3'>
                                    <div className='col-md-10 text-right'>
                                        <b>Rank </b>
                                    </div>
                                    <div className='col-md-2 p-0'>
                                        {ResultValue.Rank}
                                    </div>
                                </div>
                                <div className='row pb-3'>
                                    <div className='col-md-10 text-right'>
                                        <b>Total Questions </b>
                                    </div>
                                    <div className='col-md-2 p-0'>
                                        {ResultValue.TotalQuestionAttempts}
                                    </div>
                                </div>
                                <div className='row pb-3'>
                                    <div className='col-md-10 text-right'>
                                        <b>Question Attempted </b>
                                    </div>
                                    <div className='col-md-2 p-0'>
                                        {ResultValue.questionattemped}
                                    </div>
                                </div>
                                <div className='row pb-3'>
                                    <div className='col-md-10 text-right'>
                                        <b>Correct Answer </b>
                                    </div>
                                    <div className='col-md-2 p-0'>
                                        {ResultValue.CorrectAnswer}
                                    </div>
                                </div>
                                <div className='row'>
                                    <div className='col-md-10 text-right'>
                                        <b>Incorrect Answer </b>
                                    </div>
                                    <div className='col-md-2 p-0'>
                                        {ResultValue.IncorrectAnswer}
                                    </div>
                                </div>
                            </div>
                            <div className='col-md-8'>
                                <PieChart series={arrseries} labels={arrlabels}></PieChart>
                            </div>
                        </div>
                        <div className='row'>
                            <div className='col-md-4'>
                                <input type='button' value='Provide Feedback for Qubits' className='btn btn-info form-control' onClick={() => OpenFeedbackForm(0, ResultValue)}></input>
                            </div>
                            <div className="col-md-3">
                                <a target="_blank" href='https://www.koenig-solutions.com/qubits-test' className='btn btn-info form-control'>Buy Now</a>
                            </div>
                            <div className="col-md-5">{props.isForLet && (
                                <span style={{ position: 'absolute', left: '60px', display: "flex", alignItems: "center" }} className="go-back">
                                    <a href="https://www.mykoenig.com/student/index" style={{ backgroundColor: "royalblue" }} className="btn btn-info textLetButton text-uppercase">
                                        <i class='fa fa-reply-all'></i> Back To LET
                                    </a>
                                </span>
                            )}</div>
                        </div>
                        <hr></hr>
                        <div className="row">
                            <div className="col-md-12"><span><b>Great job! Congratulations! You can forward the results to a friend.</b></span>
                                <br></br>
                                <div className='row'>
                                    <div className='col-md-8'>
                                        <input type='text' id='txtScoreEmail' placeholder='Enter email' className='form-control'></input>
                                    </div>
                                    <div className='col-md-4'>
                                        <input type='button' value='Send' className='btn btn-info form-control' onClick={() => SendEmailOfScore(ResultValue)}></input>
                                    </div>
                                    {/* <div className='col-md-4'>
                                         <input type='button' value='How can we improve Qubits' className='btn btn-info form-control' onClick={() => OpenFeedbackForm(0, ResultValue)}></input> 
                                    </div> */}
                                </div>
                            </div>
                            <div className="col-md-12 pt-2"><span><b id='spmsg' style={{ color: 'green' }}></b></span></div>
                            <div className="col-md-4">
                                {/* <span>
                                    <a className="text-primary">How would you rate Qubits42?</a>
                                    <div className='row'>
                                        <div className='col-md-6'>
                                            <RatingComponant RValue={Rating} data={ResultValue}></RatingComponant>
                                        </div>
                                        <div className='col-md-6 mt-3'>
                                            <strong className='mr-5' id="divRatingContent"></strong>
                                        </div>
                                    </div>
                                </span> */}
                                {/* {ResultValue.NextModule > 0 ? <span className='btn btn-sm btn-outline-info ml-2'><a className="text-primary" onClick={() => CreateNewTest(ResultValue.NextModule)}>Start New Test</a></span> : ""} */}
                            </div>
                        </div>
                    </div>
                </div>
                <div className="row m-0" id='QD'></div>
            </div>
        )
    }

    const SendEmailOfScore = (RV) => {
        axios.get('/Nishant/Qubits/GetResultDataDetail?type=1&cid=' + props.CId + '&paperset=' + props.PS + '&clientId=' + props.ClientId).then(function (response) {
            var HTML = ReactDOMServer.renderToStaticMarkup(ReturnHtmlOfSharingResult(RV, response.data));
            //var HTML = 'Hi <br/><br/> Paperset: ' + props.PS + ' <br/> Client Name: ' +  RV.StudentName + '<br/> Course: ' + RV.CName + '<br /> <span>URL: </span><span>' + window.location.href + ' </span> ' + ' <br/> Feedback: ' + text;
            var SendTo = document.getElementById('txtScoreEmail').value;
            props.SendMail('qubits@koenig-solutions.com', SendTo, localStorage.getItem("cordinator_email"), '', 'Qubits results of ' + RV.StudentName, HTML);
            alert("Thank you for sharing");
        })
    }

    const ReturnHtmlOfSharingResult = (RV, ResultValue) => {
        return (
            <>
                <div style={{
                    height: "90vh",
                    flexDirection: 'column',
                    backgroundColor: '#fff',
                    overflow: 'scroll'
                }}>
                    <div style={{ margin: 40, flexDirection: 'row' }}>
                        <div><h4> Hi,</h4></div>
                        <br></br>
                        <div>Your friend {RV.StudentName}, wants to share their test results with you.</div>
                        <br></br>
                        <div style={{ textAlign: 'center' }}><h4> TEST RESULT </h4></div>
                        <table style={{ fontFamily: 'Roboto' }}>
                            <tr>
                                <td style={{ padding: 5, textAlign: 'end' }}><b>Course Name:</b></td>
                                <td style={{ padding: 5, textAlign: 'start' }}>{RV.CName}</td>
                            </tr>
                            {
                                RV.ModuleName != '' ? <>
                                    <tr>
                                        <td style={{ padding: 5, textAlign: 'end' }}><b>Module:</b></td>
                                        <td style={{ padding: 5, textAlign: 'start' }}>{RV.ModuleName}</td>
                                    </tr>
                                </> : <></>
                            }
                            <tr>
                                <td style={{ padding: 5, textAlign: 'end' }}><b>Test Taken On:</b></td>
                                <td style={{ padding: 5, textAlign: 'start' }}>{formatDate(RV.Date)}</td>
                            </tr>
                            <tr>
                                <td style={{ padding: 5, textAlign: 'end' }}><b>Question Attempted:</b></td>
                                <td style={{ padding: 5, textAlign: 'start' }}>
                                    {RV.questionattemped} / {RV.TotalQuestionAttempts}
                                </td>
                            </tr>
                            <tr>
                                <td style={{ padding: 5, textAlign: 'end' }}><b>Correct Answer:</b></td>
                                <td style={{ padding: 5, textAlign: 'start' }}>
                                    {RV.CorrectAnswer} / {RV.TotalQuestionAttempts}
                                </td>
                            </tr>
                            <tr>
                                <td style={{ padding: 5, textAlign: 'end' }}><b>Total Number of questions attempted for this course:</b></td>
                                <td style={{ padding: 5, textAlign: 'start' }}>{RV.TotalAttemptedAnswerForTheCourse} / {RV.TotalQuestionForTheCourse}</td>
                            </tr>
                            <tr>
                                <td style={{ padding: 5, textAlign: 'end' }}><b>Total Number of Correct Answer for this course:</b></td>
                                <td style={{ padding: 5, textAlign: 'start' }}>{RV.TotalCorrectAnswerForTheCourse} / {RV.TotalQuestionForTheCourse}</td>
                            </tr>
                        </table>
                        <br></br>
                        <br></br>
                        <span>Regards,</span>
                        <br></br>
                        <span>Koenig Solutions</span>
                    </div>
                </div>
            </>
        )
    }

    const Rating = (d, RV) => {
        // if(d <= 3) OpenFeedbackForm(d, RV)
        // else alert('Thanks for your rating.')
        GetRatingContent(d);
        OpenFeedbackForm(d, RV)
    }

    const GetRatingContent = (d) => {
        var str = "", CN = "";
        switch (d) {
            case 1: str = "Very sad"; CN = "text-danger"; break;
            case 2: str = "Sad"; CN = "text-danger"; break;
            case 3: str = "Satisfied"; CN = "text-warning"; break;
            case 4: str = "Happy"; CN = "text-success"; break;
            case 5: str = "Very happy"; CN = "text-success"; break;
        }
        document.getElementById('divRatingContent').innerText = str;
        document.getElementById('divRatingContent').className = '';
        document.getElementById('divRatingContent').className = CN;
    }

    const CreateNewTest = (ModuleId) => {
        var obj = {
            CourseId: props.CId,
            ModuleId: ModuleId,
            PaperType: 'daily',
            NoOfQuestion: 10
        };
        axios.post('/Nishant/Qubits/GenerateTest', obj).then(function (response) {
            let paper_set = parseInt(response.data[0].PaperSet);
            if (!isNaN(paper_set)) window.location.href = window.location.origin + "/MyTest/" + props.CId + "/0/" + props.ClientId + '/' + paper_set;
        })
    }

    const OpenFeedbackForm = (d, RV) => ReactDOM.render(OpenPopUp(d, RV), document.getElementById('divPopUp'))
    const ClosePopup = () => ReactDOM.render(<></>, document.getElementById('divPopUp'))

    const OpenPopUp = (d, RV) => {
        confirmAlert({
            closeOnClickOutside: false,
            customUI: ({ onClose }) => {
                return (
                    <div className='custom-ui'>
                        <div className="row">
                            <div className="col-md-11">
                                <h2>Your feedback for Qubits</h2>
                            </div>
                            <div className="col-md-1 mb-4 text-right">
                                <span onClick={() => { onClose(); }}><i className="fa fa-close"></i></span>
                            </div>
                            <div className="col-md-12">
                                <textarea rows="3" id='txtFeedbackRemarks' placeholder="Please type here..." className="form-control"></textarea>
                                <label style={{ 'color': 'red' }}>{FBlabelText}</label>
                            </div>
                        </div>
                        <button onClick={() => { SubmitFeedbackForm(d, RV); onClose(); }}>Submit Feedback</button>
                    </div>
                );
            }
        });
    }

    const SubmitFeedbackForm = (d, RV) => {
        var FBText = document.getElementById('txtFeedbackRemarks').value
        if (FBText.length == 0) {
            SetFBlabelText('Please Enter Some Text');
        }
        else {
            saveEmailData(FBText)
            SendFB(FBText, d, RV);
            ClosePopup();
        }
    }

    async function saveEmailData(Feedback) {
        await axios.post("/Nishant/Qubits/SaveFeedBackDetail?AssignmentId=0" + "&Paperset=" + props.PS + "&Cid=" + props.CId + "&ClientId=" + props.ClientId + "&FeedBack=" + Feedback + "&FeedBackType=2").then(function (response) {

        }).catch(function (error) {
            alert(error);
        });
    }

    const SendFB = (text, d, RV) => {
        var SendTo = 'editor@qubits42.com; Qubits@koenig-solutions.com'
        //var SendTo = 'nishant.sinha@koenig-solutions.com'
        // var HTML = 'Hi <br/><br/><span>Rating:' + d == null ? 3 : d + '</span><br/><br/> Paperset: ' + props.PS + ' <br/> Client Name: ' +  RV.StudentName + '<br/> Course: ' + RV.CName + '<br /> <span>URL: </span><span>' + window.location.href + ' </span> ' + ' <br/> Feedback: ' + text;
        var HTML = 'Hi <br/><br/> Paperset: ' + props.PS + ' <br/> Client Name: ' + RV.StudentName + '<br/> Course: ' + RV.CName + '<br /> <span>URL: </span><span>' + window.location.href + ' </span> ' + ' <br/> Feedback: ' + text;
        props.SendMail('Qubits@koenig-solutions.com', SendTo, localStorage.getItem("cordinator_email"), 'Vaibhav.Aggarwal@koenig-solutions.com', 'Feedback from Qubits Test taker', HTML);
        alert("Thank you for submit your Feedback.");
    }

    function formatDate(timestamp) {
        return timestamp
    }

    const download_result = (detail) => {
        axios.get('/Nishant/Qubits/GetResultDataDetail?type=1&cid=' + props.CId + '&paperset=' + props.PS + '&clientId=' + props.ClientId).then(function (response) {
            var QDetail = ReactDOMServer.renderToStaticMarkup(ReturnHtml(detail, response.data));
            var SendTo = detail.StudentEmail
            console.log(QDetail)
            props.SendMail('qubits@koenig-solutions.com', SendTo, '', 'ashish.kumar@koenig-solutions.com;', 'Qubits Test Result ', QDetail);
            document.getElementById('spmsg').innerHTML = 'Your test report has successfuly been sent to your email.';
        })
    }

    const ReturnHtml = (ResultValue, AD) => {
        console.log(AD)
        return (
            <>
                <div style={{
                    height: "90vh",
                    flexDirection: 'column',
                    backgroundColor: '#fff',
                    overflow: 'scroll',
                    textAlign: 'center'
                }}>
                    <div style={{ margin: 40, flexDirection: 'row' }}>
                        <div style={{ textAlign: 'center' }}><h4> TEST RESULT </h4></div>
                        <table style={{ fontFamily: 'Roboto' }}>
                            <tr>
                                <td style={{ padding: 5, textAlign: 'end' }}><b>Course Name</b></td>
                                <td style={{ padding: 5, textAlign: 'start' }}>{ResultValue.CName}</td>
                            </tr>
                            {
                                ResultValue.ModuleName != '' ? <>
                                    <tr>
                                        <td style={{ padding: 5, textAlign: 'end' }}><b>Module</b></td>
                                        <td style={{ padding: 5, textAlign: 'start' }}>{ResultValue.ModuleName}</td>
                                    </tr>
                                </> : <></>
                            }
                            <tr>
                                <td style={{ padding: 5, textAlign: 'end' }}><b>Test Taken On</b></td>
                                <td style={{ padding: 5, textAlign: 'start' }}>{formatDate(ResultValue.Date)}</td>
                            </tr>
                            <tr>
                                <td style={{ padding: 5, textAlign: 'end' }}><b>Question Attempted</b></td>
                                <td style={{ padding: 5, textAlign: 'start' }}>
                                    {ResultValue.questionattemped} / {ResultValue.TotalQuestionAttempts}
                                    <span style={{ marginLeft: "8%", marginRight: "8%" }}><a href={'https://www.qubits42.com/CreateTest/' + props.CId + '/20/0'}>Attempt New Test</a></span>
                                </td>
                            </tr>
                            <tr>
                                <td style={{ padding: 5, textAlign: 'end' }}><b>Correct Answer</b></td>
                                <td style={{ padding: 5, textAlign: 'start' }}>
                                    {ResultValue.CorrectAnswer} / {ResultValue.TotalQuestionAttempts}
                                </td>
                            </tr>
                            <tr>
                                <td style={{ padding: 5, textAlign: 'end' }}><b>Total Number of questions attempted for this course</b></td>
                                <td style={{ padding: 5, textAlign: 'start' }}>{ResultValue.TotalAttemptedAnswerForTheCourse} / {ResultValue.TotalQuestionForTheCourse}</td>
                            </tr>
                            <tr>
                                <td style={{ padding: 5, textAlign: 'end' }}><b>Total Number of Correct Answer for this course</b></td>
                                <td style={{ padding: 5, textAlign: 'start' }}>{ResultValue.TotalCorrectAnswerForTheCourse} / {ResultValue.TotalQuestionForTheCourse}</td>
                            </tr>
                        </table>
                        <div style={{ backgroundColor: '#fa3e74', height: 3, width: 300, textAlign: 'center', margin: 15 }}></div>

                        {AD.map((row) => (
                            <Mail_Result row={row} key={row.q_no}></Mail_Result>
                        ))}
                    </div>
                </div>
            </>
        )
    }

    const GetAnswerColor = (data, row) => {
        if (row.YourAttampted.split('__#&')[0].split(')')[0] === data.split('__%$')[0].split(')')[0]) return "text-danger"
    }

    const GetSelectedAnswer = (data, row) => {
        var arr = []
        row.YourAttampted.split('*$').map((d) => arr.push(d.split('__#&')[0].split(')')[0]))
        return arr.includes(data.split('__%$')[0].split(')')[0])
    }

    function Result(row, RN) {
        return (
            <div className="print-container">
                <span className="print-heading" style={{ display: 'flex', justifyContent: 'center', fontWeight: 'bold' }}>Question {row.RW}</span>
                <span>{row.ImagePath != '' ? <><img className="mt-3" src={"https://rms.koenig-solutions.com" + row.ImagePath}></img></> : ""}</span>
                <p className="print-questions" style={{ textAlign: 'center' }}><Interweave content={row.Question} /></p>
                {row.IsWordleQuestion == 1 ? <>
                    {row.AnswerStatus == "Correct" ? <span className="text-success px-3"> Your Answer:
                        <span className='mb-3'> {row.YourAttampted != '' ? row.YourAttampted : "Not Attempted"}</span>
                    </span> :
                        <>
                            {row.AnswerStatus != "0" ?
                                <span className="text-danger px-3"> Your Answer:
                                    <span className='mb-3'> {row.YourAttampted != '' ? row.YourAttampted : "Not Attempted"}</span>
                                </span>
                                : <></>
                            }
                            <span className="text-success pt-1 px-3"> Correct Answer:
                                <span className='mb-3'> {row.CorrectAnswer.split(')')[1].split('*$')[0] != '' ? row.CorrectAnswer.split(')')[1].split('*$')[0] : "Not Attempted"}</span>
                            </span>
                        </>
                    }
                </> : row.AllAnswer.split('__*$__').map((data, i) =>
                    <span className="option px-3 mt-2">
                        <div className="pb-2" style={{
                            justifySelf: 'center',
                            alignSelf: 'center'
                        }}>
                            {data != "" ?
                                <span className={data.split('__%$')[1].split('__#&')[1] == 1 ? "text-success" : GetAnswerColor(data, row)}>
                                    <span className='mr-3'>
                                        {data.split('__%$')[0]}
                                    </span>
                                    {data.split('__%$')[1].split('__#&')[0].split('__^%$&')[0]}
                                    {typeof data.split('__%$')[1].split('__#&')[0].split('__^%$&')[1] != 'undefined' ?
                                        <span>
                                            <img className="mt-3 MuiDialog-paper" src={"https://rms.koenig-solutions.com" + data.split('__%$')[1].split('__#&')[0].split('__^%$&')[1]}></img>
                                        </span> : ''}
                                    {row.Multi == 1 && GetSelectedAnswer(data, row) ? <span className='ml-2' title='you Selected'><i className='fa fa-check'></i></span> : <></>}
                                </span>
                                : <></>
                            }
                        </div>
                    </span>
                )
                }
                <div className="mb-3" style={{ flex: 1, display: 'flex', fontFamily: 'Roboto', justifyContent: 'center', fontWeight: 'bold' }}>
                    {row.AnswerStatus == "Correct" ?
                        <div style={{ float: 'right', marginLeft: 10 }}>
                            <img src="https://img.icons8.com/fluent/25/000000/checkmark.png" />
                        </div>
                        :
                        <div style={{ float: 'right', marginLeft: 10 }}>
                            {/* <img src="https://img.icons8.com/emoji/25/000000/cross-mark-emoji.png" />
                            {row.AnswerStatus == "0" ? <span className={"text-secondary px-3"}>Not Attempted</span> : ""}
                            {row.ChapterName != "" ?
                                <span className={"text-danger badge px-3"}> Please refer -
                                    <span>
                                        {' ' + row.ChapterName}
                                    </span>
                                </span> : ''
                            } */}
                        </div>
                    }
                </div>
                <Explanation clientid={props.ClientId} paperset={props.PS} QuesId={row.QuesId} SendMail={props.SendMail}></Explanation>
            </div>
        )
    }

    function Mail_Result({ row }) {
        return (
            <span style={{
                boxShadow: "10px 10px 15px #aaaaaa", fontSize: 14, fontFamily: 'Roboto',
                padding: 40, backgroundColor: '#fff', display: 'flex', margin: 20, flexDirection: 'column', borderRadius: 5
            }}
            >
                <span style={{ display: 'flex', justifyContent: 'center', fontWeight: 'bold' }}>
                    Question {row.q_no}
                </span>
                <br></br>
                <p style={{ textAlign: 'left' }}>{row.Question}</p>
                {row.ImagePath != '' ? <><span><img className="mt-3" src={"https://rms.koenig-solutions.com" + row.ImagePath}></img></span><br></br></> : ""}
                <br></br>
                <p style={{ textAlign: 'left' }}>Correct Answer : {row.correct_answer.replace(/^,|,$/g, '')}</p>
                <br></br>
                {(row.IsAnswerCorrect != 1) ? <p style={{ textAlign: 'left' }}>Your Answer : {row.input_answer.replace(/^,|,$/g, '')}</p> : <></>}
                <br></br>
                <p style={{ textAlign: 'left' }}>Explaination : {row.ExplainationAct}</p>
                <br></br>
                <div style={{ flex: 1, display: 'flex', fontFamily: 'Roboto', justifyContent: 'center', fontWeight: 'bold' }}>
                    {(row.IsAnswerCorrect == 1) ?
                        <div style={{ display: 'flex', float: 'right', marginLeft: 10 }}>
                            <span style={{ display: 'flex', justifyContent: 'center', fontWeight: 'bold' }}>Your answer was correct</span>
                        </div> : <div style={{ display: 'flex', float: 'right', marginLeft: 10 }}>
                            <span style={{ display: 'flex', justifyContent: 'center', fontWeight: 'bold' }}>Your answer was incorrect</span>
                        </div>
                    }
                </div>
            </span>
        )
    }

    return (
        <>
            <div id='divPopUp'></div>
            <div id='divresult'></div>
        </>
    )
}

export default ResultArea