import React from 'react'
import { Container, Input, Image } from 'semantic-ui-react'
import './HomepageStyle.css';
import { Icon, Button, Checkbox, Form } from 'semantic-ui-react'
import { useMediaQuery } from 'react-responsive'
import './Home1.css'
import Navbar from './Navbar';
import 'bootstrap/dist/css/bootstrap.min.css';
import $ from 'jquery'
const axios = require('axios');

const Login = (props) => {

    const [loading, set_loading] = React.useState(false)
    const [name, set_name] = React.useState('')
    const [email, set_email] = React.useState('')
    const [query, set_query] = React.useState('')
    const Desktop = ({ children }) => {
        const isDesktop = useMediaQuery({ minWidth: 992 })
        return isDesktop ? children : null
    }

    const Mobile = ({ children }) => {
        const isMobile = useMediaQuery({ maxWidth: 992 })
        return isMobile ? children : null
    }

    function validateEmail(email) {
        const re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        return re.test(String(email).toLowerCase());
    }

    function submit() {

        if (validateEmail(email) == false) {
            alert("Please enter a valid Email")
            return
        }

        //insert_qubit_contact_us
        if (name == '' || email == '' || query == '') {
            alert("Please fill all the fields")
            return
        }

        axios.get('api/qubits/insert_qubit_contact_us?name=' + name + '&email=' + email + '&query=' + query + '')
            .then(function (response) {
                set_name("")
                set_email("")
                set_query("")

                alert(response.data)


            })
            .catch(function (error) {
                alert(error);

            })
    }


    return (

        <div className='contact-nav-im'>
            {Desktop && <>
                <div>

                <Navbar/>

                  <div className='row justify-content-center'>
                      <div className='col-md-10'>
                      <div className="contactForm">

<Form>
    <Form.Field>
        <h1>CONTACT US</h1>
    </Form.Field>
    <Form.Field>
        <input value={name}
            onChange={(t) => {
                set_name(t.target.value)
            }}
            style={{ width: '100%' }} placeholder='Name' />
    </Form.Field>
    <Form.Field>
        <input
            value={email}
            onChange={(t) => {
                set_email(t.target.value)
            }}
            style={{ width: '100%' }} placeholder='Email' />
    </Form.Field>

    <Form.TextArea
        value={query}
        onChange={(t) => {
            set_query(t.target.value)
        }}
        style={{ width: '100%' }} placeholder='Your Query...' />


    <Button
        onClick={() => {
            submit()
        }}
        style={{ color: '#236380', backgroundColor: '#fff' }} type='submit'>Submit</Button>
</Form>
</div>

<div className="address-section">
<div className="contact-content-map">
    <div className="embed-responsive embed-responsive-21by9">
        <iframe className="embed-responsive-item" src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d28006.552465881534!2d77.149687!3d28.665136!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x0%3A0x4917d059de0b7d88!2sKoenig%20Solutions%20Pvt.%20Ltd.!5e0!3m2!1sen!2sin!4v1645506603881!5m2!1sen!2sin" width="100%" height="100%" allowfullscreen="" loading="lazy"></iframe>
    </div>
</div>
<div className="contact-content text-center">
    <h4>Delhi</h4>
    <div className="content">
        <address>
            <strong>
            Rayontara Solutions
            </strong>
            <span> DSM-640-641, 6th Floor,</span>
            <span>DLF Tower, Shivaji Marg,</span><br />
            <span> Moti Nagar,</span>
            <span>New Delhi-110015(India)</span><br />
            <span>Call :-&nbsp; +91 80950 73333 (24x7)</span>
        </address>
    </div>
</div>
{/* <div className="contact-content">
    <h4>Bangalore (Bengaluru)</h4>
    <div className="content">
        <address>
            <a rel="nofollow" href="https://www.google.co.in/maps/place/Koenig+Campus+Bangalore/@12.931512,77.630034,15z/data=!4m2!3m1!1s0x0:0x1deff863cff176c4" target="_blank" className="map_icon">
                <i className="fa fa-map-marker" />
            </a>
            <strong>Koenig Solutions Pvt. Ltd.</strong>
            <span>2nd &amp; 3rd Floor, No. 39, 8th Main</span>
            <span>Koramangala 4th Block, Bengaluru-560034, (India)</span>

        </address>
    </div>
</div>
<div className="contact-content">
    <h4>Chennai</h4>
    <div className="content">
        <address>
            <a rel="nofollow" href="https://www.google.com/maps/place/Koenig+Solutions+Pvt.+Ltd./@13.0138572,80.1990217,17z/data=!3m1!4b1!4m5!3m4!1s0x0:0x6c08ef5563208e37!8m2!3d13.0138572!4d80.2012104?hl=en" target="_blank" className="map_icon">
                <i className="fa fa-map-marker" />
            </a>
            <strong>Koenig Solutions Pvt. Ltd.</strong>
            <span>5th Floor, Olympia Teknos</span>
            <span>Plot No. 28, South Phase,</span>
            <span>Sidco Industrial Estate, Guindy</span>
            <span>Chennai-600032, Tamil Nadu (India)</span>

        </address>
    </div>
</div>
<div className="contact-content">
    <h4>Goa</h4>
    <div className="content">
        <address>
            <a rel="nofollow" href="https://www.google.co.in/maps/place/Koenig+Solutions+Pvt.+Ltd./@15.493561,73.8171273,17z/data=!3m1!4b1!4m5!3m4!1s0x3bbfc08d44deecf1:0x851ac902a3ec02ef!8m2!3d15.493561!4d73.819316" target="_blank" className="map_icon">
                <i className="fa fa-map-marker" />
            </a>
            <strong>Koenig Solutions Pvt. Ltd.</strong>
            <span>5th floor, Kamat Grand behind the caculo mall,</span>
            <span>St.Inez, Panjim, Goa-403001 (India)</span>
            <span>Contact Number +91 9910965544</span>
        </address>
    </div>
</div>
<div className="contact-content">
    <h4>Dubai</h4>
    <div className="content">
        <address>
            <a rel="nofollow" href="https://www.google.co.in/maps/place/Koenig+Solutions+Ltd/@25.1045793,55.1624232,17z/data=!3m1!4b1!4m5!3m4!1s0x3e5f6b6b50449419:0x7377736eb7145766!8m2!3d25.1045793!4d55.1646119" target="_blank" className="map_icon">
                <i className="fa fa-map-marker" />
            </a>
            <strong>Koenig Solutions FZ LLC</strong>
            <span>Dubai Knowledge Park (DKP) </span>
            <span>G-16, Block – 2B, Al Bourooj St</span>
            <span>Dubai - United Arab Emirates</span>
            <span>P.O Box # 503220</span>
            <span>Contact Abdul +971 505024568</span>
        </address>
    </div>
</div>
<div className="contact-content">
    <h4>Singapore</h4>
    <div className="content">
        <address>
            <a rel="nofollow" href="javascript:void(0)" target="_blank" className="map_icon">
                <i className="fa fa-map-marker" />
            </a>
            <strong>Koenig Solutions Ltd.</strong>
            <span>30 CECIL STREET #19-08 PRUDENTIAL TOWER </span>
            <span>SINGAPORE 049712</span>
        </address>
    </div>
</div>
<div className="contact-content">
    <h4>UK</h4>
    <div className="content">
        <address>
            <a rel="nofollow" href="https://goo.gl/maps/ZsjfxAhmFNUY7QfB6" target="_blank" className="map_icon">
                <i className="fa fa-map-marker" />
            </a>

            <strong>Koenig Solutions Limited</strong>
            <span>60, Devonshire House,</span>
            <span>Goswell Road,</span>
            <span>London, United Kingdom - EC1M 7AD</span>
            <span>Contact Piyush +44 7723 051585</span>




        </address>
    </div>
</div>
<div className="contact-content">
    <h4>Netherlands</h4>
    <div className="content">
        <address>
            <a rel="nofollow" href="javascript:void(0)" target="_blank" className="map_icon">
                <i className="fa fa-map-marker" />
            </a>
            <strong>Koenig Solutions B.V.</strong>
            <span>Wilhelmina van Pruisenweg 35,</span>
            <span>2595AN 's-Gravenhage</span>
        </address>
    </div>
</div>
<div className="contact-content">
    <h4>USA</h4>
    <div className="content">
        <address>
            <a rel="nofollow" href="https://goo.gl/maps/QkCSjfeMXeyXcCU48" target="_blank" className="map_icon">
                <i className="fa fa-map-marker" />
            </a>
            <span><strong>Koenig Solutions Ltd.</strong></span>
            <span>100 Ring Road Suite 300 (PH) Garden City,</span>
            <span>NY 11530,</span>
            <span>United States of America</span>
            <span>Contact# +1 404-220-7461  </span>

        </address>
    </div>
</div>
<div className="contact-content">
    <h4>Canada</h4>
    <div className="content">
        <address>
            <a href="javaScript:void(0)" className="map_icon">
                <i className="fa fa-map-marker" />
            </a>


            <span><strong>Koenig Solutions Ltd.</strong></span>

            <span>#21-22810 113 Avenue</span>
            <span>Maple Ridge</span>
            <span>Canada, V2X3N2.</span>
            <span>Contact# +1 404-220-7461  </span>




        </address>
    </div>
</div>
<div className="contact-content">
    <h4>Australia</h4>
    <div className="content">
        <address>

            <span itemscope="" itemprop="hasMap" itemtype="http://schema.org/Map">


                <a rel="nofollow" href="https://goo.gl/maps/jX5WmuMjfUJrQQ7D6" target="_blank" className="map_icon">
                    <i className="fa fa-map-marker" />
                </a>
            </span>

            <strong>Koenig Solutions Pty Ltd</strong>
            <span> C/- ABN Australia</span>
            <span> Level 1, 8, Beulah Road,</span>
            <span> Norwood SA 5067.</span>
            <span> Contact Kunal +61 416190484</span>
            <span> Contact Chris +61 423059782</span>
        </address>
    </div>
</div>
<div className="contact-content">
    <h4>South Africa</h4>
    <div className="content">
        <address>
            <strong>Koenig Solutions Pvt. Ltd.</strong>
            <span>18 Lakeview Crescent,</span>
            <span>Kleinfontein Lake,</span>
            <span>Benoni,</span>
            <span>Gauteng 1501,</span>
            <span>Regn No: 2021 / 804064 / 07</span>
            <span>Contact# +27 767350074</span>

        </address>
    </div>
</div>
 */}

</div>
                      </div>
                  </div>






                </div>
            </>}

            {/* for nishant remove mobile content from here */}
            {Mobile && <>
                <div style={{
                    display: 'flex',
                    flexDirection: 'column',
                    backgroundColor: '#fff',
                    width: '100vw',
                    display: 'none',
                    //paddingLeft: 130, paddingRight: 130,
                    height: 'calc(100vh - 130px)'
                }}>
                    {/* HEADER */}
                    <div style={{
                        height: 'calc(100vh - 130px)', width: '100vw'

                    }}>


                        <div className="rightfix">

                            <span
                                onClick={() => {
                                    window.location.href = window.location.origin

                                }}
                                style={{ cursor: 'pointer', fontSize: 18, fontWeight: 'bolder' }}>Home</span>

                            <span
                                onClick={() => {
                                    if (localStorage.getItem("cl_id") > 0) {
                                        window.location.href = window.location.origin + '/student'
                                    }
                                    else {
                                        window.location.href = window.location.origin + '/dashboard'
                                    }
                                }}
                                style={{ cursor: 'pointer', fontSize: 18, fontWeight: 'bolder' }}>Login</span>

                            {/* <Button onClick={() => { setOpen(true) }} color="teal" content='Add' icon='add' labelPosition='right' /> */}


                        </div>
                    </div>
                    <br></br>
                    <br></br>


                    {/* <div  className="contactForm">

                        <Form>
                            <Form.Field>
                                <h1>CONTACT US</h1>
                            </Form.Field>
                            <Form.Field>
                                <input value={name}
                                    onChange={(t) => {
                                        set_name(t.target.value)
                                    }}
                                    style={{ backgroundColor: '#cccccc', width: 200 }} placeholder='Your Name' />
                            </Form.Field>
                            <Form.Field>
                                <input
                                    value={email}
                                    onChange={(t) => {
                                        set_email(t.target.value)
                                    }}
                                    style={{ backgroundColor: '#cccccc', width: 200 }} placeholder='Your Email ID' />
                            </Form.Field>

                            <Form.TextArea
                                value={query}
                                onChange={(t) => {
                                    set_query(t.target.value)
                                }}
                                style={{ backgroundColor: '#cccccc', width: 200 }} placeholder='Your Message' />


                            <Button
                                onClick={() => {
                                    submit()
                                }}
                                style={{  backgroundColor: '#236380', color: '#fff' }} type='submit'>Submit</Button>
                        </Form>
                    </div>
                    {/* 

            <div >

                <img style={{ position: 'fixed', right: 0, height: 600, width: 800 }}
                    src="/contact_img.png" alt="QUBITS"

                />

            </div> */}


                </div>
            </>}
            {/* for nishant remove mobile content to here */}

        </div>

    )
}



export default Login


// import React from 'react'
// import { Container, Input, Button, Image } from 'semantic-ui-react'
// const axios = require('axios');

// const Login = (props) => {

//     const [loading, set_loading] = React.useState(false)



//     return (
//         <div style={{
//             display: 'flex',
//             flexDirection: 'column',
//             backgroundColor: '#fff',
//             width: '100vw',
//             //paddingLeft: 130, paddingRight: 130,
//             height: '100vh'
//         }}>
//             {/* HEADER */}
//             <div style={{
//                 height: '10vh', backgroundColor: '#fafafb', width: '100vw',
//                 WebkitBoxShadow: "0 8px 6px -6px #d5d4d6",
//                 MozBoxShadow: "0 8px 6px -6px #d5d4d6",
//                 boxShadow: "0 8px 6px -6px #d5d4d6"
//             }}>

//                 <Image
//                     //size='tiny'
//                     style={{ height: 80, width: 80, position: 'absolute', top: -10, left: 30 }}
//                     src={require('./image/ko.png')} />

//                 <div style={{ position: 'absolute', right: 40, top: 22 }}>

//                     <span style={{ fontSize: 18, fontWeight: 'bolder', opacity: 0.7 }}>About Us</span>
//                     {'\u00A0'} {'\u00A0'} {'\u00A0'}  {'\u00A0'} {'\u00A0'}
//                     <span style={{ fontSize: 18, fontWeight: 'bolder', opacity: 0.7 }}>Login</span>

//                     {/* <Button onClick={() => { setOpen(true) }} color="teal" content='Add' icon='add' labelPosition='right' /> */}


//                 </div>
//             </div>
// <br></br>
// <br></br>

//             <div className="">
//                             <center>
//                             <img
//                                 //src="/kkk.png" alt="QUBITS"
//                                 style={{ height: 120, width: 380 }}
//                                 src="http://qubits.training/Images/logo/qubit-logo-3.jpg"
//                                 className="QubitImgDesktop"
//                             />
//                             </center>
//                             <div className="text-white text-center pt-2" style={{ fontSize: "larger" }}> Question Bank for IT </div>
//                         </div>˝
//             {/*               
//                 <Image
//                     //size='tiny'
//                     style={{ alignSelf: 'center', height: 100, width: 100 }}
//                     src={require('./image/ko.png')} /> */}



//         </div>
//     )
// }



// export default Login