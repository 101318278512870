import React,{useState} from 'react';
//import { Button } from '@material-ui/core';
import { useParams } from 'react-router-dom';
import ReactDOM from 'react-dom';

import Countdown, {zeroPad} from "react-countdown";
import Dialog from '@material-ui/core/Dialog';
import Alert from '@material-ui/lab/Alert';
import { Button } from 'semantic-ui-react'
import SubmitSound from '../src/Check.mp3'
import Question from './Nishant/Reusable/Question';
import './random_test.css'
import { confirmAlert } from 'react-confirm-alert'; // Import
import 'react-confirm-alert/src/react-confirm-alert.css'; // Import css
import { CalculateTotalMinuteWithText } from './Nishant/Reusable/Utility/CommonUtilityClass';
import CommonHelper from './Nishant/Reusable/Utility/CommonHelper';
import TestInstruction from './Nishant/Componant/PartialComponant/TestInstruction';
import ResultArea from './Nishant/Componant/PartialComponant/ResultArea';
import { CopyTimerButtonLog } from './Nishant/Reusable/Utility/CommonUtilityClass';
const axios = require('axios');


export default function My_tes() {
    const { CId } = useParams();
    const { TrainerId } = useParams();
    const { ClientId } = useParams();
    const { PS } = useParams(); 
    const [isTrackingInactivity, setIsTrackingInactivity] = useState(false);
    const [c_name, set_c_name] = React.useState("")
    const [isPopupShown,setisPopupShown ] = React.useState(false);
    const [RusultShowed, setRusultShowed] = React.useState(false)
    const [TimerStop, setTimerStop] = React.useState(false)
    const NoOfSecondForEachQuestion = 90;
    const NoOfSecondForWordle = 120;
    let LeftQuestion = 0, TotalNoOfWordle = 0;

    const handleInactivity = () => {
        //alert("User is inactive for 1 minute.");
        DisplayInactivityPopUp()
    };

    React.useEffect(() => {
        if (window.location.href.includes('%0D%20')) {
            window.location.href = window.location.href.replace('%0D%20', "")
        }
        else if (window.location.href.includes('%20')) {
            window.location.href = window.location.href.replace('%20', "")
        }
        else if (window.location.href.includes('%0D')) {
            window.location.href = window.location.href.replace('%0D', "")
        }

        const inactivityTimeout = 1000*60*2; // 1 minute

        // Initialize a timer variable
        let inactivityTimer;

        // Function to reset the timer
        const resetTimer = () => {
            clearTimeout(inactivityTimer); // Clear the previous timer
            if (isTrackingInactivity) {
                inactivityTimer = setTimeout(handleInactivity, inactivityTimeout); // Set a new timer
            }
        };

        // Add event listeners for user activity
        window.addEventListener("mousemove", resetTimer);
        window.addEventListener("keydown", resetTimer);
        window.addEventListener("scroll", resetTimer);

        // Initialize the timer when the component mounts
        resetTimer();

        if(!isPopupShown)GetTestInfo(CId, TrainerId, ClientId, PS);
        setisPopupShown(true);
        

        return () => {
            window.removeEventListener("mousemove", resetTimer);
            window.removeEventListener("keydown", resetTimer);
            window.removeEventListener("scroll", resetTimer);
            clearTimeout(inactivityTimer);
        };
    }, [isTrackingInactivity])

    const startInactivityTracking = () => {
        //console.log('started')
        setIsTrackingInactivity(true);
        // ... other logic to start the test
    };

    // Function to pause inactivity tracking
    const pauseInactivityTracking = () => {
        //console.log('paused')
        setIsTrackingInactivity(false);
        // ... other logic to pause the test
    };

    const GetTestInfo = (CId, TId, ClId, PaperSetId) => {
        axios.get('/Nishant/Qubits/GetQubitsTestInfo?c_id=' + CId + '&clientid=' + ClId + '&paperset=' + PaperSetId + '&trainerid=' + TId)
        .then(function (response) {
            let info = response.data
            localStorage.setItem("cordinator_email", info[0].StudentEmail)
            localStorage.setItem("user_name", info[0].student_name)
            set_c_name(info[0].c_name)
            LeftQuestion = info[0].left_q
            TotalNoOfWordle = info[0].TotalNoOfWordle
            RenderInfoPopUp(info[0]);
        })
        .catch(function (error) {
            alert(error);
        })
    }

    const RenderInfoPopUp = (data) => {
        ReactDOM.render(RenderInfoPopUpContent(data), document.getElementById('ModelPopUp'))
    }

    const RenderInfoPopUpContent = (data) => {
        return(
            <Dialog maxWidth="xs" aria-labelledby="simple-dialog-title" open={true}>
                {/* <DialogTitle id="simple-dialog-title" style={{  flexDirection: 'row' }}>Test Info</DialogTitle> */}
                <Alert style={{ display: (data.attempted_q > 0 && data.total_q == data.attempted_q) ? 'flex' : 'none' }} severity="success">Test Completed</Alert>
                <div style={{ margin: 20 }}>
                    <div style={{ display: 'flex', flex: 1, maxWidth: 400, margin: '10px 0' }}>
                        <div style={{ flex: 2, maxWidth:'50%', minWidth:'50%', display: 'flex', fontFamily: 'Roboto', justifyContent: 'flex-end', fontWeight: 'bold', alignItems: 'flex-start', textAlign: 'right' }}> Date<span style={{padding: '0 5px'}}>:</span> </div>
                        <div style={{ flex: 2, maxWidth:'50%', minWidth:'50%', display: 'flex', fontFamily: 'Roboto', alignItems: 'start' }}>{data.TestDate}</div>
                    </div>
                    <div style={{ display: 'flex', flex: 1, maxWidth: 400, margin: '10px 0' }}>
                        <div style={{ flex: 2, maxWidth:'50%', minWidth:'50%', display: 'flex', fontFamily: 'Roboto', justifyContent: 'flex-end', fontWeight: 'bold', alignItems: 'flex-start', textAlign: 'right' }}> Course<span style={{padding: '0 5px'}}>:</span> </div>
                        <div style={{ flex: 2, maxWidth:'50%', minWidth:'50%', display: 'flex', fontFamily: 'Roboto', alignItems: 'start' }}><a target="_blank" href={"https://www.koenig-solutions.com/" + data.CourseURL}>{data.c_name}</a></div>
                    </div>
                    <div style={{ display: 'flex', flex: 1, maxWidth: 400, margin: '10px 0' }}>
                        <div style={{ flex: 2, maxWidth:'50%', minWidth:'50%', display: 'flex', fontFamily: 'Roboto', justifyContent: 'flex-end', fontWeight: 'bold', alignItems: 'flex-start', textAlign: 'right' }}> Student<span style={{padding: '0 5px'}}>:</span> </div>
                        <div style={{ flex: 2, maxWidth:'50%', minWidth:'50%', display: 'flex', fontFamily: 'Roboto', alignItems: 'start' }}>{data.student_name}</div>
                    </div>
                    <div style={{ display: 'flex', flex: 1, maxWidth: 400, margin: '10px 0' }}>
                        <div style={{ flex: 2, maxWidth:'50%', minWidth:'50%', display: 'flex', fontFamily: 'Roboto', justifyContent: 'flex-end', fontWeight: 'bold', alignItems: 'flex-start', textAlign: 'right' }}> Total Questions<span style={{padding: '0 5px'}}>:</span> </div>
                        <div style={{ flex: 2, maxWidth:'50%', minWidth:'50%', display: 'flex', fontFamily: 'Roboto', alignItems: 'start' }}>{data.total_q}</div>
                    </div>
                    <div style={{ display: (data.CorrectAnswer > 0) ? 'flex' : 'none', flex: 1, maxWidth: 400, margin: '10px 0' }}>
                        <div style={{ flex: 2, maxWidth:'50%', minWidth:'50%', display: 'flex', fontFamily: 'Roboto', justifyContent: 'flex-end', fontWeight: 'bold', alignItems: 'flex-start', textAlign: 'right' }}> Correct Answer<span style={{padding: '0 5px'}}>:</span> </div>
                        <div style={{ flex: 2, maxWidth:'50%', minWidth:'50%', display: 'flex', fontFamily: 'Roboto', alignItems: 'start' }}>{data.CorrectAnswer}</div>
                    </div>
                    <div style={{ display: (data.LifeTimeCorrectAnswer > 0) ? 'flex' : 'none', flex: 1, maxWidth: 400, margin: '10px 0' }}>
                        <div style={{ flex: 2, maxWidth:'50%', minWidth:'50%', display: 'flex', fontFamily: 'Roboto', justifyContent: 'flex-end', fontWeight: 'bold', alignItems: 'flex-start', textAlign: 'right' }}>Lifetime Correct Answer<span style={{padding: '0 5px'}}>:</span> </div>
                        <div style={{ flex: 2, maxWidth:'50%', minWidth:'50%', display: 'flex', fontFamily: 'Roboto', alignItems: 'start' }}>{data.LifeTimeCorrectAnswer}</div>
                    </div>
                    <div style={{ display: (data.attempted_q > 0 && data.total_q == data.attempted_q) ? 'none' : 'flex', flex: 1, maxWidth: 400, margin: '10px 0' }}>
                        <div style={{ flex: 2, maxWidth:'50%', minWidth:'50%', display: 'flex', fontFamily: 'Roboto', justifyContent: 'flex-end', fontWeight: 'bold', alignItems: 'flex-start', textAlign: 'right' }}> Duration<span style={{padding: '0 5px'}}>:</span> </div>
                        <div style={{ flex: 2, maxWidth:'50%', minWidth:'50%', display: 'flex', fontFamily: 'Roboto', alignItems: 'start' }}>{CalculateTotalMinuteWithText(data.total_q - data.TotalNoOfWordle, data.TimerCount , data.TotalNoOfWordle, NoOfSecondForWordle)}</div>
                    </div>
                    <div style={{ display: (data.used_seconds > 0 && data.total_q != data.attempted_q) ? 'flex' : 'none', flex: 1, maxWidth: 400, margin: '10px 0' }}>
                        <div style={{ flex: 2, maxWidth:'50%', minWidth:'50%', display: 'flex', fontFamily: 'Roboto', justifyContent: 'flex-end', fontWeight: 'bold', alignItems: 'flex-start', textAlign: 'right' }}> Time Left<span style={{padding: '0 5px'}}>:</span> </div>
                        <div style={{ flex: 2, maxWidth:'50%', minWidth:'50%', display: 'flex', fontFamily: 'Roboto', alignItems: 'start' }}>{CalculateTotalMinuteWithText(data.left_q - data.TotalNoOfWordle, data.TimerCount , data.TotalNoOfWordle, NoOfSecondForWordle)}</div>
                    </div>
                    <div style={{ display: 'flex', flex: 1, maxWidth: 400, marginTop: 30 }}>
                        <div style={{ flex: 1, display: (data.left_q > 0) ? 'flex' : 'none', fontFamily: 'Roboto', justifyContent: 'center', fontWeight: 'bold', alignItems: 'flex-start', textAlign: 'right' }}>
                            <Button color='teal' onClick={() => {
                                RenderTest(data)
                            }} >
                                {(data.used_seconds > 0) ? 'Resume Test' : 'Start Test'}
                            </Button>
                        </div>
                        <div style={{ flex: 1, display: (data.left_q == 0) ? 'flex' : 'none', fontFamily: 'Roboto', justifyContent: 'center', fontWeight: 'bold' }}>
                            <Button color='teal' onClick={() => {
                                RenderResultArea()
                            }} >
                                View Result
                            </Button>
                        </div>
                    </div>
                </div>
            </Dialog>
        )
    }

    const RenderTest = (data) => {
        ReactDOM.render(RenderTestContent(data), document.getElementById('divMainContent'))
        ReactDOM.render('', document.getElementById('ModelPopUp'))
    }

    const RenderTestContent = (data) => {
        return (
            <>
                {/* questions box */}
                <audio className="audio-elements">
                    <source src={SubmitSound}></source>
                </audio>
                <div className="container">
                    {RenderInstructionText(data)}
                    <div style={{
                        //display: show_question ? 'flex' : 'none',
                        flexDirection: 'column',
                    }}>
                        <div id="divQList"></div>
                        <div style={{ flex: 1, display: 'flex', fontFamily: 'Roboto', justifyContent: 'center', fontWeight: 'bold', margin: 20 }} id="divButton"></div>
                    </div>
                </div>
            </>
        )
    }

    const GetUnAttemptedQuestion = () => {
        var arr = [], arrAttempted = [];
        Array.from(document.getElementsByClassName('print-heading')).forEach((o, i) => arr.push(o.getAttribute('title')));
        Array.from(document.getElementsByClassName('print-heading-attempted')).forEach((o, i) => arrAttempted.push(o.getAttribute('title')));
        //document.getElementsByClassName('print-heading').forEach((o, i) => arr.push(o.getAttribute('title')));
        //document.getElementsByClassName('print-heading-attempted').forEach((o, i) => arrAttempted.push(o.getAttribute('title')));
        return CommonHelper.CampareTwoArray(arr, arrAttempted)
    }

    const RenderResultArea = () => {
        var notAttemptedQuestion = GetUnAttemptedQuestion()
        if(notAttemptedQuestion.length == 0) RenderResult()
        else DisplayConfirmPopUp(notAttemptedQuestion)
        ReactDOM.render('', document.getElementById('ModelPopUp'))
    }

    const RenderResult = () => { setRusultShowed(true); ReactDOM.render(<ResultArea CId={CId} PS={PS} ClientId={ClientId} SendMail={SendMail} isForLet="1"></ResultArea>, document.getElementById('divMainContent')) }

    const DisplayConfirmPopUp = (data, IsFromHomebutton) => {
        confirmAlert({
            closeOnClickOutside: false,
            customUI: ({ onClose }) => {
                return (
                    <div className='custom-ui' style={{borderRadius:"15px"}}>
                        <h2>There are still {data.length.toString()} questions left</h2>
                        <h3>Are you sure you want to {IsFromHomebutton ? "Leave" : "submit"} this test?</h3>
                        {/* <h5>We recommend you to please attempt all questions in the test.</h5> */}
                        <button className="btn" style={{width:"auto"}} onClick={() => { onClose();setIsTrackingInactivity(true) }}>Go Back</button>
                        <button className="btn" style={{borderColor:'#19387e',width:"auto"}} onClick={() => { {IsFromHomebutton ? (window.location.href = "/LetDirect") : RenderResult() } onClose(); }}>{IsFromHomebutton ? "Leave" : "Submit"}</button>
                    </div>
                );
            }
        });
    }

    const DisplayInactivityPopUp = () => {
        handlePause();
        setIsTrackingInactivity(false);
        confirmAlert({
            closeOnClickOutside: false,
            customUI: ({ onClose }) => {
                return (
                    <div className='custom-ui' style={{borderRadius:"15px"}}>
                        <h2>The test has been paused due to inactivity.</h2>
                        <h3>Would you like to resume this test?</h3>
                        {/* <h5>We recommend you to please attempt all questions in the test.</h5> */}
                        <button className="btn" style={{width:"auto"}} onClick={() => { onClose();handleStart();setIsTrackingInactivity(true); }}>YES</button>
                        <button className="btn" style={{borderColor:'#19387e',width:"auto"}} onClick={() => { { RenderResult() } onClose(); }}>{"Submit"}</button>
                    </div>
                );
            }
        });
    }
    
    async function SendMail(uname, to, cc, bcc, subject, html) {
        try {
            var obj = {
                _uname: uname,
                _to: to,
                _cc: cc,
                _bcc: bcc,
                _subject: subject,
                _html: html
            };
            await axios.post("/Nishant/sendemail", obj)
        } catch (er) {
            //alert(JSON.stringify(er));
        }
    }

    const GetQuestion = () => {
        return (
            ReactDOM.render(RenderAllQuestion(), document.getElementById('divQList'))
        )
    }

    const RenderInstructionText = (d) => {
        return (
            <>
                <TestInstruction TQ={d.total_q} TimeText={CalculateTotalMinuteWithText(d.total_q - d.TotalNoOfWordle, d.TimerCount , d.TotalNoOfWordle, NoOfSecondForWordle)} TotalNoOfWordle={TotalNoOfWordle}></TestInstruction>
                <div className="countdown">
                    <span className='mt-2' style={{ float: 'left', fontSize: '30px', fontFamily: 'Roboto', fontWeight: 'bold' }}><div id='QuestionStatus'></div></span>
                    <div id="divCountDown" style={{ display: 'flex', justifyContent: 'space-between' ,alignItems:'center'}}>
                        <label className="m-0">
                            <button class="ui green small button start-test" onClick={(e) => {startInactivityTracking();GetQuestion()}}>Start Test</button>
                        </label>
                    </div>
                </div>
            </>
        )
    }

    const Completionist = () => <span style={{ color: '#000', fontSize: 30, display: 'flex', fontFamily: 'Roboto', fontWeight: 'bold', flexFlow: 'column' }}>Time Over</span>;
    const rendererAfterQuestionLoaded = ({ hours, minutes, seconds, completed }) => {
        if (completed) {
            RenderResult() 
            return <Completionist></Completionist>
        } else {
            minutes = minutes + hours * 60;
            //if (minutes == 0 && seconds == 1) { window.location.href = window.location.origin }
            return (
                <span className="mr-2 mt-2" style={{ color: '#000', fontSize: 30, display: 'block', fontFamily: 'Roboto', fontWeight: 'bold', textAlign: 'center' }}>
                    {zeroPad(minutes)}:{zeroPad(seconds)}
                </span>
            );
        }
    };

    const clockRef = React.useRef();
    const handleStart = () => {
        startInactivityTracking();
        clockRef.current.start();
        document.getElementById( 'divQList' ).style.display = 'block';
        ReactDOM.render(<><a href="#" color="teal" className="form-control btn btn-warning" size="small" style={{ width: 'fit-content' }} onClick={() => handlePause()} variant="contained">
            <i className="fa fa-pause"></i>
        </a></>, document.getElementById('SpanCountDownButton'))
        CopyTimerButtonLog(PS, ClientId, 1);
    }
    
    const handlePause = () => {
        pauseInactivityTracking();
        clockRef.current.pause();
        document.getElementById( 'divQList' ).style.display = 'none';
        ReactDOM.render(<><a href="#" color="teal" className="form-control btn btn-success" size="small" style={{ width: 'fit-content' }} onClick={() => handleStart()} variant="contained">
            <i className="fa fa-play"></i>
        </a></>, document.getElementById('SpanCountDownButton'))
         CopyTimerButtonLog(PS, ClientId, 0);
    }

    const QuestionsDetail = (d) => {
        ReactDOM.render(<>
        <span>Questions: <span id='AttemptedCount'>{d[0].total - d.length}</span><input type='hidden' id='hiddenCount' value={d[0].total - d.length}/> / {d[0].total}</span>
        <div style={{display:"flex"}}>
            <Countdown ref={clockRef} date={Date.now() + (CommonHelper.CalculateTotalMinute(d[0].total - TotalNoOfWordle, d[0].TimerCount, TotalNoOfWordle, NoOfSecondForWordle) * 1000)} renderer={rendererAfterQuestionLoaded} />
            <div id="SpanCountDownButton" style={{display:"flex"}}>
                <a href="#" color="teal" className="form-control btn btn-warning" size="small" style={{ width: 'fit-content' }} onClick={() => handlePause()} variant="contained">
                    <i className="fa fa-pause"></i>
                </a>
            </div>
            </div>
            <a href="#" color="teal" className="ui teal small button  submitQuestionMobile form-control w-auto" size="small" onClick={() => {RenderResultArea();setIsTrackingInactivity(false)}} variant="contained">
        Submit
    </a>
        </>, document.getElementById('divCountDown'))
        //ReactDOM.render(<>Questions: <sapn id='AttemptedCount'>{d[0].total - d.length}</sapn> / {d[0].total}<input type='hidden' id='hiddenCount' value={d[0].total - d.length}/></>, document.getElementById('QuestionStatus'))
        ReactDOM.render(<a href="#" color="teal" className="ui teal small button  submitQuestionMobile form-control w-auto" size="small" onClick={() => {RenderResultArea();setIsTrackingInactivity(false)}} variant="contained">
            Submit
        </a>, document.getElementById('divButton'))
    }

    const RenderAllQuestion = () => {
        return (
            <>
                <Question c_id={CId} Cl_Id={ClientId} paperset={PS} getDetailFormQuestion={QuestionsDetail}></Question>
            </>
        )
    }

    const RestrictBack = () => {
        if (!RusultShowed) {
            var notAttemptedQuestion = GetUnAttemptedQuestion()
            if (notAttemptedQuestion.length == 0) window.location.href = "/"
            else DisplayConfirmPopUp(notAttemptedQuestion, true)
        }
        else window.location.href = "/";
    }

    return (
        <div>
            <div id="ModelPopUp"></div>
            <div className="header top-sticky row">
                <div className='col-md-2 p-2' id="divImage">
                        <img src="/qbLT.png" width={110}></img>
                    {/* <a href='javascript:void(0)' onClick={() => RestrictBack()}>
                    </a> */}
                </div>
                <div class="col-md-9 text-center-box">
                    <span style={{ color: 'black', fontSize: '25px' }}><b> {c_name}</b></span>
                </div>
            </div>
            <div id="divMainContent" style={{
                flex: 1,
                display: "flex",
                justifyContent: 'center',
                alignItems: 'center'
            }} onCopy={(e) => CommonHelper.CopyEvent(e)} oncut={(e) => CommonHelper.CopyEvent(e)} onpaste={(e) => CommonHelper.CopyEvent(e)}></div>
        </div>
    );
}

